import React, { useEffect, useState } from "react";
import _, { chunk } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  excludeCrossReactors,
  excludeIngredientReviewFragrance,
  getCrossReactorsList,
  updateIngredientCrossReactorList
} from "../../redux/actions/ingredientReview";

import FuzzyHighlighter, { Highlighter } from 'react-fuzzy-highlighter';
import { Checkbox, Loader, Text } from "@mantine/core";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  Spinner
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import "./CrossReactorList.scss";

function CrossReactorModal({ 
    openCrossReactorModal,
    setOpenCrossReactorModal,
    crossReactorModalToggle,
    ingredientId,
    hasFragrance,
    crossReactors
  }) {
  const dispatch = useDispatch()
  const crossReactorsList = useSelector(state => state.ingredientReviews.crossReactorsList);
  const fetchingCrossReactorsList = useSelector(state => state.ingredientReviews.fetchingCrossReactorsList);
  const crossReactorsToInclude = useSelector(state => state.ingredientReviews.crossReactorsToInclude)
  const [crossReactorQuery, setCrossReactorQuery] = useState("");
  const [modalSelectedCrossReactors, setModalSelectedCrossReactors] = useState([]);

  const noOfColumns = 2;

  useEffect(() => {
    openCrossReactorModal && dispatch(getCrossReactorsList())
  }, [openCrossReactorModal]);

  useEffect(() => {
    setModalSelectedCrossReactors(crossReactorsToInclude[ingredientId] ?? [])
    return () => setModalSelectedCrossReactors([])
  }, [crossReactorsToInclude, ingredientId])

  const handleFuzzyHighlightAdd = (crossReactor) => {
    const itemFound = modalSelectedCrossReactors.findIndex(item => item.id === crossReactor.id) > -1;
    const itemAlreadyLinked = crossReactors.findIndex(item => item.id === crossReactor.id) > -1
    if (itemFound || itemAlreadyLinked)
      return

    setModalSelectedCrossReactors(prev => [...prev, crossReactor])
  }

  const handleModalClose = () => {
    let selectedCrossReactorIds = modalSelectedCrossReactors.map(item => ({ id: item.id, name: item.name }))
    dispatch(updateIngredientCrossReactorList(selectedCrossReactorIds, ingredientId))
    setOpenCrossReactorModal(false)
  }

  return (
    <Modal
      unmountOnClose={true}
      onClosed={handleModalClose}
      isOpen={openCrossReactorModal}
      toggle={crossReactorModalToggle}
      size="xl"
      centered={true}
      scrollable={true}
    >
      <ModalHeader
        toggle={crossReactorModalToggle}
        style={{
          backgroundColor: '#f5fbff',
        }}
      >
        Cross Reactors
      </ModalHeader>
      <ModalBody
        style={{
          backgroundColor: '#f5fbff',
        }}
      >
        <Row>
          <InputGroup className="ingredient-type-ahead__input-group flex-nowrap">
            <InputGroupText>
              <FontAwesomeIcon className="font-awesome-icon" icon={faSearch} size="lg" />
            </InputGroupText>
            <span className="ingredient-type-ahead__input">
              <Input
                value={crossReactorQuery}
                onChange={(event) => setCrossReactorQuery(event.target.value)}
              />
              <Row className="common-allergen__results">
                <Col>
                  {crossReactorsList?.length !== 0 && 
                  <FuzzyHighlighter
                    query={crossReactorQuery}
                    data={crossReactorsList}
                    options={{
                      shouldSort: true,
                      includeMatches: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 1,
                      keys: ['name'],
                    }}
                  >
                    {({ formattedResults }) => {
                      return (
                        <Row>
                          <Col>
                            {formattedResults.map((formattedResult, resultIndex) => {
                              if (formattedResult.formatted.name === undefined) {
                                return null;
                              }

                              return (
                                <div
                                  key={resultIndex}
                                  className="ingredient__option-button"
                                  onClick={() => {
                                    handleFuzzyHighlightAdd(formattedResult.item)
                                    setCrossReactorQuery("");
                                    const checkBox = document.getElementById(`input-${formattedResult.item.id}`);
                                    checkBox.scrollIntoView({ behavior: 'smooth' });
                                  }}
                                  role="presentation"
                                >
                                  <Highlighter text={formattedResult.formatted.name} mark={'div'} />
                                </div>
                              );
                            })}
                          </Col>
                        </Row>
                      );
                    }}
                  </FuzzyHighlighter>}
                </Col>
              </Row>
            </span>
            <InputGroupText>
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faClose}
                size="lg"
                onClick={() => {
                  setCrossReactorQuery('');
                }}
              />
            </InputGroupText>
          </InputGroup>
        </Row>
        <Row className="p-3"></Row>
        <Row className="table-container">
          <div className="col-10">
            {crossReactorsList.length ? (
              chunk(
                [
                  !hasFragrance && ({ id: "frag-restrictive", name: "Fragrance (Restrictive)" }),
                  !hasFragrance && ({ id: "frag-standard", name: "Fragrance (Standard)" }),
                  ...crossReactorsList
                ].filter(Boolean), noOfColumns)
                .map((items, index) => {
                return (
                  <Row key={index} className="ingredient-component-container">
                    {items.map((item, index) => {
                      const isInModalSelectedCrossReactors = modalSelectedCrossReactors.findIndex(crossReactor => crossReactor.id === item.id) > -1
                      const isInAlreadyLinkedCrossReactors = crossReactors.findIndex(crossReactor => crossReactor.id === item.id) > -1
                      return (
                        <Col key={item.id} className={`col-${Math.round(12 / noOfColumns)}`}>
                          <Row
                            className={`${
                              index + 1 == noOfColumns
                                ? 'ingredient-component-container-right'
                                : 'ingredient-component-container-left'
                            }`}
                          >
                            <Col className="col-10 ingredient-component-text">
                              <Label for={`input-${item.id}`} style={{ cursor: 'pointer' }}>
                                {item.name}
                              </Label>
                            </Col>
                            <Col className="col-2 custom-checkbox">
                              <Input
                                addon
                                type="checkbox"
                                checked={isInModalSelectedCrossReactors || isInAlreadyLinkedCrossReactors}
                                disabled={isInAlreadyLinkedCrossReactors}
                                className="input-checked"
                                id={`input-${item.id}`}
                                onChange={() => {
                                  setModalSelectedCrossReactors(prev => {
                                    const fragRestrictiveFound = prev.find(item => item.id === "frag-restrictive")
                                    const fragStandardFound = prev.find(item => item.id === "frag-standard")

                                    // This 'if-elseif' block toggles the value of "Fragrance (Restrictive)" and "Fragrance (Standard)".
                                    // ie, if one is checked the other must be unchecked.
                                    if (fragRestrictiveFound) {
                                      if (item.id === "frag-standard") {
                                        const filtered = prev.filter(crossReactor => crossReactor.id !== "frag-restrictive")
                                        return [...filtered, item]
                                      }
                                    } else if (fragStandardFound) {
                                      if (item.id === "frag-restrictive") {
                                        const filtered = prev.filter(crossReactor => crossReactor.id !== "frag-standard")
                                        return [...filtered, item]
                                      }
                                    }

                                    // This 'if' block is to uncheck an already checked cross-reactor.
                                    if(isInModalSelectedCrossReactors) {
                                      return prev.filter(crossReactor => crossReactor.id !== item.id)
                                    }
                                    return [...prev, item]
                                  })
                                }}
                              /> 
                            </Col>
                          </Row>
                        </Col>
                      )
                    })}
                  </Row>
                )
              })
            ) : (
              <div className="loading-container">
                <Spinner />
              </div>
            )}
          </div>
        </Row>
      </ModalBody>
      <ModalFooter
        style={{
          backgroundColor: '#f5fbff',
        }}
      >
        <Button
          className="footer-button"
          onClick={handleModalClose}
        >
          Select
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function CrossReactorList({ crossReactors, ingredientId, fragrance, ingredientStatus }) {
  const dispatch = useDispatch();
  const crossReactorsToInclude = useSelector(state => state.ingredientReviews.crossReactorsToInclude)
  const crossReactorsToExclude = useSelector((state) => state.ingredientReviews.crossReactorsToExclude);
  const fragranceToExclude = useSelector((state) => state.ingredientReviews.fragranceToExclude);
  const excludedCrossReactors = _.get(crossReactorsToExclude, ingredientId, []);
  const hasFragrance = _.has(fragrance, "restrictive");
  const fragranceChecked = fragranceToExclude.includes(ingredientId);
  const [openCrossReactorModal, setOpenCrossReactorModal] = useState(false)

  const crossReactorModalToggle = () => {
    setOpenCrossReactorModal(!openCrossReactorModal)
  }

  if (_.isEmpty(crossReactors) && !hasFragrance) {
    return (
      <>
        {!crossReactorsToInclude[ingredientId]?.length && ingredientStatus === 3 && (
          <div
            style={{ color: "#00c9b7", cursor: "pointer" }}
            onClick={crossReactorModalToggle}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" style={{ marginRight: 5 }} /> Add New
          </div>
        )}
        <div className="d-flex flex-column gap-3" style={{ position: "relative", maxWidth: 270 }}>
          {crossReactorsToInclude[ingredientId]?.map(crossReactor => {
            let label = crossReactor.name
            if (crossReactor.id === "frag-restrictive" || crossReactor.id === "frag-standard")
              label = "Fragrance"

            return (
              <Checkbox
                label={label}
                size={"sm"}
                checked={!crossReactorsToInclude[ingredientId].includes(crossReactor.id)}
                className={"cross-reactor-list"}
                styles={{
                  label: {
                    cursor: "pointer",
                    width: 160
                  },
                  input: {
                    cursor: "pointer",
                  },
                }}
                disabled={ingredientStatus !== 3}
              />
            )
          })}
          {(crossReactorsToInclude[ingredientId]?.length > 0) && (ingredientStatus === 3) && (
            <div
              style={{ color: "#00c9b7", cursor: "pointer", position: "absolute", right: 10, bottom: 0 }}
              onClick={crossReactorModalToggle}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
            </div>
          )}
        </div>

        <CrossReactorModal
          openCrossReactorModal={openCrossReactorModal}
          setOpenCrossReactorModal={setOpenCrossReactorModal}
          crossReactorModalToggle={crossReactorModalToggle}
          ingredientId={ingredientId}
          hasFragrance={hasFragrance}
          crossReactors={crossReactors}
        />
      </>
    )
  }

  return (
    <>
    <div className="d-flex flex-column gap-3" style={{ position: "relative", maxWidth: 270 }}>
      {crossReactors?.map((item) => {
        return (
          <Checkbox
            label={item.name}
            size={"sm"}
            checked={!excludedCrossReactors.includes(item.id)}
            onChange={() => {
              dispatch(excludeCrossReactors(item.id, ingredientId));
            }}
            className={"cross-reactor-list"}
            styles={{
              label: {
                cursor: "pointer",
                width: 160
              },
              input: {
                cursor: "pointer",
              },
            }}
            disabled={ingredientStatus !== 3}
          />
        );
      })}
      {crossReactorsToInclude[ingredientId]?.map(crossReactor => {
        let label = crossReactor.name
        if (crossReactor.id === "frag-restrictive" || crossReactor.id === "frag-standard")
          label = "Fragrance"
        return (
          <Checkbox
            label={label}
            size={"sm"}
            checked={!crossReactorsToInclude[ingredientId].includes(crossReactor.id)}
            className={"cross-reactor-list"}
            styles={{
              label: {
                cursor: "pointer",
                width: 160
              },
              input: {
                cursor: "pointer",
              },
            }}
            disabled={ingredientStatus !== 3}
          />
        )
      })}
      {hasFragrance && (
        <Checkbox
          label={"Fragrance"}
          checked={!fragranceChecked}
          onChange={() => {
            dispatch(excludeIngredientReviewFragrance(ingredientId));
          }}
          styles={{
            label: {
              cursor: "pointer",
            },
            input: {
              cursor: "pointer",
            },
          }}
          className={"cross-reactor-list"}
          disabled={ingredientStatus !== 3}
        />
      )}
      {(ingredientStatus === 3) && <div
        style={{ color: "#00c9b7", cursor: "pointer", position: "absolute", right: 10, bottom: 0 }}
        onClick={crossReactorModalToggle}
      >
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </div>}
    </div>

    <CrossReactorModal
      openCrossReactorModal={openCrossReactorModal}
      setOpenCrossReactorModal={setOpenCrossReactorModal}
      crossReactorModalToggle={crossReactorModalToggle}
      ingredientId={ingredientId}
      hasFragrance={hasFragrance}
      crossReactors={crossReactors}
    />
    </>
  );
}

export default CrossReactorList;
