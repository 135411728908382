export const GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS =
  'GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS';
export const GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_SUCCESS =
  'GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_SUCCESS';
export const GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_ERROR =
  'GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_ERROR';

export const getIngredientsWithAllergenNarativesAndDietarySheets = (payload) => {
  return {
    type: GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS,
    payload,
  };
};
  
export const getIngredientsWithAllergenNarativesAndDietarySheetsSuccess = (payload) => {
  return {
    type: GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_SUCCESS,
    payload,
  };
};
  
export const getIngredientsWithAllergenNarativesAndDietarySheetsError = (payload) => {
  return {
    type: GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_ERROR,
    payload,
  };
};
  
