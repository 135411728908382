import { faArrowRotateLeft, faCheck, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Loader, Modal, Text, TextInput, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedIngredientsSuggestion, clearSelectedIngredientsSuggestions, updateIngredient } from "../../redux/actions/ingredientReview";
import { useState } from "react";

function IngredientReviewAction({ ingredientId, crossReactor, ingredientName, ingredientStatus }) {
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const selectedIngredientSuggestions = useSelector((state) => state.ingredientReviews.selectedIngredientSuggestions);
  const loadingSelectedIngredientSuggestions = useSelector(
    (state) => state.ingredientReviews.loadingSelectedIngredientSuggestions,
  );
  const pendingIngredientSuggestionsId = useSelector((state) => state.ingredientReviews.pendingIngredientSuggestionsId);

  return (
    <div className="d-flex" style={{ justifyContent: "space-around", alignItems: "center", gap: 20 }}>
      {ingredientStatus === 3 && (
        <Tooltip label="Publish">
          <FontAwesomeIcon
            icon={faCheck}
            size="xl"
            color="#13aa48"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                updateIngredient({
                  id: ingredientId,
                  status: 0,
                  crossReactor,
                }),
              );
            }}
          />
        </Tooltip>
      )}
      {ingredientStatus !== 2 && (
        <Tooltip label="Delete">
          <FontAwesomeIcon
            icon={faXmark}
            size="xl"
            color="#f50000"
            style={{ cursor: "pointer" }}
            onClick={() => {
              open();
            }}
          />
        </Tooltip>
      )}
      {ingredientStatus === 2 && (
        <Tooltip label="Restore">
          <FontAwesomeIcon
            icon={faArrowRotateLeft}
            size="xl"
            color="#13aa48"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                updateIngredient({
                  id: ingredientId,
                  status: 3,
                }),
              );
            }}
          />
        </Tooltip>
      )}
      <Modal
        centered
        opened={opened}
        onClose={close}
        overlayProps={{
          opacity: 0.55,
          blur: 3,
        }}
        styles={{
          header: {
            color: "red",
            fontSize: "20px",
            ".mantine-CloseButton-root": {
              color: "gray",
            },
          },
          content: {
            borderRadius: 25,
            backgroundColor: "#fff",
            padding: 24,
          },
        }}
        closeButtonProps={{
          display: "none",
        }}
        size={"lg"}
      >
        <div>
          <Text c={"#eb5757"} mb={10}>
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faTriangleExclamation} color="#eb5757" size="xl" />
            This ingredient will no longer be available at camp and will also be removed from the product. Please check for possible suggestions before deleting it.
          </Text>
          <TextInput value={ingredientName} disabled={true} />
          
          <hr color="#a9a9ab" />

          <Button
            onClick={() => {
              dispatch(getSelectedIngredientsSuggestion(ingredientId));
            }}
          >
            Show Suggestions
          </Button>

          {(() => {
            if (ingredientId !== pendingIngredientSuggestionsId)
              return <></>;
            return loadingSelectedIngredientSuggestions ? (
            <div
              style={{
                width: "100%",
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {!selectedIngredientSuggestions ? <></> : selectedIngredientSuggestions.length ? (
                <div>
                  <Text my={10}>Possible matches are listed below</Text>
                  <div
                    className="py-2 my-2"
                    style={{
                      borderRadius: 5,
                      backgroundColor: "#eef8f8",
                      width: "100%",
                      border: "solid 1px #a9a9ab",
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    {selectedIngredientSuggestions?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 10,
                            borderBottom:
                              index + 1 !== selectedIngredientSuggestions?.length ? "solid 1px #a9a9ab" : "",
                          }}
                        >
                          <div>
                            <Text c={"#000"} fs={14} lh={1.55}>
                              {item?.name}
                            </Text>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center p-1 my-2">
                  <div className="mx-1">
                    <FontAwesomeIcon icon={faTriangleExclamation} color="#eb5757" size="xl" />
                  </div>
                  <div className="mx-1">
                    <Text c={"#eb5757"}>
                      No potential ingredient matches this text in CAMP.
                    </Text>
                  </div>
                </div>
              )}
            </>
          )})()}
        </div>
        <div className="d-flex mt-3 mb-1 flex-row align-items-center justify-content-center gap-3 ">
          <Button 
            variant="white" 
            c="#414b4f" 
            onClick={() => {
              close();
              dispatch(clearSelectedIngredientsSuggestions());
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            style={{ backgroundColor: "#f50000" }}
            onClick={() => {
              dispatch(
                updateIngredient({
                  id: ingredientId,
                  status: 2,
                }),
              );
              dispatch(clearSelectedIngredientsSuggestions());
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default IngredientReviewAction;
