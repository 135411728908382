import * as _ from 'lodash';

import * as Actions from '../actions/learningCenter';
import * as CommonActions from '../actions/commonUtils';

export const initialState = {
  ingredients: [],
  terms:[],
  privacy: [],
  faq: [],
  generalEducation : [],
};
  
export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_SUCCESS:

      return {
        ...state,
        ingredients: action.response,
      };

    case CommonActions.GET_TERMS_SUCCESS:
      var results = [];
      _.map(action.response, (result)=> {
        _.map(result, (termsArray) => {
          results.push(termsArray.terms);
        });
      });
      
      return {
        ...state,
        terms: results,
      };

    case CommonActions.GET_PRIVACY_POLICY_SUCCESS:
      var privacyResults = [];
      _.map(action.response, (result)=> {
        _.map(result, (termsArray) => {
          privacyResults.push(termsArray.privacy);
        });
      });
        
      return {
        ...state,
        privacy: privacyResults,
      };

    case CommonActions.GET_FAQ_SUCCESS:
      var faqResults = [];
      _.map(action.response, (result)=> {
        _.map(result, (faqArray) => {
          faqResults.push(faqArray.faq);
        });
      });
        
      return {
        ...state,
        faq: faqResults,
      };

    case CommonActions.GET_GENERAL_EDUCATION_SUCCESS:
      var geResults = [];
      _.map(action.response, (result)=> {
        _.map(result, (geArray) => {
          geResults.push(geArray);
        });
      });
        
      return {
        ...state,
        generalEducation: geResults,
      };

    default:
      return state;
  }
};
