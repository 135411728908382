export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_SITE';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_OPEN_GROUP_VALUE = 'SET_OPEN_GROUP_VALUE';

export const setSideBarState = (payload) => {
  return {
    type: SET_SIDEBAR_STATE,
    payload,
  };
};

export const setScrollPosition = (payload) => {
  return {
    type: SET_SCROLL_POSITION,
    payload
  }
}

export const setOpenGroupValue = (payload) => {
  return {
    type: SET_OPEN_GROUP_VALUE,
    payload
  }
}
