import * as _ from 'lodash';

import * as Actions from '../actions/search';

const initialState = {
  searchComplete: false,
  searchPending: false,
  searchResults: null,
  searchError: null,
  searchString: '',
  productSearchTxt: '',
  productInitialLoad: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SEARCH:
      return {
        ...state,
        hasMore: false,
        searchComplete: false,
        searchPending: true,
        searchString: _.get(action, 'params.searchValue'),
      };
    case Actions.SEARCH_SUCCESS:
      return {
        ...state,
        hasMore: !_.isNull(_.get(action, 'searchResults.next')),
        searchComplete: true,
        searchPending: false,
      };
    case Actions.SEARCH_ERRORS:
      return {
        ...state,
        hasMore: false,
        searchPending: false,
        searchError: action.error,
        searchString: '',
      };
    case Actions.ELASTIC_SEARCH:
      
      return {
        ...state,
        hasMore: false,
        searchComplete: false,
        searchPending: true,
        searchString: _.get(action, 'params.searchValue'),
      };
    case Actions.SET_PRODUCT_SEARCH_TEXT:
      
      return {
        ...state,
        productSearchTxt: _.get(action, 'searchValue', ''),
      };
    case Actions.SET_SEARCH_STATUS:
      return {
        ...state,
        searchPending: _.get(action, 'status'),
      };
    case Actions.SET_PRODUCT_INITIAL_LOAD:
      return {
        ...state,
        productInitialLoad: _.get(action, 'status'),
      };
    default:
      return state;
  }
};
