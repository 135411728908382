import {
  faDiamondTurnRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
} from 'reactstrap';

import Header from '../../components/common/Header';
import LeftTabs from '../../components/common/LeftTabs';
import SubmitProduct from '../safe-list/SubmitProduct';
import './About.scss';
import AboutPage from './AboutPage';
import HowCampWorks from './HowCampWorks';

export default function About () {
  const tabLabels = [
    {id: 1, name: 'Who We Are'},
    {id: 2, name: 'How CAMP Works'},
    {
      id: 3,
      name: 'Suggest a Product',
      userTypes: ['physician', 'acds'],
    },
    {
      id: 4,
      notNavItem: true,
      name:
        <div className="a-nav-link">
          <FontAwesomeIcon
            className="font-awesome-icon"
            icon={faDiamondTurnRight}
            size="lg"
          />&nbsp;&nbsp;
          <a href="https://www.contactderm.org/find/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Find a Provider
          </a>
        </div>,
    },
    {
      id: 5,
      notNavItem: true,
      name:
        <div className="a-nav-link">
          <FontAwesomeIcon
            className="font-awesome-icon"
            icon={faDiamondTurnRight}
            size="lg"
          />&nbsp;&nbsp;
          <a href="https://www.contactderm.org/patient-support/camp-access/donate"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Donate to ACDS
          </a>
        </div>,
    },
  ];

  const [activeTab, setActiveTab] = useState();

  return (
    <div className="about">
      <Header
        title="About ACDS"
      />
      <div className="body">
        <LeftTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabList={tabLabels}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <AboutPage/>
          </TabPane>
          <TabPane tabId={2}>
            <HowCampWorks/>
          </TabPane>
          <TabPane tabId={3}>
            <SubmitProduct isPhysician={true} activeTab={activeTab} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
