export const START_REGISTRATION_SYNC = 'START_REGISTRATION_SYNC';
export const STOP_REGISTRATION_SYNC = 'STOP_REGISTRATION_SYNC';
export const GET_REGISTRATION_CODES = 'GET_REGISTRATION_CODES';
export const GET_REGISTRATION_CODES_SUCCESS = 'GET_REGISTRATION_CODES_SUCCESS';
export const GET_REGISTRATION_CODES_ERRORS = 'GET_REGISTRATION_CODES_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const getRegistrationCodes = (params, history) => {
  return {
    type: GET_REGISTRATION_CODES,
    params,
    history,
  };
};

export const startRegistration = () => {
  return {
    type: START_REGISTRATION_SYNC,
  };
};

export const stopRegistration = () => {
  return {
    type: STOP_REGISTRATION_SYNC,
  };
};
