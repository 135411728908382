export const LOOK_UP_CODES = "PHYSICIAN/LOOK_UP_CODES";
export const LOOK_UP_CODES_SUCCESS = "PHYSICIAN/LOOK_UP_CODES_SUCCESS";
export const LOOK_UP_CODES_ERROR = "PHYSICIAN/LOOK_UP_CODES_ERROR";
export const CLEAR_CODES = "CLEAR_CODES";
export const PHYSICIAN_PRODUCT_SEARCH_SUCCESS = "PHYSICIAN/PHYSICIAN_PRODUCT_SEARCH_SUCCESS";

// Codesets
export const GENERATE_CODESETS = "PHYSICIAN/GENERATE_CODESETS";
export const GENERATE_CODESETS_SUCCESS = "PHYSICIAN/GENERATE_CODESETS_SUCCESS";
export const GENERATE_CODESETS_ERROR = "PHYSICIAN/GENERATE_CODESETS_ERROR";
export const UPDATE_CODESETS = "PHYSICIAN/UPDATE_CODESETS";

// SafeList
export const EMAIL_SAFE_LIST = "PHYSICIAN/EMAIL_SAFE_LIST";
export const EMAIL_SAFE_LIST_SUCCESS = "PHYSICIAN/EMAIL_SAFE_LIST_SUCCESS";
export const EMAIL_SAFE_LIST_ERROR = "PHYSICIAN/EMAIL_SAFE_LIST_ERROR";
export const CLOSE_EMAIL_SAFE_LIST = "PHYSICIAN/CLOSE_EMAIL_SAFE_LIST"

// PrintList
export const DOWNLOAD_SAFE_LIST = "PHYSICIAN/DOWNLOAD_SAFE_LIST";
export const DOWNLOAD_SAFE_LIST_SAVE_TASKID = "PHYSICIAN/DOWNLOAD_SAFE_LIST_SAVE_TASKID";
export const DOWNLOAD_SAFE_LIST_POLLING_START = "PHYSICIAN/DOWNLOAD_SAFE_LIST_POLLING_START";
export const DOWNLOAD_SAFE_LIST_SUCCESS = "PHYSICIAN/DOWNLOAD_SAFE_LIST_SUCCESS";
export const DOWNLOAD_SAFE_LIST_ERROR = "PHYSICIAN/DOWNLOAD_SAFE_LIST_ERROR";

// Cross-reactors
export const GET_INGREDIENT_CROSSREACTORS = "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS";
export const GET_INGREDIENT_CROSSREACTORS_SUCCESS = "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS_SUCCESS";
export const GET_INGREDIENT_CROSSREACTORS_ERROR = "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS_ERROR";

export const GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION = "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION";
export const GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_SUCCESS =
  "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_SUCCESS";
export const GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_ERROR =
  "PHYSICIAN/GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_ERROR";
export const REMOVE_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION =
  "PHYSICIAN/REMOVE_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION";

export const SET_ALLERGENS = "PHYSICIAN/SET_ALLERGENS";
export const REMOVE_ALLERGEN = "PHYSICIAN/REMOVE_ALLERGEN";
export const SET_ALLERGEN_RESTRICTIVE = "PHYSICIAN/SET_ALLERGEN_RESTRICTIVE";
// Categories
export const UPDATE_CATEGORIES = "PHYSICIAN/UPDATE_CATEGORIES";
export const INITIALIZE_CATEGORIES = "PHYSICIAN/INITIALIZE_CATEGORIES";
export const TOGGLE_ALL_CATEGORIES = "PHYSICIAN/TOGGLE_ALL_CATEGORIES";

export const SET_RESTRICTIVE_PRESENT = "PHYSICIAN/SET_RESTRICTIVE_PRESENT";

export const GET_COMMON_ALLERGENS = "PHYSICIAN/GET_COMMON_ALLERGENS";
export const SET_COMMON_ALLERGENS = "PHYSICIAN/SET_COMMON_ALLERGENS";

export const GET_ALLERGEN_INFO_SHEETS = "GET_ALLERGEN_INFO_SHEETS";
export const GET_ALLERGEN_INFO_SHEETS_SUCCESS = "GET_ALLERGEN_INFO_SHEETS_SUCCESS";
export const SAVE_ALLERGEN_INFO_SHEETS = "SAVE_ALLERGEN_INFO_SHEETS";

export const GET_ALLERGEN_NARRATIVES = "GET_ALLERGEN_NARRATIVES";
export const GET_ALLERGEN_NARATIVES_SUCCESS = "GET_ALLERGEN_NARATIVES_SUCCESS";

export const GET_DIETARY_SHEETS = "GET_DIETARY_SHEETS";
export const GET_DIETARY_SHEETS_SUCCESS = "GET_DIETARY_SHEETS_SUCCESS";

export const SEND_SELECTED_INGREDIENTS_LIST_INFO = "SEND_SELECTED_INGREDIENTS_LIST_INFO";
export const SEND_SELECTED_INGREDIENTS_LIST_INFO_SUCCESS = "SEND_SELECTED_INGREDIENTS_LIST_INFO_SUCCESS";

//selected common allergens
export const SET_SELECTED_COMMON_ALLERGENS_ID = "PHYSICIANS/SET_SELECTED_COMMON_ALLERGENS_ID";
export const REMOVE_REMOVE_SELECTED_COMMON_ALLERGENS_ID = "PHYSICIANS/REMOVE_REMOVE_SELECTED_COMMON_ALLERGENS_ID";

export const POST_PHYSICIAN_QUESTION_ANSWER = "POST_PHYSICIAN_QUESTION_ANSWER";
export const SET_CURRENT_ACTIVE_TAB = "PHYSICIANS/SET_CURRENT_ACTIVE_TAB";

export const EMAIL_CODESETS = "EMAIL_CODESETS";
export const EMAIL_CODESETS_SUCCESS = "EMAIL_CODESETS_SUCCESS";
export const EMAIL_CODESETS_ERROR = "ERROR_CODESETD_ERROR";

//Selected
export const SET_ALLERGEN_ALLERGEN_NARRATIVE = "PHYSICIANS/SET_ALLERGEN_ALLERGEN_NARRATIVE";
export const SET_ALLERGEN_DIETARY_SHEET = "PHYSICIANS/SET_ALLERGEN_DIETARY_SHEET";

//Physician questions and answers
export const SET_PHYSICIAN_ANSWERS = "PHYSICIANS/SET_PHYSICIAN_ANSWERS";
export const GET_PHYSICIAN_ANSWERS = "PHYSICIANS/GET_PHYSICIAN_ANSWERS";
export const PHYSICIAN_QUESTION_ANSWER_SYNC = "PHYSICIAN/PHYSICIAN_QUESTION_ANSWER_SYNC";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const PHYSICIAN_ANSWERS_SYNC_STATUS = "PHYSICIAN/PHYSICIAN_ANSWERS_SYNC_STATUS";

export const RESET_PHYSICAIN_QUESTIONS_AND_ANSWERS = "PHYSICIAN/RESET_PHYSICAIN_QUESTIONS_AND_ANSWERS";

export const lookUpCodes = (params) => {
  return {
    type: LOOK_UP_CODES,
    params,
  };
};

export const clearCodes = () => {
  return {
    type: CLEAR_CODES,
  };
};

export const generateCodesets = () => {
  return {
    type: GENERATE_CODESETS,
  };
};

export const updateCodesets = () => {
  return {
    type: UPDATE_CODESETS,
  };
};

export const emailSafeList = (params, closeEmail, setUserEmail, productCategories) => {
  return {
    type: EMAIL_SAFE_LIST,
    params,
    closeEmail,
    setUserEmail,
    productCategories,
  };
};

export const downloadSafeList = (productCategories) => {
  return {
    type: DOWNLOAD_SAFE_LIST,
    productCategories
  }
}

export const closeEmailSafeList = () => {
  return {
    type: CLOSE_EMAIL_SAFE_LIST
  }
}

export const getIngredientCrossReactors = (params) => {
  return {
    type: GET_INGREDIENT_CROSSREACTORS,
    params,
  };
};

export const clearIngredientCrossReactors = () => {
  return {
    type: REMOVE_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION,
  };
};

export const setAllergens = (selectedIngredients) => {
  return {
    type: SET_ALLERGENS,
    selectedIngredients,
  };
};

export const setAllergenRestrictive = (params) => {
  return {
    type: SET_ALLERGEN_RESTRICTIVE,
    params,
  };
};

export const removeAllergens = (params) => {
  return {
    type: REMOVE_ALLERGEN,
    params,
  };
};

export const initializeCategories = (categories) => {
  return {
    type: INITIALIZE_CATEGORIES,
    categories,
  };
};

export const updateCategories = (params) => {
  return {
    type: UPDATE_CATEGORIES,
    params,
  };
};

export const toggleAllCategories = (checkValue) => {
  return {
    type: TOGGLE_ALL_CATEGORIES,
    checkValue,
  };
};

export const setRestrictivePresent = (payload) => {
  return {
    type: SET_RESTRICTIVE_PRESENT,
    payload,
  };
};

export const getCommonAllergens = () => {
  return {
    type: GET_COMMON_ALLERGENS,
  };
};

export const setCommonAllergens = (response) => {
  return {
    type: SET_COMMON_ALLERGENS,
    response,
  };
};

export const setSelectedCommonAllergens = (payload) => {
  return {
    type: SET_SELECTED_COMMON_ALLERGENS_ID,
    payload,
  };
};

export const removeSelectedCommonAllergens = (payload) => {
  return {
    type: REMOVE_REMOVE_SELECTED_COMMON_ALLERGENS_ID,
    payload,
  };
};

export const getIngredientsCrossReactorBasedOnDescription = (payload) => {
  return {
    type: GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION,
    payload,
  };
};

export const getAllergensInfoSheets = (payload) => {
  return {
    type: GET_ALLERGEN_INFO_SHEETS,
    payload,
  };
};

export const getAllergenNarratives = (payload) => {
  return {
    type: GET_ALLERGEN_NARRATIVES,
    payload,
  };
};

export const getDietarySheets = (payload) => {
  return {
    type: GET_DIETARY_SHEETS,
    payload,
  };
};

export const sendIngredientListInfo = (payload) => {
  return {
    type: SEND_SELECTED_INGREDIENTS_LIST_INFO,
    payload,
  };
};

export const postPhysicianQuestionAnswer = (params, history) => {
  return {
    type: POST_PHYSICIAN_QUESTION_ANSWER,
    payload: {
      params,
      history,
      shouldRoute: false,
    },
  };
};

export const setCurrentActiveTab = (tab) => {
  return {
    type: SET_CURRENT_ACTIVE_TAB,
    payload: tab,
  };
};

export const emailCodeSets = (params, closeEmail, setUserEmail) => {
  return {
    type: EMAIL_CODESETS,
    params,
    closeEmail,
    setUserEmail,
  };
};

export const setAllergenAllergenNarrative = (params) => {
  return {
    type: SET_ALLERGEN_ALLERGEN_NARRATIVE,
    params,
  };
};

export const setAllergenDietarySheet = (params) => {
  return {
    type: SET_ALLERGEN_DIETARY_SHEET,
    params,
  };
};

export const setPhysicianAnswers = (payload) => {
  return {
    type: SET_PHYSICIAN_ANSWERS,
    payload,
  };
};

export const getPhysicianAnswers = () => {
  return {
    type: GET_PHYSICIAN_ANSWERS,
  };
};

export const physicianQuestionAnswerSync = (payload) => {
  return {
    type: PHYSICIAN_QUESTION_ANSWER_SYNC,
    payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload,
  };
};

export const setPhysicianAnswerSyncStatus = (payload) => {
  return {
    type: PHYSICIAN_ANSWERS_SYNC_STATUS,
    payload,
  };
};

export const resetPhysicianQuestionAnswer = () => {
  return {
    type: RESET_PHYSICAIN_QUESTIONS_AND_ANSWERS,
  };
};
