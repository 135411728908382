import { call, take, put } from 'redux-saga/effects';
import * as _ from 'lodash';

import { LOGIN_SUCCESS, SIGNUP_SUCCESS, apiPost } from 'acds-react-core';
import * as authActions from '../actions/auth';

function* doForgotPassword (action) {
  const { params } = action;
  try {
    const response = yield apiPost('api/password/reset/', params);

    if (!!response) {
      params.nextRoute.push(params.nextRoute.path);
    }
  } catch (error) {
    yield put({
      type: authActions.POST_FORGOT_PASSWORD_ERRORS,
      error: error.message,
    });
  }
}

function* authSagas () {
  while (true) {
    // Wait for login
    const action = yield take([LOGIN_SUCCESS, SIGNUP_SUCCESS, authActions.POST_FORGOT_PASSWORD]);
    if (action.type === authActions.POST_FORGOT_PASSWORD) {
      yield call(doForgotPassword, action);
    }

    // Redirect user to home
    if ((action.type === 'LOGIN_SUCCESS' || action.type === 'SIGNUP_SUCCESS') && _.has(action, 'nextRoute.path')) {
      action.nextRoute.push(action.nextRoute.path);
    }
  }
}

export default authSagas;
