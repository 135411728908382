const ACDS_CONSTANTS = {
  DELETION_CONTACT_NO: "(414) 918-9805",
  PRODUCT_MANUFACTURER_LIMIT: 20,
  PRODUCT_BRAND_LIMIT: 20,
};

const PAGE_ROUTES = {
  PATIENTS_CODES_RESET_DASHBOARD_PAGE: "reset-code-set",
};

const PRODUCT_REVIEW_CONSTANTS = {
  PUBLISH: 0,
  REJECT: 2,
  SAVE: 1,
};

export { ACDS_CONSTANTS, PAGE_ROUTES, PRODUCT_REVIEW_CONSTANTS };
