import { apiGet, apiPatch } from "acds-react-core";
import _ from "lodash";
import { toast } from "react-toastify";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_COMMENT_REVIEW_LAST_UPDATED_DATE,
  GET_PRODUCTS_COMMENTS,
  GET_PRODUCTS_COMMENTS_ERROR,
  GET_PRODUCTS_COMMENTS_NEXT,
  GET_PRODUCTS_COMMENTS_NEXT_ERROR,
  GET_PRODUCTS_COMMENTS_NEXT_SUCCESS,
  GET_PRODUCTS_COMMENTS_SUCCESS,
  SET_COMMENT_REVIEW_LAST_UPDATED_DATE,
  UPDATE_PRODUCT_COMMENT_STATUS,
} from "../actions/commentReview";

function* doGetProductsCommentLastUpdatedDate(payload) {
  const status = _.get(payload, "payload", 1);
  const apiUrl = `api/products/reviews/last_updated_date/?status=${status}`;
  try {
    const response = yield apiGet(apiUrl);
    yield put({
      type: SET_COMMENT_REVIEW_LAST_UPDATED_DATE,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductsComments({ status }) {
  const queryParams = Object.keys(status)
    .map((key) => {
      if (key === "startDate") return "updated_at__gte=" + encodeURIComponent(status[key]);
      if (key === "endDate") return "updated_at__lte=" + encodeURIComponent(status[key]);
      return encodeURIComponent(key) + "=" + encodeURIComponent(status[key]);
    })
    .join("&");

  const apiString = `api/products/reviews/?${queryParams}`;

  try {
    const response = yield apiGet(apiString);

    yield put({
      type: GET_PRODUCTS_COMMENTS_SUCCESS,
      response,
    });

    if (_.has(status, "startDate")) {
      const dateResponse = {
        from_date: status.startDate,
        to_date: status.endDate,
      };
      yield put({
        type: SET_COMMENT_REVIEW_LAST_UPDATED_DATE,
        response: dateResponse,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: GET_PRODUCTS_COMMENTS_ERROR,
      error,
    });
  }
}

function* doGetProductsCommentsNext() {
  const baseUrl = yield select((state) => state.env.api_root);
  const fetchingProductsComments = yield select((state) => state.commentReviews.fetchingProductReviews);
  const productsCommentsNext = yield select((state) => state.commentReviews.productsCommentsNext);
  const productsCommentsNextUrl = _.replace(productsCommentsNext, baseUrl + "/", "");

  if (fetchingProductsComments) {
    return;
  }

  if (productsCommentsNextUrl.length > 0) {
    try {
      const productCommentsNextResult = yield apiGet(productsCommentsNextUrl);

      yield put({
        type: GET_PRODUCTS_COMMENTS_NEXT_SUCCESS,
        result: productCommentsNextResult,
      });
    } catch (error) {
      yield put({
        type: GET_PRODUCTS_COMMENTS_NEXT_ERROR,
        error,
      });
      console.log(error);
    }
  }
}

function* doReviewAction({ reviewAction }) {
  const id = reviewAction.id;
  const status = reviewAction.status;
  const payload = { status };
  const apiString = `api/products/reviews/${id}/`;

  try {
    yield apiPatch(apiString, payload);

    const toastMessage = status === 0 ? "Comment Published" : "Comment Rejected";
    const toastBackground = status === 0 ? "#00c9b7" : "#E74C3C";
    const toastMessageType = status === 0 ? "success" : "error";

    toast[toastMessageType](toastMessage, {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: toastBackground,
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* commentReviewSagas() {
  yield takeLatest(GET_COMMENT_REVIEW_LAST_UPDATED_DATE, doGetProductsCommentLastUpdatedDate);
  yield takeLatest(GET_PRODUCTS_COMMENTS, doGetProductsComments);
  yield takeLatest(GET_PRODUCTS_COMMENTS_NEXT, doGetProductsCommentsNext);
  yield takeEvery(UPDATE_PRODUCT_COMMENT_STATUS, doReviewAction);
}
