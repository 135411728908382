import * as _ from 'lodash';
import * as Actions from '../actions/qualityOfLife';

const initialState = {
  qualityOfLifePending: false,
  qualityOfLifeResults: [],
  qualityOfLifeNext: null,
  qualityOfLifeErrors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_QUALITY_OF_LIFE:
    case Actions.GET_NEXT_QUALITY_OF_LIFE:
    case Actions.POST_QUALITY_OF_LIFE:
      return {
        ...state,
        qualityOfLifePending: true,
      };
    case Actions.GET_QUALITY_OF_LIFE_SUCCESS:
      return {
        ...state,
        qualityOfLifePending: false,
        qualityOfLifeResults: _.get(action, 'response.results'),
        qualityOfLifeNext: _.get(action, 'response.next'),
      };
    case Actions.GET_QUALITY_OF_LIFE_ERRORS:
      return {
        ...state,
        qualityOfLifePending: false,
        qualityOfLifeErrors: action.error,
      };
    case Actions.GET_NEXT_QUALITY_OF_LIFE_SUCCESS:
      return {
        ...state,
        qualityOfLifePending: false,
        qualityOfLifeResults: [
          ...state.qualityOfLifeResults,
          ..._.get(action, 'response.results'),
        ],
        qualityOfLifeNext: _.get(action, 'response.next'),
      };
    case Actions.POST_QUALITY_OF_LIFE_SUCCESS:
      return {
        ...state,
        qualityOfLifePending: false,
      };
    case Actions.POST_QUALITY_OF_LIFE_ERRORS:
      return {
        ...state,
        qualityOfLifePending: false,
        qualityOfLifeErrors: action.error,
      };
    default:
      return state;
  }
};
