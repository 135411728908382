/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faCamera, faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Group, MantineProvider, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mantineTheme } from "../../utils/mantine-theme";

const ImageComponent = ({ currentImage, newImage, formFieldName, formSetValue }) => {
  const apiRoot = useSelector((state) => state.env.api_root);

  const [opened, { open, close }] = useDisclosure(false);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    const isNewImageUpdate = _.has(currentImage, "newImage");
    if (!isNewImageUpdate) {
      const currentImageRotations = _.get(currentImage, "image.rotate", 0);
      setRotate(currentImageRotations);
    }
  }, [currentImage]);

  const handleRotateLeft = () => setRotate((prev) => prev - 90);
  const handleRotateRight = () => setRotate((prev) => prev + 90);
  const handleModalClose = () => {
    close();
  };

  const handlePositionSave = (imageSaveFrom) => {
    if (imageSaveFrom === "oldImage") {
      formSetValue({
        [formFieldName]: {
          ...currentImage,
          image: {
            ...currentImage?.image,
            rotate,
          },
        },
      });
    }

    if (imageSaveFrom === "newImage") {
      formSetValue({
        [formFieldName]: {
          ...currentImage,
          rotate,
        },
      });
    }

    close();
  };

  if (!(currentImage?.newImage?.size > 0) && _.isEmpty(currentImage.image)) {
    return (
      <div
        style={{
          width: 80,
          height: 80,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: 5,
          backgroundColor: "#E0E0E0",
        }}
      >
        <FontAwesomeIcon icon={faCamera} color="#b0b0b0" size="xl" />
      </div>
    );
  }

  if (_.has(currentImage, "newImage")) {
    return (
      <MantineProvider theme={mantineTheme}>
        <div>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <img
            src={URL.createObjectURL(currentImage.newImage)}
            alt={"preview"}
            width={80}
            height={80}
            style={{
              borderRadius: 8,
              cursor: "pointer",
              transform: `rotate(${_.get(currentImage, "rotate", 0)}deg)`,
            }}
            onClick={open}
          />
        </div>

        <Modal
          opened={opened}
          onClose={handleModalClose}
          title={formFieldName}
          size="auto"
          styles={{
            close: {
              display: "none",
            },
          }}
        >
          <Group wrap="nowrap" mt="md">
            <img
              src={URL.createObjectURL(newImage)}
              alt={"preview"}
              width={450}
              height={450}
              style={{ borderRadius: 8, transform: `rotate(${rotate}deg)`, transition: "transform 0.3s ease-in-out" }}
            />
          </Group>
          <Group position="center" spacing="xs" direction="column" mt="md">
            <Tooltip label="Rotate Left">
              <ActionIcon color="teal" size="xl" radius="xl" variant="transparent" onClick={handleRotateLeft}>
                <FontAwesomeIcon icon={faRotateLeft} size="xl" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Rotate Right">
              <ActionIcon color="teal" size="xl" radius="xl" variant="transparent" onClick={handleRotateRight}>
                <FontAwesomeIcon icon={faRotateRight} size="xl" />
              </ActionIcon>
            </Tooltip>
          </Group>

          <Group position="right" mt="md" spacing="xs">
            <Button variant="white" color="modifiedGray" onClick={close}>
              Close
            </Button>
            <Button
              variant="filled"
              onClick={() => {
                handlePositionSave("newImage");
              }}
            >
              Save
            </Button>
          </Group>
        </Modal>
      </MantineProvider>
    );
  }

  return (
    <MantineProvider theme={mantineTheme}>
      <div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: Will refactor it latter */}
        <img
          src={currentImage.image.image ? apiRoot + currentImage.image.image : currentImage.image.image_url}
          alt={"preview"}
          width={80}
          height={80}
          style={{
            borderRadius: 8,
            cursor: "pointer",
            transform: `rotate(${_.get(currentImage, "image.rotate", 0)}deg)`,
          }}
          onClick={open}
        />
      </div>

      <Modal
        opened={opened}
        onClose={handleModalClose}
        title={formFieldName}
        size="auto"
        styles={{
          close: {
            display: "none",
          },
        }}
      >
        <Group wrap="nowrap" mt="md">
          <img
            src={currentImage.image.image ? apiRoot + currentImage.image.image : currentImage.image.image_url}
            alt={"preview"}
            width={450}
            height={450}
            style={{
              borderRadius: 8,
              transform: `rotate(${rotate}deg)`,
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Group>
        <Group position="center" spacing="xs" direction="column" mt="md">
          <Tooltip label="Rotate Left">
            <ActionIcon color="teal" size="xl" radius="xl" variant="transparent" onClick={handleRotateLeft}>
              <FontAwesomeIcon icon={faRotateLeft} size="xl" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Rotate Right">
            <ActionIcon color="teal" size="xl" radius="xl" variant="transparent" onClick={handleRotateRight}>
              <FontAwesomeIcon icon={faRotateRight} size="xl" />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Group position="right" mt="md" spacing="xs">
          <Button variant="white" color="modifiedGray" onClick={close}>
            Close
          </Button>
          <Button
            variant="filled"
            onClick={() => {
              handlePositionSave("oldImage");
            }}
          >
            Save
          </Button>
        </Group>
      </Modal>
    </MantineProvider>
  );
};

export default ImageComponent;
