import {createSelector} from 'reselect';
import * as _ from 'lodash';

export const selectProductDetails = (s) => s.safeListReducer.currProduct;
export const selectReviews = (quantity) => {
  return createSelector(
    selectProductDetails,
    (prod) => {
      if (quantity === 'all') {
        return _.get(prod, 'reviews');
      } else {
        return _.slice(
          _.get(prod, 'reviews'),
          0,
          quantity,
        );
      }
    }
  );
};

export const selectIsCurrFavorite = (s) => _.get(s, 'safeListReducer.currProduct.is_favorite', false);

export const retailerPurchaseQueries = (s) => s.safeListReducer.retailerPurchaseQueries;

export const getProductPurchasingLinks = (product, retailerQueries) => {
  // Get URLs associated with the product from the backend database (if they exist)
  const productPurchasingOptions = _.get(product, 'product_purchasing_options');

  return _.map(retailerQueries, (retailQuery) => {
    // Determine if this retailer exists as a backend URL option
    if (_.some(productPurchasingOptions, ['retailer', retailQuery.retailer])) {
      return {
        retailer: retailQuery.retailer,
        link: _.get(
          // Find the backend option where the retailer is the same as the current one in this map
          _.find(productPurchasingOptions, (option) => option.retailer === retailQuery.retailer),
          // Return backend product URL
          'product_url'
        ),
      };
    }
    // URI encode strings to match standard formatting for these queries
    const productName = encodeURIComponent(_.get(product, 'name'));
    const productBrand = encodeURIComponent(_.get(product, 'brand'));
    const brandEnd = _.isNull(retailQuery.brand_query_end)
      ? ''
      : _.get(retailQuery, 'brand_query_end');
    // Brand portion of query is conditional on the brand existing
    const brandString =
      _.get(product, 'brand') && retailQuery.brand_query_start
        ? `${retailQuery.brand_query_start}${productBrand}${brandEnd}`
        : '';

    return {
      retailer: retailQuery.retailer,
      link: `${_.get(retailQuery, 'base_url')}${productName}${brandString}`,
    };
  });
};

export const selectProductPurchasing = createSelector(
  selectProductDetails,
  retailerPurchaseQueries,
  getProductPurchasingLinks,
);
