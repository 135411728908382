export const SEARCH = 'SEARCH/SEARCH';
export const CATEGORY_SEARCH_NEXT_PAGE = 'SEARCH/CATEGORY_SEARCH_NEXT_PAGE'
export const NEXT_SEARCH = 'SEARCH/NEXT_SEARCH';
export const SEARCH_SUCCESS = 'SEARCH/SEARCH_SUCCESS';
export const SEARCH_ERRORS = 'SEARCH/SEARCH_ERRORS';
export const ELASTIC_SEARCH = 'ELASTIC/SEARCH';
export const PRODUCT_ELASTIC_SEARCH = 'SEARCH/PRODUCT_ELASTIC_SEARCH';
export const GET_PRODUCT_ELASTIC_SEARCH = 'SEARCH/GET_PRODUCT_ELASTIC_SEARCH';
export const SET_PRODUCT_SEARCH_TEXT = 'SEARCH/SET_PRODUCT_SEARCH_TXT';
export const SET_SEARCH_STATUS = 'SEARCH/SEARCH_STATUS';
export const SET_PRODUCT_INITIAL_LOAD = 'SEARCH/PRODUCT_INITIAL_LOAD';
export const GET_ALLERGEN_NARRATIVES_SEARCH = 'SEARCH/GET_ALLERGEN_NARRATIVES_SEARCH'; 

export const getSearch = (params) => {
  return {
    type: SEARCH,
    params,
  };
};

export const getCategorySearchNextPage = (params) => {
  return {
    type: CATEGORY_SEARCH_NEXT_PAGE,
    params
  }
}

export const getNextSearch = (params) => {
  return {
    type: NEXT_SEARCH,
    params,
  };
};

export const getElasticSearch = (params) => {
  return {
    type: ELASTIC_SEARCH,
    params,
  };
};

export const getProductElasticSearch = (params) => {
  return {
    type: GET_PRODUCT_ELASTIC_SEARCH,
    params,
  };
};

export const setProductSearchText = (searchValue) => {
  return {
    type: SET_PRODUCT_SEARCH_TEXT,
    searchValue,
  };
};

export const setSearchStatus = (status) => {
  return {
    type: SET_SEARCH_STATUS,
    status,
  };
};

export const setProductInitialLoad = (status) => {
  return {
    type: SET_PRODUCT_INITIAL_LOAD,
    status,
  };
};

export const getAllergenNarrativesSearch = (params) => {
  return {
    type: GET_ALLERGEN_NARRATIVES_SEARCH,
    params,
  };
};
