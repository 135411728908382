import { apiGet, apiPatch, apiPost } from 'acds-react-core';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
} from '../actions/notifications';

function* doGetNotifications () {
  try {
    const response = yield apiGet('api/notifications/');

    yield put({
      type: GET_NOTIFICATIONS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: GET_NOTIFICATIONS_ERROR,
      error,
    });
  }
}

function* doMarkNotificationAsRead (action) {
  const notificationId = action.params;
  try {
    const response = yield apiPatch(`api/notifications/${notificationId}/`, {
      is_seen: true,
    });

    yield put({
      type: MARK_NOTIFICATION_AS_READ_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: MARK_NOTIFICATION_AS_READ_ERROR,
      error,
    });
  }
}

function* doMarkAllNotificationsAsRead () {
  try {
    const response = yield apiPost(`api/notifications/mark_all_read/`);

    yield put({
      type: MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
      error,
    });
  }
}

export default function* ingredientSagas () {
  yield takeLatest(GET_NOTIFICATIONS, doGetNotifications);
  yield takeEvery(MARK_NOTIFICATION_AS_READ, doMarkNotificationAsRead);
  yield takeLatest(MARK_ALL_NOTIFICATIONS_AS_READ, doMarkAllNotificationsAsRead);
}
