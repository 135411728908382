import { apiGet } from 'acds-react-core';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import * as Actions from '../actions/learning';
import { getNextApiString } from './helpers';

function* doGetAllergenNarratives ({params}) {
  try {
    const response = yield apiGet('api/ingredients/allergen-narratives/', params);

    yield put({
      type: Actions.GET_ALLERGEN_NARRATIVES_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_ALLERGEN_NARRATIVES_ERROR,
      error,
    });
  }
}

function* doGetNextAllergenNarratives () {
  try {
    const next = yield select((s) => s.learningReducer.allergenNarrativesNext);
    const apiString = yield getNextApiString(next);
    const response = yield apiGet(apiString);

    yield put({
      type: Actions.GET_ALLERGEN_NARRATIVES_SUCCESS,
      response,
      paginated: true,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_ALLERGEN_NARRATIVES_ERROR,
      error,
    });
  }
}

export default function* learning () {
  yield takeLatest(Actions.GET_ALLERGEN_NARRATIVES, doGetAllergenNarratives);
  yield takeLatest(Actions.GET_NEXT_ALLERGEN_NARRATIVES, doGetNextAllergenNarratives);
}
