import * as _ from 'lodash';

import * as Actions from '../actions/physician';
// import { selectedCategories } from '../selectors/physician';

const initialState = {
  user: null,
  allergens: null,
  ingredients: null,
  lookupCodesError: false,
  lookupCodesPending: false,
  codesets: {
    code1: null,
    code2: null,
  },
  codesetsPending: false,
  codesetsError: false,
  emailSafeListPending: false,
  emailSafeListError: false,
  downloadSafeListPending: false,
  downloadSafeListTaskId: null,
  ingredientCrossReactors: null,
  patient: null,
  products: null,
  selectedCategories: {},
  fragrancePresent: false,
  commonAllergensList: [],
  selectedCommonAllergensId: [],
  activeTab: 'search',
  physicianQuestionAnswers: [],
  physicianQuestionAnswersSync: [],
  physicianQuestionAnswersSyncing: false,
};

const initializeCategories = (category, checkValue) => {
  const categoryChildren = _.get(category, 'children');
  const children = _.map(categoryChildren, (child) =>
    initializeCategories(child, checkValue),
  );

  const categoryChecked = [
    {
      [category.id]: {
        ...category,
        id: category.id,
        isChecked: checkValue,
      },
    },
    children,
  ];

  return categoryChecked;
};

const getCategoryIndex = (clonedCategories, categoryId) => {
  return _.findIndex(clonedCategories, (category) =>
    _.findKey(category, { id: categoryId }),
  );
};

const getChildrenStatus = (clonedCategories, parent) => {
  const children = _.get(parent, 'children');
  const grandchildren = _.map(children, (child) => {
    if (!_.isEmpty(_.get(child, 'children'))) {
      return getChildrenStatus(child);
    }
  });

  const checkedChildren = _.map(children, (child) => {
    const childIndex = getCategoryIndex(clonedCategories, child.id);

    return {
      isChecked: _.get(clonedCategories[childIndex], `${child.id}.isChecked`),
    };
  });

  const allChildrenChecked = _.every(checkedChildren, { isChecked: true });

  const noneChildrenChecked = _.every(checkedChildren, { isChecked: false });

  const allChecked = !_.isEmpty(_.get(grandchildren, 'allChecked'))
    ? _.concat(allChildrenChecked, grandchildren.allChecked)
    : allChildrenChecked;

  const noneChecked = !_.isEmpty(_.get(grandchildren, 'noneChecked'))
    ? _.concat(noneChildrenChecked, grandchildren.noneChecked)
    : noneChildrenChecked;

  return {
    allChecked,
    noneChecked,
  };
};

const toggleSelectedCategories = (clonedCategories, category, checkedValue) => {
  const categoryId = _.get(category, 'id');
  const categoryIndex = getCategoryIndex(clonedCategories, categoryId);
  const clonedIndexState = _.cloneDeep(_.get(clonedCategories, categoryIndex));

  clonedCategories[categoryIndex] = {
    ...clonedIndexState,
    [categoryId]: {
      ...clonedIndexState[categoryId],
      isChecked: checkedValue,
    },
  };

  const categoryChildren = _.get(category, 'children');
  if (!!categoryChildren) {
    _.map(categoryChildren, (child) =>
      toggleSelectedCategories(clonedCategories, child, checkedValue),
    );
  }

  const parentCategoryId = _.get(category, 'parent_category');
  if (!!parentCategoryId) {
    const parentCategoryIndex = getCategoryIndex(
      clonedCategories,
      parentCategoryId,
    );
    const parentCategory = _.get(
      clonedCategories[parentCategoryIndex],
      parentCategoryId,
    );
    const parentClonedIndexState = _.cloneDeep(
      _.get(clonedCategories, parentCategoryIndex),
    );

    const childrenStatus = getChildrenStatus(clonedCategories, parentCategory);

    if (childrenStatus.allChecked) {
      clonedCategories[parentCategoryIndex] = {
        ...parentClonedIndexState,
        [_.get(parentCategory, 'id')]: {
          ...parentClonedIndexState[_.get(parentCategory, 'id')],
          isChecked: true,
          isIndeterminate: false,
        },
      };
    } else if (childrenStatus.noneChecked) {
      clonedCategories[parentCategoryIndex] = {
        ...parentClonedIndexState,
        [_.get(parentCategory, 'id')]: {
          ...parentClonedIndexState[_.get(parentCategory, 'id')],
          isChecked: false,
          isIndeterminate: false,
        },
      };
    } else {
      clonedCategories[parentCategoryIndex] = {
        ...parentClonedIndexState,
        [_.get(parentCategory, 'id')]: {
          ...parentClonedIndexState[_.get(parentCategory, 'id')],
          isIndeterminate: true,
        },
      };
    }
  }

  return clonedCategories;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOOK_UP_CODES:
      return {
        ...state,
        lookupCodesPending: true,
      };
    case Actions.LOOK_UP_CODES_SUCCESS: {
      return {
        ...state,
        codesets: {
          id: _.get(action, 'response.id'),
          code1: _.get(action, 'response.code1'),
          code2: _.get(action, 'response.code2'),
        },
        allergens: _.get(action, 'response.cross_reactor_ingredient'),
        user: _.get(action, 'response.user'),
        ingredients: _.get(action, 'response.cross_reactor_ingredient.all'),
        lookupCodesPending: false,
        patient: _.get(action, 'response.user'),
      };
    }
    case Actions.LOOK_UP_CODES_ERROR:
      return {
        ...state,
        lookupCodesError: action.error,
        lookupCodesPending: false,
      };
    case Actions.CLEAR_CODES:
      return {
        ...initialState,
        codesets: {
          code1: null,
          code2: null,
        },
      };
    case Actions.GENERATE_CODESETS:
    case Actions.UPDATE_CODESETS:
      return {
        ...state,
        codesetsPending: true,
      };
    case Actions.GENERATE_CODESETS_SUCCESS:
      return {
        ...state,
        codesets: action.codesetResponse,
        codesetsPending: false,
      };
    case Actions.GENERATE_CODESETS_ERROR:
      return {
        ...state,
        codesetsError: action.error,
        codesetsPending: false,
      };
    case Actions.EMAIL_SAFE_LIST:
      return {
        ...state,
        emailSafeListPending: true,
      };
    case Actions.EMAIL_SAFE_LIST_SUCCESS:
      return {
        ...state,
        emailSafeListPending: false,
      };
    case Actions.EMAIL_SAFE_LIST_ERROR:
      return {
        ...state,
        emailSafeListPending: false,
      };
    case Actions.CLOSE_EMAIL_SAFE_LIST:
      return {
        ...state,
        emailSafeListPending: false
      }
    case Actions.DOWNLOAD_SAFE_LIST:
      return {
        ...state,
        downloadSafeListPending: true
      }
    case Actions.DOWNLOAD_SAFE_LIST_SAVE_TASKID:
      return {
        ...state,
        downloadSafeListTaskId: action.taskId
      }
    case Actions.DOWNLOAD_SAFE_LIST_SUCCESS:
      return {
        ...state,
        downloadSafeListPending: false
      }
    case Actions.DOWNLOAD_SAFE_LIST_ERROR:
      return {
        ...state,
        downloadSafeListPending: false
      }
    case Actions.GET_INGREDIENT_CROSSREACTORS_SUCCESS:
      return {
        ...state,
        ingredientCrossReactors: {
          ...state.ingredientCrossReactors,
          [action.response.id]: action.response,
        },
      };
    case Actions.SET_ALLERGENS:
      return {
        ...state,
        allergens: _.merge(state.allergens, action.selectedIngredients),
      };
    case Actions.SET_ALLERGEN_RESTRICTIVE:
      var clonedAllergen = _.cloneDeep(state.allergens);

      return {
        ...state,
        allergens: _.map(clonedAllergen, (allergen) => {
          if (allergen.id === _.get(action, 'params.id')) {
            return {
              ...allergen,
              restrictive: _.get(action, 'params.restrictive'),
            };
          } else {
            return allergen;
          }
        }),
      };
    case Actions.SET_ALLERGEN_ALLERGEN_NARRATIVE:
      var clonedAllergenNarrative = _.cloneDeep(state.allergens);

      return {
        ...state,
        allergens: _.map(clonedAllergenNarrative, (allergen) => {
          if (allergen.id === _.get(action, 'params.id')) {
            return {
              ...allergen,
              allergen_narrative: _.get(action, 'params.allergen_narrative'),
            };
          } else {
            return allergen;
          }
        }),
      };

    case Actions.SET_ALLERGEN_DIETARY_SHEET:
      var clonedAllergenDietary = _.cloneDeep(state.allergens);

      return {
        ...state,
        allergens: _.map(clonedAllergenDietary, (allergen) => {
          if (allergen.id === _.get(action, 'params.id')) {
            return {
              ...allergen,
              dietary_sheet: _.get(action, 'params.dietary_sheet'),
            };
          } else {
            return allergen;
          }
        }),
      };
    case Actions.REMOVE_ALLERGEN:
      return {
        ...state,
        allergens: _.filter(
          state.allergens,
          (allergen) => allergen.id !== _.get(action, 'params.id'),
        ),
      };
    case Actions.UPDATE_CATEGORIES:
      var { category, currentCategory } = action.params;
      var clonedSelectedCategories = _.cloneDeep(
        _.get(state, 'selectedCategories'),
      );

      var newSelectedCategories = toggleSelectedCategories(
        clonedSelectedCategories,
        category,
        !_.get(currentCategory, 'isChecked'),
      );

      return {
        ...state,
        selectedCategories: newSelectedCategories,
      };
    case Actions.INITIALIZE_CATEGORIES:
      return {
        ...state,
        selectedCategories: _.flattenDeep(
          _.map(action.categories, (category) =>
            initializeCategories(category, false),
          ),
        ),
      };
    case Actions.TOGGLE_ALL_CATEGORIES:
      var clonedCategories = _.cloneDeep(_.get(state, 'selectedCategories'));

      return {
        ...state,
        selectedCategories: _.map(clonedCategories, (category) => {
          const categoryKey = _.keys(category)[0];

          return {
            [categoryKey]: {
              ...category[categoryKey],
              isChecked: action.checkValue,
              isIndeterminate: false,
            },
          };
        }),
      };

    case Actions.SET_RESTRICTIVE_PRESENT:
      return {
        ...state,
        fragrancePresent: action.payload,
      };

    case Actions.SET_COMMON_ALLERGENS:
      var commonAllergenValue = _.map(action.response, (ingredient) => {
        return {
          ...ingredient,
          ingredient_id: ingredient.id,
          text: ingredient.name,
        };
      });

      return {
        ...state,
        commonAllergensList: commonAllergenValue,
      };

    case Actions.GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_SUCCESS:
      return {
        ...state,
        ingredientCrossReactorWithDescription: action.response,
      };

    case Actions.REMOVE_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION:
      return {
        ...state,
        ingredientCrossReactorWithDescription: [],
      };

    case Actions.SET_SELECTED_COMMON_ALLERGENS_ID:
      return {
        ...state,
        selectedCommonAllergensId: [
          ...state.selectedCommonAllergensId,
          action.payload,
        ],
      };

    case Actions.REMOVE_REMOVE_SELECTED_COMMON_ALLERGENS_ID:
      var filteredSelectedCommonAllergenId = _.remove(
        state.selectedCommonAllergensId,
        (item) => {
          if (action.payload.ingredient_id != item.ingredient_id) {
            return item;
          } else if (action.payload.id != item.id) {
            return item;
          }
        },
      );

      return {
        ...state,
        selectedCommonAllergensId: filteredSelectedCommonAllergenId,
      };

    case Actions.GET_ALLERGEN_INFO_SHEETS_SUCCESS:
      return {
        ...state,
        allergenInfoSheets: action.response,
      };

    case Actions.GET_ALLERGEN_NARATIVES_SUCCESS:
      return {
        ...state,
        allergenNaratives: action.response,
      };

    case Actions.GET_DIETARY_SHEETS_SUCCESS:
      return {
        ...state,
        dietarySheets: action.response,
      };

    case Actions.SET_CURRENT_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case Actions.EMAIL_CODESETS:
      return {
        ...state,
        emailCodesetsPending: true,
      };
    case Actions.EMAIL_CODESETS_SUCCESS:
      return {
        ...state,
        emailCodesetsPending: false,
      };
    case Actions.EMAIL_CODESETS_ERROR:
      return {
        ...state,
        emailCodesetsPending: false,
      };
    case Actions.SET_PHYSICIAN_ANSWERS:
      var answerValues;
      if (_.isEmpty(state.physicianQuestionAnswers)) {
        answerValues = [{ ...action.payload }];
      } else {
        var isValueExisting = _.some(state.physicianQuestionAnswers, {
          question: action.payload.question,
        });
        if (isValueExisting) {
          var valueIndex = _.findIndex(
            state.physicianQuestionAnswers,
            (item) => item.question == action.payload.question,
          );
          const tempValue = _.cloneDeep(state.physicianQuestionAnswers);
          tempValue[valueIndex].answer_choices = action.payload.answer_choices;
          answerValues = [...tempValue];
        } else {
          answerValues = _.cloneDeep([
            ...state.physicianQuestionAnswers,
            { ...action.payload },
          ]);
        }
      }

      return {
        ...state,
        physicianQuestionAnswers: answerValues,
      };

    case Actions.PHYSICIAN_QUESTION_ANSWER_SYNC:
      var physicianQuestionAnswers = action.response;

      return {
        ...state,
        physicianQuestionAnswersSync: [...physicianQuestionAnswers.results],
      };
    case Actions.PHYSICIAN_ANSWERS_SYNC_STATUS:
      return {
        ...state,
        physicianQuestionAnswersSyncing: action.payload,
      };
    case Actions.RESET_PHYSICAIN_QUESTIONS_AND_ANSWERS:
      return {
        ...state,
        physicianQuestionAnswersSync: [],
      };
    default:
      return state;
  }
};
