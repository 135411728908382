import * as _ from 'lodash';
import * as Actions from '../actions/safeList';

const initialState = {
  currProductPending: false,
  currProduct: null,
  filters: {
    filterField: '',
    filterString: '',
    unsafe: false,
  },
  productCount: 0,
  products: [],
  productsNext: null,
  resultsBySearch: false,
  searchText: '',
  sorting: 'name',
  unsafeProductCount: 0,
  unsafeProducts: [],
  unsafeProductsNext: null,
  unsafeProductsError: false,
  unsafeProductsPending: false,
  retailerPurchaseQueries: null,
  favoriteProducts: [],
  favoriteProductsCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Product Details
    case Actions.GET_PRODUCT_INFO:
      return {
        ...state,
        currProductPending: true,
      };
    case Actions.MARK_FAVORITE_SUCCESS: {
      const favProductIndex = _.findIndex(
        state.products,
        (product) => product.id === action.productId,
      );

      const productIndex = state.products.findIndex(prod => prod.id == action.productId);
      const updatedProducts = [...state.products];
      updatedProducts[productIndex].is_favorite = !action.isFavorite;
      
      return {
        ...state,
        products: [
          ...updatedProducts,
        ],
        currProduct:{
          ...state.currProduct,
          is_favorite: !action.isFavorite,
        },
        favoriteProductsCount: action.isFavorite ? state.favoriteProductsCount - 1 : state.favoriteProductsCount + 1
      };
    }
    case Actions.GET_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        currProductPending: false,
        currProduct: action.response,
      };
    case Actions.GET_PRODUCT_INFO_ERRORS:
      return {
        ...state,
        currProductPending: false,
        currProduct: action.error,
      };
    // Product List Parameters
    case Actions.SET_PRODUCT_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.CLEAR_PRODUCT_FILTERS:
      return {
        ...state,
        filters: {
          filterField: '',
          filterString: '',
          ..._.get(state, 'filters.unsafe'),
        },
      };
    case Actions.CLEAR_PRODUCTS:
      return {
        ...state,
        productCount: 0,
        products: [],
        unsafeProducts: [],
      };
    case Actions.SET_PRODUCT_SORTING:
      return {
        ...state,
        sorting: action.sorting,
      };
    // Product Search
    case Actions.PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        resultsBySearch: true,
        productCount: _.get(action, 'response.safe_count', 0),
        products: action.paginated ? [
          ...state.products,
          ..._.get(action, 'response.results'),
        ] : _.get(action, 'response.results'),
        searchText: action.searchValue,
        unsafeProductCount: _.get(action, 'response.unsafe_count', 0),
        favoriteProductsCount: _.get(action, 'response.favorite_count', 0),
      };
    case Actions.UNSAFE_PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        resultsBySearch: true,
        unsafeProducts: action.paginated ? [
          ...state.unsafeProducts,
          ..._.get(action, 'response.results'),
        ] : _.get(action, 'response.results'),
        unsafeProductCount: _.get(action, 'response.count', 0),
        unsafeProductsNext: _.get(action, 'response.next'),
        searchText: action.searchValue,
        favoriteProductsCount: _.get(action, 'response.favorite_count', 0),
        productCount: _.get(action, 'response.safe_count', 0),
      };
    case Actions.FAVORITES_SEARCH_SUCCESS:      
      return {
        ...state,
        resultsBySearch: true,
        products: action.paginated ? [
          ...state.products,
          ..._.get(action, 'response.results'),
        ] : _.get(action, 'response.results'),
        productsNext: _.get(action, 'response.next'),
        productCount: _.get(action, 'response.safe_count', 0),
        unsafeProductCount: _.get(action, 'response.unsafe_count', 0),
        favoriteProductsCount: _.get(action, 'response.favorite_count', 0),
      };
    case Actions.CLEAR_SEARCH_TEXT:
      return {
        ...state,
        searchText: '',
      };
    case Actions.GET_RETAILER_PURCHASE_QUERIES_SUCCESS:
      return {
        ...state,
        retailerPurchaseQueries: _.get(action, 'response.results'),
      };
    case Actions.SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS:
      return {
        ...state,
        products: [...state.products, ..._.get(action, 'response.results')],
        productCount: _.get(action, 'response.safe_count', 0),
        unsafeProductCount: _.get(action, 'response.unsafe_count', 0),
        favoriteProductsCount: _.get(action, 'response.favorite_count', 0),
      };
    case Actions.UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS:
      return {
        ...state,
        unsafeProducts: [...state.unsafeProducts, ..._.get(action, 'response.results')],
        productCount: _.get(action, 'response.safe_count', 0),
        unsafeProductCount: _.get(action, 'response.unsafe_count', 0),
        favoriteProductsCount: _.get(action, 'response.favorite_count', 0),
      };
    case Actions.CLEAR_CURRENT_PRODUCT: 
      return {
        ...state,
        currProduct: null,
      };
    case Actions.RESET_SEARCH_FILTERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
