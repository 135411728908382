import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import Header from "../../components/common/Header";
import LeftTabs from "../../components/common/LeftTabs";
import "./AnalyticsPage.scss";
import DisplayPlot from "./DisplayPlot";

function AnalyticsPage() {
  const [activeTab, setActiveTab] = useState();
  const history = useHistory();
  const match = useRouteMatch("/analytics/:slug");

  const plotTabNames = [];

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
      history.push("/analytics/" + tab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab, setActiveTab],
  );

  useEffect(() => {
    if (_.has(match, "params.slug") && _.get(match, "params.slug") !== activeTab) {
      toggle(_.get(match, "params.slug"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const plotSideTabs = [
    {
      displayName: "Login",
      reduxValue: "loginPlot",
      type: "dateTimePlot",
      route: "login-plot",
      plotName: "login",
    },
    {
      displayName: "Top Categories",
      reduxValue: "categoriesPlot",
      type: "countPlot",
      route: "top-categories-plot",
      plotName: "category",
    },
    {
      displayName: "Top Subcategories",
      reduxValue: "subcategoriesPlot",
      type: "countPlot",
      route: "top-subcategories-plot",
      plotName: "subcategory",
    },
    {
      displayName: "Top Allergens",
      reduxValue: "allergenPlot",
      type: "countPlot",
      route: "top-allergens",
      plotName: "allergen",
    },
    {
      displayName: "Patient Registration",
      reduxValue: "totalUsersPlot",
      type: "dateTimePlot",
      route: "patient-registration",
      plotName: "patients_joined",
    },
    {
      displayName: "Top Products",
      reduxValue: "topProductsPlot",
      type: "countPlot",
      route: "top-products",
      plotName: "product",
    },
  ];

  return (
    <div className="analytics-page">
      <Route exact path="/analytics">
        <Redirect from="/analytics" to={`/analytics/${plotSideTabs[0].route}`} />
      </Route>
      <Header title="Analytics" />
      <div className="body">
        <LeftTabs activeTab={activeTab} setActiveTab={toggle} tabList={plotTabNames} />
        <TabContent activeTab={activeTab}>
          {plotSideTabs.map((item) => {
            const pathName = `/analytics/${item.route}`;

            plotTabNames.push({
              id: item.route,
              name: item.displayName,
            });

            return (
              <TabPane tabId={item.route} key={item.route}>
                <Route key={`route-${item.route}`} path={pathName}>
                  <DisplayPlot plotDetails={item} />
                </Route>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    </div>
  );
}

export default AnalyticsPage;
