import {
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';
import {
  apiGet,
  apiPost,
} from 'acds-react-core';

import * as Actions from '../actions/safeList';

function* doGetProductInfo (params) {
  const id = _.get(params, 'productId.productId');

  try {
    const response = yield apiGet(`api/products/product-list/${id}/`);

    yield put({
      type: Actions.GET_PRODUCT_INFO_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_PRODUCT_INFO_ERRORS,
      error: error.response.data,
    });
  }
}

function* doMarkFavorite ({productId, isFavorite}) {
  try {
    yield apiPost(`api/products/${productId}/favorite/`);
    yield put({
      type: Actions.MARK_FAVORITE_SUCCESS,
      productId,
      isFavorite,
    });
  } catch (error) {
    yield put({
      type: Actions.MARK_FAVORITE_ERRORS,
      error: error.message,
    });
  }
}

function* doGetRetailerPurchaseQueries () {
  try {
    const response = yield apiGet('api/products/retailer-purchase-queries/');

    yield put({
      type: Actions.GET_RETAILER_PURCHASE_QUERIES_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_RETAILER_PURCHASE_QUERIES_ERROR,
      error: error.response.data,
    });
  }
}

export default function* safeListSagas () {
  yield takeLatest(Actions.GET_PRODUCT_INFO, doGetProductInfo);
  yield takeLatest(Actions.MARK_FAVORITE, doMarkFavorite);
  yield takeLatest(Actions.GET_RETAILER_PURCHASE_QUERIES, doGetRetailerPurchaseQueries);
}
