import * as Actions from '../actions/app-ui';

export const initialState = {
  sideBarOpen: false,
  productListScrollPosition: null,
  openGroupValue: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_SIDEBAR_STATE:
      return {
        ...state,
        sideBarOpen: action.payload,
      };

    case Actions.SET_SCROLL_POSITION:
      return {
        ...state,
        productListScrollPosition: action.payload
      }
    case Actions.SET_OPEN_GROUP_VALUE:
      return {
        ...state,
        openGroupValue: action.payload
      }
    default:
      return state;
  }
};
