import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as _ from 'lodash';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const user = useSelector((state) => state.account.user);
  const userLoading = useSelector((state) => state.account.userLoading);

  let userGroup;
  if (_.get(user, 'groups.0')) {
    userGroup = _.get(user, 'groups.0.name', 'anonymous');
  }

  return (
    <Route {...rest}
      render={(props) => (!userLoading &&
        (userGroup === 'acds' ?
          <Redirect to='/safe-list' />
          :
          (user && restricted ?
            <Redirect to={ _.get(user, 'show_tutorial', false) ? '/onboarding' : '/welcome'} />
            :
            <Suspense fallback={<div></div>}><Component {...props} /></Suspense>
          )
        )
      )} 
    />    
  );
};
export default PublicRoute;
