export const GET_COMMENT_REVIEW_LAST_UPDATED_DATE = 'COMMENT_REVIEW/GET_COMMENT_REVIEW_LAST_UPDATED_DATE';
export const SET_COMMENT_REVIEW_LAST_UPDATED_DATE = 'COMMENT_REVIEW/SET_COMMENT_REVIEW_LAST_UPDATED_DATE';
export const SET_COMMENT_REVIEW_LAST_UPDATED_STATUS = 'COMMENT_REVIEW/SET_COMMENT_REVIEW_LAST_UPDATED_STATUS';
export const SET_COMMENT_REVIEW_LAST_UPDATED_ERROR = 'COMMENT_REVIEW/SET_COMMENT_REVIEW_LAST_UPDATED_ERROR';
export const GET_PRODUCTS_COMMENTS = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS';
export const GET_PRODUCTS_COMMENTS_SUCCESS = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS_SUCCESS';
export const GET_PRODUCTS_COMMENTS_ERROR = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS_ERROR';
export const GET_PRODUCTS_COMMENTS_NEXT = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS_NEXT';
export const GET_PRODUCTS_COMMENTS_NEXT_SUCCESS = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS_NEXT_SUCCESS';
export const GET_PRODUCTS_COMMENTS_NEXT_ERROR = 'COMMENT_REVIEW/GET_PRODUCTS_COMMENTS_NEXT_ERROR';
export const UPDATE_PRODUCT_COMMENT_STATUS = 'COMMENT_REVIEW/UPDATE_PRODUCT_COMMENT_STATUS';

export const getCommentReviewLastUpdatedDate = (payload) => {
  return {
    type: GET_COMMENT_REVIEW_LAST_UPDATED_DATE,
    payload,
  };
};

export const setCommentReviewLastUpdatedDate = (payload) => {
  return {
    type: SET_COMMENT_REVIEW_LAST_UPDATED_DATE,
    payload,
  };
};

export const getProductsComments = (status) => {
  return {
    type: GET_PRODUCTS_COMMENTS,
    status,
  };
};

export const getProductCommentsNext = () => {
  return {
    type: GET_PRODUCTS_COMMENTS_NEXT,
  };
};

export const updateCommentReview = (reviewAction) => {
  return{
    type: UPDATE_PRODUCT_COMMENT_STATUS,
    reviewAction,
  };
};
