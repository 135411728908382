import { apiGet } from 'acds-react-core';
import {
  put,
  takeLatest,
} from 'redux-saga/effects';
import { GET_PLOT_DATA, SET_PLOT_DATA } from '../actions/plot';
  
function* doGetPlot (action) {
  const url = 'api/analytics/graph/';

  const data = yield apiGet(url,  action.payload);
  yield put({
    type: SET_PLOT_DATA,
    data,
  });
}
  
export default function* routingAndToastSagas () {
  yield takeLatest(GET_PLOT_DATA, doGetPlot);
}
  
