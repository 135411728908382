import * as _ from 'lodash';
import * as Actions from '../actions/learning';

const initialState = {
  allergenNarratives: [],
  allergenNarrativesError: null,
  allergenNarrativesNext: null,
  allergenNarrativesPending: false,
  allergenNarrativeSearchStatus: false,
  allergenNarrativeCount: 0,
  allergenNarrativeInitialLoad: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALLERGEN_NARRATIVES:
    case Actions.GET_NEXT_ALLERGEN_NARRATIVES:
      return {
        ...state,
        allergenNarrativesPending: true,
      };
    case Actions.GET_ALLERGEN_NARRATIVES_SUCCESS:
      return {
        ...state,
        allergenNarrativesPending: false,
        allergenNarratives: [
          ...state.allergenNarratives,
          ..._.get(action, 'response.results'),
        ],
        allergenNarrativesNext: _.get(action, 'response.next'),
      };
    case Actions.GET_ALLERGEN_NARRATIVES_ERROR:
      return {
        ...state,
        allergenNarrativesPending: false,
        allergenNarrativesError: action.error,
      };
    case Actions.CLEAR_ALLERGEN_NARRATIVES:
      return {
        ...state,
        allergenNarratives: [],
      };
    case Actions.ALLERGEN_NARRATIVE_SEARCH_SUCCESS:
      return {
        ...state,
        allergenNarrativesPending: false,
        allergenNarratives: action.paginated ? [
          ...state.allergenNarratives,
          ..._.get(action, 'formattedResult'),
        ] : _.get(action, 'formattedResult'),
        searchText: action.searchValue,
        allergenNarrativeCount: _.get(action, 'count', 0),
      };
    case Actions.ALLERGEN_NARRATIVE_SEARCH_STATUS:
      return {
        ...state,
        allergenNarrativeSearchStatus: _.get(action, 'status', false),
      };
    case Actions.ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS:
      return {
        ...state,
        allergenNarrativeInitialLoad: _.get(action, 'status', false),
      };
    default:
      return state;
  }
};
