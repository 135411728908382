// Product details
export const GET_PRODUCT_INFO = 'SAFELIST/GET_PRODUCT_INFO';
export const GET_PRODUCT_INFO_SUCCESS = 'SAFELIST/GET_PRODUCT_INFO_SUCCESS';
export const GET_PRODUCT_INFO_ERRORS = 'SAFELIST/GET_PRODUCT_INFO_ERRORS';
export const GET_RETAILER_PURCHASE_QUERIES = 'SAFELIST/GET_RETAILER_PURCHASE_QUERIES';
export const GET_RETAILER_PURCHASE_QUERIES_SUCCESS = 'SAFELIST/GET_RETAILER_PURCHASE_QUERIES_SUCCESS';
export const GET_RETAILER_PURCHASE_QUERIES_ERROR = 'SAFELIST/GET_RETAILER_PURCHASE_QUERIES_ERROR';
// Mark Product Favorite
export const MARK_FAVORITE = 'SAFELIST/MARK_FAVORITE';
export const MARK_FAVORITE_SUCCESS = 'SAFELIST/MARK_FAVORITE_SUCCESS';
export const MARK_FAVORITE_ERRORS = 'SAFELIST/MARK_FAVORITE_ERRORS';
// Product Filters
export const CLEAR_PRODUCTS = 'SAFELIST/CLEAR_PRODUCTS';
export const SET_PRODUCT_FILTERS = 'SAFELIST/SET_PRODUCT_FILTERS';
export const CLEAR_PRODUCT_FILTERS = 'SAFELIST/CLEAR_PRODUCT_FILTERS';
export const SET_PRODUCT_SORTING = 'SAFELIST/SET_PRODUCT_SORTING';
export const CLEAR_CURRENT_PRODUCT = 'SAFELIST/CLEAR_CURRENT_PRODUCT';
// Search
export const PRODUCT_SEARCH_SUCCESS = 'SAFELIST/PRODUCT_SEARCH_SUCCESS';
export const UNSAFE_PRODUCT_SEARCH_SUCCESS = 'SAFELIST/UNSAFE_PRODUCT_SEARCH_SUCCESS';
export const CLEAR_SEARCH_TEXT = 'SAFELIST/CLEAR_SEARCH_TEXT';
export const FAVORITES_SEARCH_SUCCESS = 'SAFELIST/FAVORITES_SEARCH_SUCCESS'; 
export const PRODUCT_ELASTIC_SEARCH_NEXT = 'SAFELIST/PRODUCT_ELASTIC_SEARCH_NEXT';
export const SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS = 'SAFELIST/SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS';
export const UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS = 'SAFELIST/UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS';
export const SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_ERROR = 'SAFELIST/SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_ERROR';
export const UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_ERROR = 'SAFELIST/UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_ERROR';
export const GET_CROSS_REACTORS = 'SEARCH/GET_CROSS_REACTORS';
export const RESET_SEARCH_FILTERS = 'SEARCH/RESET_SEARCH_FILTERS';

export const getProductInfo = (productId) => {
  return {
    type: GET_PRODUCT_INFO,
    productId,
  };
};

export const markProductFavorite = (productId, isFavorite) => {
  return {
    type: MARK_FAVORITE,
    productId,
    isFavorite,
  };
};

export const safelistSetProductFilters = (filters) => {
  return {
    type: SET_PRODUCT_FILTERS,
    filters,
  };
};

export const safelistClearProductFilters = () => {
  return {
    type: CLEAR_PRODUCT_FILTERS,
  };
};

export const safelistSetProductSorting = (sorting) => {
  return {
    type: SET_PRODUCT_SORTING,
    sorting,
  };
};

export const safeListClearSearchText = () => {
  return {
    type: CLEAR_SEARCH_TEXT,
  };
};

export const clearProducts = () => {
  return {
    type: CLEAR_PRODUCTS,
  };
};

export const getRetailerPurchaseQueries = () => {
  return {
    type: GET_RETAILER_PURCHASE_QUERIES,
  };
};

export const getNextElasticProductSearch = (params) => {
  return {
    type: PRODUCT_ELASTIC_SEARCH_NEXT,
    params,
  };
};

export const getCrossReactorsList = (params) => {
  return {
    type: GET_CROSS_REACTORS,
    params,
  };
};

export const  clearCurrentProduct = () => {
  return {
    type: CLEAR_CURRENT_PRODUCT,
  };
};

export const resetSearchFilters = () => {
  return {
    type: RESET_SEARCH_FILTERS,
  };
};
