import {createSelector} from 'reselect';
import * as _ from 'lodash';

const selectUser = (s) => s.account.user;
const selectAllergenNarratives = (s) => s.learningReducer.allergenNarratives;
const selectAllergenNarrativesNext = (s) => s.learningReducer.allergenNarrativesNext;

export const selectUserAllergens = createSelector(
  selectUser,
  (user) => _.get(user, 'allergens', []),
);

export const selectPatientAllergenNarratives = createSelector(
  selectUserAllergens,
  selectAllergenNarratives,
  selectAllergenNarrativesNext,
  (
    allergens,
    narratives,
    next,
  ) => {
    if (!_.isNull(next)) {
      // If there are more narratives, don't combine with the allergens list yet
      return narratives;
    } else if (!_.isNull(allergens)) {
      const narrativeIngredientIds = _.map(
        narratives,
        (narrative) => _.toString(_.get(narrative, 'ingredient.id')),
      );

      const missingAllergens = _.filter(
        allergens,
        (allergen) => _.findIndex(
          narrativeIngredientIds,
          (narrativeId) => narrativeId === _.toString(allergen.id),
        ),
      );

      const newMissingAllergenList = _.map(
        missingAllergens,
        (allergen) => ({
          noFileAttached: true,
          ingredient: allergen,
        }),
      );
      
      // Order narrative results by ingredient name
      return _.orderBy(
        // Merge allergen narratives with user allergens not included in narratives
        _.concat(narratives, newMissingAllergenList),
        'ingredient.name',
        'asc',
      );
    } else {
      // All other circumstances shouldn't get here, but return just in case
      return narratives;
    }
  }
);
