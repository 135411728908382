import React from 'react';

import './HowCampWorks.scss';

export default function HowCampWorks () {

  return (
    <div className="how">
      <div>
        <div className="card mt-0">
          <h2  className='about-header'>Contact Allergen Management Program (CAMP)</h2>
          <p  className='about-summary'>
            The Contact Allergen Management Program (CAMP) is an ACDS member only benefit which is available to ACDS
             members and their respective patients. This web-based resource is designed to help patients manage
             allergic contact dermatitis and find personal care products that are safe for them to use.
          </p>
          <div className="about-summary-t">
            <ul>
              <li>Following patch testing, your physician determines if you are allergic to certain allergens.</li>
              <li>Using CAMP, a personalized Safe List is generated by your ACDS physician, which identifies products
                 that are free of your known allergens or ingredients that are closely related to your allergen.</li>
              <li>Allergen search codes will be given to you as the patient. These are unique codes tied to your
                specific allergens, as identified by your ACDS physician. Allergen search codes are required to
                complete the registration process and utilize CAMP.</li>
              <li>Access the Learning Center to learn more about contact dermatitis, patch testing and your specific
                 allergens.</li>
              <li>Can’t find a product on your list? Use our Scan feature to scan a product’s information into the
                 system and receive immediate feedback on whether or not it’s safe for you to use.</li>
            </ul>
          </div>
          <div className='p-3 about-summary-info'>
            <p className="about-summary-small">
            The products illustrated on your personalized list are SAFE FOR YOU TO USE! Be sure to use the exact
             products as listed on your safe list.
            </p>
            <p className="about-summary-small">
            Product ingredient information is imported into CAMP from several different sources and reviewed on a
             continual basis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
