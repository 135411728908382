export const GET_INGREDIENTS = "INGREDIENTS_REVIEW/GET_INGREDIENTS";
export const GET_INGREDIENTS_SUCCESS = "INGREDIENTS_REVIEW/GET_INGREDIENTS_SUCCESS";
export const GET_INGREDIENTS_ERROR = "INGREDIENTS_REVIEW/GET_INGREDIENTS_ERROR";
export const GET_INGREDIENTS_NEXT = "INGREDIENTS_REVIEW/GET_INGREDIENTS_NEXT";
export const GET_INGREDIENTS_NEXT_SUCCESS = "INGREDIENTS_REVIEW/GET_INGREDIENTS_NEXT_SUCCESS";
export const GET_INGREDIENTS_NEXT_ERROR = "INGREDIENTS_REVIEW/GET_INGREDIENTS_NEXT_ERROR";
export const GET_INGREDIENTS_DATE_RANGE = "INGREDIENTS_REVIEW/GET_INGREDIENTS_DATE_RANGE";
export const GET_INGREDIENTS_DATE_RANGE_SUCCESS = "INGREDIENTS_REVIEW/GET_INGREDIENTS_DATE_RANGE_SUCCESS";
export const GET_INGREDIENTS_DATE_RANGE_ERROR = "INGREDIENTS_REVIEW/GET_INGREDIENTS_DATE_RANGE_ERROR";
export const UPDATE_INGREDIENT = "INGREDIENTS_REVIEW/UPDATE_INGREDIENT";
export const UPDATE_INGREDIENT_SUCCESS = "INGREDIENTS_REVIEW/UPDATE_INGREDIENT_SUCCESS";
export const UPDATE_INGREDIENT_ERROR = "INGREDIENTS_REVIEW/UPDATE_INGREDIENT_SUCCESS";
export const CROSS_REACTOR_TO_EXCLUDE = "INGREDIENT_REVIEW/CROSS_REACTORS_TO_EXCLUDE";
export const BULK_UPDATE_INGREDIENT = "INGREDIENTS_REVIEW/BULK_UPDATE_INGREDIENT";
export const BULK_UPDATE_INGREDIENT_SUCCESS = "INGREDIENTS_REVIEW/BULK_UPDATE_INGREDIENT_SUCCESS";
export const BULK_UPDATE_INGREDIENT_ERROR = "INGREDIENTS_REVIEW/BULK_UPDATE_INGREDIENT_SUCCESS";
export const BULK_DELETE_INGREDIENT = "INGREDIENTS_REVIEW/BULK_DELETE_INGREDIENT";
export const BULK_DELETE_INGREDIENT_SUCCESS = "INGREDIENTS_REVIEW/BULK_DELETE_INGREDIENT_SUCCESS";
export const BULK_DELETE_INGREDIENT_ERROR = "INGREDIENTS_REVIEW/BULK_DELETE_INGREDIENT_ERROR";
export const FRAGRANCE_TO_EXCLUDE = "INGREDIENTS_REVIEWS/FRAGRANCE_TO_EXCLUDE";
export const GET_SELECTED_INGREDIENT = "INGREDIENTS_REVIEWS/GET_SELECTED_INGREDIENT";
export const SET_SELECTED_INGREDIENT = "INGREDIENTS_REVIEWS/SET_SELECTED_INGREDIENT"
export const CLEAR_SELECTED_INGREDIENT = "INGREDIENTS_REVIEWS/CLEAR_SELECTED_INGREDIENT"
export const GET_SELECTED_INGREDIENT_SUCCESS = "INGREDIENTS_REVIEWS/GET_SELECTED_INGREDIENT_SUCCESS";
export const GET_SELECTED_INGREDIENT_ERROR = "INGREDIENTS_REVIEWS/GET_SELECTED_INGREDIENT_ERROR";
export const REPLACE_SELECTED_INGREDIENT_SUGGESTION = "INGREDIENTS_REVIEWS/REPLACE_SELECTED_INGREDIENT_SUGGESTION";
export const REPLACE_SELECTED_INGREDIENT_SUGGESTION_SUCCESS =
  "INGREDIENTS_REVIEWS/REPLACE_SELECTED_INGREDIENT_SUGGESTION_SUCCESS";
export const REPLACE_SELECTED_INGREDIENT_SUGGESTION_ERROR =
  "INGREDIENTS_REVIEWS/REPLACE_SELECTED_INGREDIENT_SUGGESTION_ERROR";
export const GET_CROSS_REACTORS_LIST = "INGREDIENTS_REVIEWS/GET_CROSS_REACTORS_LIST";
export const GET_CROSS_REACTORS_LIST_SUCCESS = "INGREDIENTS_REVIEWS/GET_CROSS_REACTORS_LIST_SUCCESS";
export const GET_CROSS_REACTORS_LIST_ERROR = "INGREDIENTS_REVIEWS/GET_CROSS_REACTORS_LIST_ERROR";
export const UPDATE_INGREDIENT_CROSS_REACTOR_LIST = "INGREDIENTS_REVIEWS/UPDATE_INGREDIENT_CROSS_REACTOR_LIST"


export const getIngredients = (filters) => {
  return {
    type: GET_INGREDIENTS,
    filters,
  };
};

export const getIngredientsDateRange = (status) => {
  return {
    type: GET_INGREDIENTS_DATE_RANGE,
    status,
  };
};

export const getIngredientsNext = () => {
  return {
    type: GET_INGREDIENTS_NEXT,
  };
};

export const updateIngredient = (reviewAction) => {
  return {
    type: UPDATE_INGREDIENT,
    reviewAction,
  };
};

export const excludeCrossReactors = (crossReactorId, ingredientId) => {
  return {
    type: CROSS_REACTOR_TO_EXCLUDE,
    crossReactorId,
    ingredientId,
  };
};

export const bulkUpdateIngredient = (status, selectedIngredients) => {
  return {
    type: BULK_UPDATE_INGREDIENT,
    status,
    selectedIngredients,
  };
};

export const bulkDeleteIngredient = (status, selectedIngredients) => {
  return {
    type: BULK_DELETE_INGREDIENT,
    status,
    selectedIngredients,
  }
}

export const excludeIngredientReviewFragrance = (ingredientId) => {
  return {
    type: FRAGRANCE_TO_EXCLUDE,
    ingredientId,
  };
};

export const getSelectedIngredientsSuggestion = (ingredientId) => {
  return {
    type: GET_SELECTED_INGREDIENT,
    ingredientId: ingredientId,
  };
};

export const clearSelectedIngredientsSuggestions = () => {
  return {
    type: CLEAR_SELECTED_INGREDIENT
  }
}

export const replaceSelectedIngredient = (ingredientId, suggestedIngredients) => {
  return {
    type: REPLACE_SELECTED_INGREDIENT_SUGGESTION,
    ingredientId,
    suggestedIngredients,
  };
};

export const getCrossReactorsList = () => {
  return {
    type: GET_CROSS_REACTORS_LIST
  }
}

export const updateIngredientCrossReactorList = (crossReactor, ingredientId) => {
  return {
    type: UPDATE_INGREDIENT_CROSS_REACTOR_LIST,
    crossReactor,
    ingredientId
  }
}
