import classnames from 'classnames';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { clearSelectedIngredients } from 'acds-redux-core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { clearCodes } from '../../redux/actions/physician';
import './LeftTabs.scss';

export default function LeftTabs ({ activeTab, setActiveTab, tabList, subTab = '' }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const userGroup = _.get(user, 'groups.0.name', 'anonymous');
  const history = useHistory();

  useEffect(() => {
    setActiveTab(_.get(tabList, '[0].id'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOpen = (tab) => {
    if (activeTab !== tab || !!subTab) setActiveTab(tab);
    if(tab === 'search' ) {
      dispatch(clearCodes());
      dispatch(clearSelectedIngredients());
      history.push('/allergen/search');
    } else if (tab === 'code-management') {
      history.push('/allergen/code-management');
    }
  };

  const navList = _.map(tabList, (tab) => {
    const tabs = [];
    let canView = true;
    const userTypes = _.get(tab, 'userTypes');
    if (!!userTypes && _.findIndex(userTypes, (userType) => userType === userGroup) === -1) {
      canView = false;
    }
    if (canView) {
      if (tab.notNavItem) {
        tabs.push(<span key={tab.id}>{tab.name}</span>);
      } else {
        tabs.push(
          <NavItem className={classnames({ active: activeTab === tab.id })} key={tab.id} style={{ width: '250px' }}>
            <NavLink
              onClick={() => {
                toggleOpen(tab.id);
              }}
            >
              {tab.name}
            </NavLink>
          </NavItem>,
        );
      }
    }

    return tabs;
  });

  return (
    <Nav tabs className="left-tabs">
      {navList}
    </Nav>
  );
}
