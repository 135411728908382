import MTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Spinner } from "reactstrap";
import { getPlotData } from "../../redux/actions/plot";
import "./DisplayPlot.scss";

function DisplayPlot({ plotDetails }) {
  const options = [
    { value: 10, label: "Top 10" },
    { value: 20, label: "Top 20" },
    { value: 25, label: "Top 25" },
    { value: 50, label: "Top 50" },
    { value: 100, label: "Top 100" },
  ];
  const CUSTOM_TOOLTIP_PLACEMENT = "bottom-end";

  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [count, setCount] = useState(options[0]);
  const fetchingPlotData = useSelector((state) => state.plotDetails.plotDataFetching);
  const plotData = useSelector((state) => state.plotDetails.plotData);

  // plotData

  const dispatch = useDispatch();
  const dateFormat = "YYYY-MM-DD";

  const handleChange = (selectedOption) => {
    setCount(selectedOption);
  };

  useEffect(() => {
    const plotData = {
      graph_name: plotDetails.plotName,
      start_date: startDate.format(dateFormat),
      end_date: endDate.format(dateFormat),
    };

    if (plotDetails?.type !== "dateTimePlot") {
      plotData.count = count.value;
    }

    dispatch(getPlotData(plotData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, count]);

  const RenderFilters = () => {
    switch (plotDetails.type) {
      case "countPlot":
        return (
          <div className="d-flex flex-row mx-1 my-2 gap-2">
            <div style={{ width: 300 }} className="mx-2">
              <Select options={options} value={count} className="my-2" onChange={handleChange} />
            </div>
            <div className="mx-2">
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format="MMM D, YYYY"
                    maxDate={endDate}
                    slotProps={{
                      field: {
                        readOnly: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <div className="ml-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format="MMM D, YYYY"
                    minDate={startDate}
                    slotProps={{
                      field: {
                        readOnly: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        );
      case "dateTimePlot":
        return (
          <div className="d-flex flex-row mx-1 my-2 gap-3">
            <div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format="MMM D, YYYY"
                    maxDate={endDate}
                    slotProps={{
                      field: {
                        readOnly: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <div className="ml-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format="MMM D, YYYY"
                    minDate={startDate}
                    slotProps={{
                      field: {
                        readOnly: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <MTooltip {...props} arrow placement={CUSTOM_TOOLTIP_PLACEMENT} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const RenderPlot = () => {
    if (fetchingPlotData) {
      return (
        <div className="safe-list-loading-container">
          <div className="spinner-container">
            <Spinner className="safe-list-spinner" color="#00c9b7" />
          </div>
        </div>
      );
    }

    if (plotDetails.type === "countPlot") {
      const plotLabel = _.get(plotData, "data.0.y", []);
      const plotValue = _.get(plotData, "data.0.x", []);

      const result = plotLabel.map((value, index) => ({ label: value, value: plotValue[index] })).reverse();

      const maxLabelValue = _.max(plotValue);

      return (
        <div className="count-plot-container">
          {result.map((item, index) => {
            const barLength = (+item.value / maxLabelValue) * 100;

            return (
              <div key={index}>
                <div className="data-container">
                  <div className="label-container">
                    <span className="label-span">
                      <CustomTooltip title={item.label}>{item.label}</CustomTooltip>
                    </span>
                  </div>
                  <div className="w-100 bar-container mx-1">
                    <div className="bar-style" style={{ width: `${barLength}%` }} />
                    <div className="mx-1">{item.value}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <Plot
        data={plotData["data"]}
        layout={plotData["layout"]}
        style={{ width: "100%", height: "78vh" }}
        config={{
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ["lasso2d", "select2d"],
        }}
      />
    );
  };

  return (
    <div className="m-3">
      <RenderFilters />
      <RenderPlot />
    </div>
  );
}

export default DisplayPlot;
