import React from 'react';
import Header from '../../components/common/Header';
import { Row } from 'reactstrap';
import logo from '../../assets/images/ACDS-Logo.png';

import './Page404.scss';
  
export default function Page404 () {
  return (
    <div className='page-404'>
      <Header showBack={true} />
      <div className='camp-logo'>
        <img src={logo} alt='ACDS logo' />
      </div>
      <Row className='page-404--message'>
        <h1>This page does not exist.</h1>
      </Row>
      <Row className='page-404--message'>
        <h3>If you feel you have received this message in error, please contact CAMP.</h3>
      </Row>
    </div>
  );
}
