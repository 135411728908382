export const PATCH_CHANGE_PASSWORD = 'PATCH_CHANGE_PASSWORD';
export const PATCH_CHANGE_PASSWORD_SUCCESS = 'PATCH_CHANGE_PASSWORD_SUCCESS';
export const PATCH_CHANGE_PASSWORD_ERRORS = 'PATCH_CHANGE_PASSWORD_ERRORS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERRORS = 'RESET_PASSWORD_ERRORS';
export const GET_REVIEW_PENDING_LIST = 'GET_REVIEW_PENDING_LIST';
export const GET_REVIEW_PENDING_LIST_SUCCESS = 'GET_REVIEW_PENDING_LIST_SUCCESS';

export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
export const DECLINE_REVIEW = 'DECLINE_REVIEW';
export const DECLINE_REVIEW_SUCCESS = 'DECLINE_REVIEW_SUCCESS';

export const GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE = 'GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE';
export const
  GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS
 = 'GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS';

export const GET_NEXT_COMMENT_REVIEWS = 'GET_NEXT_COMMENT_REVIEWS';
export const GET_NEXT_COMMENT_REVIEWS_SUCCESS = 'GET_NEXT_COMMENT_REVIEWS_SUCCESS';

export const GET_PRODUCTS_REVIEW_PENDING_LIST = 'GET_PRODUCTS_REVIEW_PENDING_LIST';
export const GET_PRODUCTS_REVIEW_PENDING_LIST_SUCCESS = 'GET_PRODUCTS_REVIEW_PENDING_LIST_SUCCESS';

export const GET_NEXT_PRODUCT_REVIEWS = 'GET_NEXT_PRODUCT_REVIEWS';
export const GET_NEXT_PRODUCT_REVIEWS_SUCCESS = 'GET_NEXT_PRODUCT_REVIEWS_SUCCESS';

export const SUBMIT_PRODUCT_REVIEW = 'SUBMIT_PRODUCT_REVIEW';
export const SUBMIT_PRODUCT_REVIEW_SUCCESS = 'SUBMIT_PRODUCT_REVIEW_SUCCESS';
export const DECLINE_PRODUCT_REVIEW = 'DECLINE_PRODUCT_REVIEW';
export const DECLINE_PRODUCT_REVIEW_SUCCESS = 'DECLINE_PRODUCT_REVIEW_SUCCESS';

export const GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE = 'GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE';
export const
  GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS
 = 'GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS';

export const GET_PRODUCT_REVIEW_PENDING_SORTBY = 'GET_PRODUCT_REVIEW_PENDING_SORTBY';
export const GET_PRODUCT_REVIEW_PENDING_SORTBY_SUCCESS = 'GET_PRODUCT_REVIEW_PENDING_SORTBY_SUCCESS';

export const SUBMIT_PRODUCT_DETAILS = 'SUBMIT_PRODUCT_DETAILS';
export const SUBMIT_PRODUCT_DETAILS_SUCCESS = 'SUBMIT_PRODUCT_DETAILS_SUCCESS';

export const GET_CURRENT_PRODUCT_INFO = 'GET_CURRENT_PRODUCT_INFO';
export const GET_CURRENT_PRODUCT_INFO_SUCCESS = 'GET_CURRENT_PRODUCT_INFO_SUCCESS';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';

export const changePassword = (params) => {
  return {
    type: PATCH_CHANGE_PASSWORD,
    params,
  };
};

export const resetPassword = (params) => {
  return {
    type: RESET_PASSWORD,
    params,
  };
};

export const getReviewPending = (params) => {
  return {
    type: GET_REVIEW_PENDING_LIST,
    params,
  };
};

export const submittingReview = (params) => {
  return {
    type:SUBMIT_REVIEW,
    params,
  };
};

export const decliningReview = (params) => {
  return {
    type:DECLINE_REVIEW,
    params,
  };
};

export const getCommentReviewFilterByDate = (params) => {
  return {
    type: GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE,
    params,
  };
};

export const getNextCommentsReview = (params) => {
  return {
    type: GET_NEXT_COMMENT_REVIEWS,
    params,
  };
};

export const getProductsReviewPendingList = (params) => {
  return {
    type: GET_PRODUCTS_REVIEW_PENDING_LIST,
    params,
  };
};

export const getNextProductsReview = (params) => {
  return {
    type: GET_NEXT_PRODUCT_REVIEWS,
    params,
  };
};

export const submittingProductReview = (params) => {
  return {
    type:SUBMIT_PRODUCT_REVIEW,
    params,
  };
};

export const decliningProductReview = (params) => {
  return {
    type:DECLINE_PRODUCT_REVIEW,
    params,
  };
};

export const getProductReviewFilterByDate = (params) => {
  
  return {
    type: GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE,
    params,
  };
};

export const getProductReviewBySortBy = (params) => {

  return {
    type: GET_PRODUCT_REVIEW_PENDING_SORTBY,
    params,
  };
};

export const submittingProductReviewAll = (params) => {
  return {
    type:SUBMIT_PRODUCT_DETAILS,
    params,
  };
};

export const getCurrentProductInfo = (params) => {
  return{
    type:GET_CURRENT_PRODUCT_INFO,
    params,
  };
};

export const deleteAccountUser = (params) => {
  return {
    type: DELETE_ACCOUNT,
    params,
  };
};
