import React from 'react';
import { Spinner } from 'reactstrap';
import './LoadingScreen.scss';

function LoadingScreen () {
  return (
    <div className="loading-container">
      <Spinner color="#00c9b7" style={{ width: '3rem', height: '3rem' }} />
    </div>
  );
}

export default LoadingScreen;
