import * as Actions from '../actions/plot';

export const initialState = {
  plotData: {},
  plotDataFetching: false,
  plotDataFetchingError: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_PLOT_DATA:
      return {
        ...state,
        plotDataFetching: true,
      };
    case Actions.SET_PLOT_DATA:
      
      return {
        ...state,
        plotData: action.data.results,
        plotDataFetching: false,
      };
    case Actions.SET_PLOT_DATA_ERROR:
      return {
        ...state,
        plotDataFetchingError: action.payload,
        plotDataFetching: false,
      };
    default:
      return state;
  }
};
