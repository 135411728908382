import {createSelector} from 'reselect';
import * as _ from 'lodash';

export const selectNotificationSettings = (s) => s.notificationSettings.notificationSettings;
export const selectNotificationSettingTypes = (s) => s.notificationSettings.notificationSettingTypes;
export const selectNotificationSettingsPending = (s) => s.notificationSettings.submitPending;
export const selectNotificationSettingsError = (s) => s.notificationSettings.error;
export const selectMappedNotificationSettings = createSelector(
  selectNotificationSettings,
  selectNotificationSettingTypes,
  (userSettings, settingTypes) =>
    _.map(settingTypes, (settingType) => {
      const userSetting = _.find(userSettings, {
        notif_type: settingType.notif_type,
      });
      if (userSetting) return {...settingType, ...userSetting};

      return {...settingType, allowed: true};
    })
);
