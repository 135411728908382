import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BellIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import {
  differenceInCalendarDays,
  format,
  formatDistanceToNow,
  isThisYear,
} from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import ProductImageNotAvailable from '../../assets/images/product-image-not-available.png';
import { notificationMarkAllAsRead, notificationMarkAsRead } from '../../redux/actions/notifications';
import './NotificationPopover.scss';
import _ from 'lodash';

const NotificationPopover = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((s) => s.notifications.notifications);
  const apiDomain = useSelector((state) => state.env.api_root);
  const userHasUnreadNotifications = useSelector(
    (s) => _.find(s.notifications.notifications, (notification) => !notification.is_seen),
  );

  const markNotificationAsRead = (notification) => {
    if (!notification.is_seen)
      dispatch(notificationMarkAsRead(notification.id));
  };

  const markAllAsRead = () => {
    dispatch(notificationMarkAllAsRead());
  };

  const notificationList = _.map(notifications, (notification, index) => {
    const productImageDetails = _.get(notification, 'product_image');
    const productImage = productImageDetails
      ? (productImageDetails.thumbnail !== null
          ? `${apiDomain}${productImageDetails.thumbnail}`
          : (productImageDetails.image_url !== ""
              ? productImageDetails.image_url
              : null))
      : null;
    const notificationDate = new Date(notification.created_at);
    const now = new Date();
    let dateVal;
    if (differenceInCalendarDays(now, notificationDate) < 7) {
      dateVal = formatDistanceToNow(notificationDate, { addSuffix: true });
    } else {
      dateVal = format(
        notificationDate,
        isThisYear(notificationDate) ? 'MMM d' : 'MMM d, yyyy',
      );
    }
    const isClickable = notification.action === "go_to_product";
    const notificationClass = isClickable ? "notification-item" : "notification-item non-clickable";
    const cursorStyle = isClickable ? 'pointer' : 'default';
    const NotificationContent = (
        <div className='d-flex flex-row gap-5 p-1'>
          <Col xs="3">
            <img
              alt={_.get(notification, 'product.name')}
              className="product-image p-1"
              src={productImage ? productImage : ProductImageNotAvailable}
              height={140}
              width={140}
            />
          </Col>
          <Col className="justify-content-between">
            <p className="notification-message p-1">{notification.message}</p>
            <p className="notification-date">{dateVal}</p>
          </Col>
          {!notification.is_seen && (
            <Col xs={1} className="d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon
                className="unread-indicator"
                icon={faCircle} color='#00c9b7'
                style={{ backgroundColor: '#00c9b7', borderRadius: '25px'}}
              />
            </Col>
          )}
        </div>
      );
  
    return isClickable ? (
      <Link
        key={notification.id}
        to={`/product/${_.get(notification, 'product.id')}`}
        onClick={() => markNotificationAsRead(notification)}
        className={notificationClass}
        style={{ cursor: cursorStyle }}
      >
        {NotificationContent}
        <hr className={parseInt(index+1) === notifications?.length ? 'd-none' : 'my-0'} />
      </Link>
    ) : (
      <div
        key={notification.id}
        className={notificationClass}
        style={{ cursor: cursorStyle }}
      >
        {NotificationContent}
        <hr className={parseInt(index+1) === notifications?.length ? 'd-none' : 'my-0'} />
      </div>
    );
  });
  
  return(
    <Popover.Root>
      <Popover.Trigger asChild>
        <button aria-label="Update dimensions" className='popover-trigger-btn' >
          <BellIcon className='notification-icon' />
          {
            userHasUnreadNotifications && (
              <FontAwesomeIcon
                icon={faCircle}
                color='#ef6360'
                style={
                  {
                    backgroundColor: '#ef6360',
                    borderRadius: '25px',
                    position: 'absolute',
                    bottom: 10,
                    left: 13,
                  }
                }
                size='xs'
                className='notification-unseen-icon'
              />
            )
          }
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="PopoverContent" sideOffset={5}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div className='header-container' style={{ backgroundColor: '#fff'  }}>
              <div>
                <span className='notification-header-text'>
                  Notifications
                </span>
              </div>
              <div>
                <Button
                  style={{ borderRadius: '5px' }}
                  onClick={markAllAsRead}
                  outline={true}
                >
                  Mark all as read
                </Button>
              </div>
            </div>
            <div className='notification-content'>
              {notificationList}
            </div>
          </div>
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default NotificationPopover;
