export const GET_ALLERGEN_NARRATIVES = 'LEARNING/GET_ALLERGEN_NARRATIVES';
export const GET_ALLERGEN_NARRATIVES_SUCCESS = 'LEARNING/GET_ALLERGEN_NARRATIVES_SUCCESS';
export const GET_ALLERGEN_NARRATIVES_ERROR = 'LEARNING/GET_ALLERGEN_NARRATIVES_ERROR';
export const GET_NEXT_ALLERGEN_NARRATIVES = 'LEARNING/GET_NEXT_ALLERGEN_NARRATIVES';
export const CLEAR_ALLERGEN_NARRATIVES = 'LEARNING/CLEAR_ALLERGEN_NARRATIVES';
export const ALLERGEN_NARRATIVE_SEARCH_SUCCESS = 'INGREDIENT/ALLERGEN_NARRATIVE_SEARCH_SUCCESS';
export const ALLERGEN_NARRATIVE_SEARCH_STATUS = 'INGREDIENT/ALLERGEN_NARRATIVE_SEARCH_STATUS';
export const ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS = 'INGREDIENT/ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS';

export const getAllergenNarratives = (params) => {
  return {
    type: GET_ALLERGEN_NARRATIVES,
    params,
  };
};

export const getNextAllergenNarratives = () => {
  return {
    type: GET_NEXT_ALLERGEN_NARRATIVES,
  };
};

export const clearAllergenNarratives = () => {
  return {
    type: CLEAR_ALLERGEN_NARRATIVES,
  };
};
