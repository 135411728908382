import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Button, Card, Text, Progress } from "@mantine/core";
import { MantineProvider } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { mantineTheme } from "../../utils/mantine-theme";
import Select from "react-select";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { getIngredientLogs, getIngredientLogsNext } from "../../redux/actions/productReview";

const customSelectStyles = {
  control: (base) => ({
    ...base,
    minWidth: 150,
    height: 36,
    minHeight: 36,
    fontSize: "14px",
    borderColor: "#e9ecef",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#00c9b7",
    },
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isSelected ? "#00c9b7" : isFocused ? "#e5f4f9" : "white",
    color: isSelected ? "white" : "#414b4f",
    fontSize: "14px",
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "#e5f4f9",
      color: "#414b4f",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "6px",
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "6px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

const IngredientLog = () => {
  const [ingredientLogVisible, setIngredientLogVisible] = useState(false);
  const [filter, setFilter] = useState("active");
  const [sorting, setSorting] = useState([
    { id: "updated_at", desc: true },
    { id: "ingredient", desc: true },
  ]);

  const dispatch = useDispatch();
  const match = useRouteMatch("/product-details/:id");
  const productId = _.get(match, "params.id");

  const { logs, next, fetchingIngredientLogs, error } = useSelector(
    (state) => state.productReviews
  );

  const sortMapping = {
    updated_at: "updated_at",
    ingredient: "ingredient__name",
  };
  const defaultSort = "updated_at";

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const filteredData = logs.filter((item) =>
    filter === "active"
      ? item.is_active
      : filter === "inactive"
      ? !item.is_active
      : true
  );

  useEffect(() => {
    if (!productId || !ingredientLogVisible) return;

    const { id, desc } = sorting[0] || {};
    const sortKey = sortMapping[id] || defaultSort;
    const sortOrder = desc ? `-${sortKey}` : sortKey;

    dispatch(getIngredientLogs(productId, sortOrder, filter === "all" ? null : filter));
  }, [productId, ingredientLogVisible, sorting, filter, dispatch]);

  const columns = [
    {
      header: "Ingredient Name",
      accessorKey: "ingredient.name",
      size: 200,
    },
    {
      header: "Current Status",
      accessorKey: "is_active",
      Cell: ({ cell }) => (cell.getValue() ? "Active" : "Inactive"),
      size: 160,
      alignItems: "left",
    },
    {
      header: "Last Updated",
      accessorKey: "updated_at",
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
      size: 190,
    },
    {
      header: "LI",
      accessorKey: "is_label_insight_update",
      size: 80,
      Cell: ({ cell }) =>
        cell.getValue() ? <FontAwesomeIcon icon={faCheck} color="green" /> : null,
    },
    {
      header: "Event",
      accessorKey: "action",
      size: 120,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enablePagination: false,
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enableColumnResizing: false,
    mantineTableProps: {
      sx: {
        tableLayout: "fixed",
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: "#e5f1f0",
      },
    },
    mantineTableContainerProps: {
      sx: { height: "400px", minHeight: "400px" },
      onScroll: (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        if (
          scrollHeight - scrollTop - clientHeight < 50 &&
          next &&
          !fetchingIngredientLogs
        ) {
          dispatch(getIngredientLogsNext(next));
        }
      },
    },
    renderBottomToolbar: () =>
      fetchingIngredientLogs && (
        <div>
          <Progress value={100} striped animate color="rgba(0, 201, 183, 1)" />
        </div>
      ),
    onSortingChange: setSorting,
    state: { sorting, isLoading: fetchingIngredientLogs },
    enableHiding: false,
    enableFilters: false,
  });

  return (
    <div>
      <Button
        onClick={() => setIngredientLogVisible(!ingredientLogVisible)}
        mt="md"
        style={{
          display: "flex",
          marginLeft: "auto",
          alignItems: "right",
          textAlign: "right",
          backgroundColor: "#e5f4f9",
          color: "#414b4f",
          marginBottom: "10px",
        }}
      >
        Ingredient Log
        <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "8px" }} />
      </Button>

      {ingredientLogVisible && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
            }}
            className="mb-2"
          >
            <Text size="sm" className="mx-2">
              Filter By:
            </Text>
            <Select
              classNamePrefix="react-select"
              value={{
                value: filter,
                label:
                  filter === "active"
                    ? "Active"
                    : filter === "inactive"
                    ? "Inactive"
                    : "All",
              }}
              onChange={(selectedOption) => {
                handleFilterChange(selectedOption?.value || "");
              }}
              options={[
                { value: "all", label: "All" },
                { value: "active", label: "Active" },
                { value: "inactive", label: "Inactive" },
              ]}
              styles={customSelectStyles}
            />
          </div>
          {error ? (
            <Card p="md" withBorder sx={{ backgroundColor: "#ffebee" }}>
              <Text color="red" align="center">
                Error loading ingredient logs: {error}
              </Text>
            </Card>
          ) : logs.length === 0 && !fetchingIngredientLogs ? (
            <Card p="md" withBorder>
              <Text color="dimmed" align="center">
                No ingredient logs available
              </Text>
            </Card>
          ) : (
            <MantineReactTable table={table} />
          )}
        </div>
      )}
    </div>
  );
};

export default IngredientLog;
