import React from 'react';

import { CButton } from '@coreui/react';
import { useHistory } from 'react-router-dom';
import './AboutPage.scss';

export default function AboutPage () {

  const history = useHistory();

  return (
    <div className="about-page">
      <div>
        <div className="card">
          <h2 className='about-header'>Who We Are</h2>
          <p className='about-summary'>
            The American Contact Dermatitis Society (ACDS) was founded in 1989 and has grown from a small dedicated
            group of dermatologists focused on contact dermatitis to over 2,000 health care professionals specializing
             in the field of allergic contact dermatitis and related inflammatory skin diseases.
          </p>
        </div>
        <div className="card">
          <h2 className='about-header'>ACDS Vision Statement</h2>
          <p className='about-summary'>
            The American Contact Dermatitis Society is committed to advancing the care and understanding of dermatitis
             and allergy.
          </p>
        </div>
        <div className="card">
          <h2 className='about-header'>ACDS Mission Statement</h2>
          <p className='about-summary'>
            The mission of the American Contact Dermatitis Society is to be the premier organization cultivating
            advancements in the diagnosis and care of dermatitis through the promotion of education, research, and
             advocacy. Our society is dedicated to understanding and investigating the interplay among contact,
             occupational, atopic, photo, drug and other forms of dermatitis.
          </p>
        </div>
        <div className="card">
          <h2 className='about-header'>Need help?</h2>
          <CButton className='mt-2' style={{ borderRadius: '5px' }} onClick={()=>{
            history.push('/contact-us');
          }}>
            Contact Us
          </CButton>
        </div>
      </div>
    </div>
  );
}
