import {
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Input,
  TextInput,
  Loader,
  MantineProvider,
  Modal,
  Popover,
  ScrollArea,
  Select,
  Table,
  Text,
  Tooltip,
  CloseButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit, IconSquarePlus } from "@tabler/icons-react";
import dayjs from "dayjs";
import _ from "lodash";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useMemo, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/common/Header";
import {
  bulkUpdateIngredient,
  bulkDeleteIngredient,
  getIngredients,
  getIngredientsDateRange,
  getSelectedIngredientsSuggestion,
  clearSelectedIngredientsSuggestions,
  replaceSelectedIngredient,
} from "../../redux/actions/ingredientReview";
import CrossReactorList from "./CrossReactorList";
import IngredientReviewAction from "./IngredienReviewAction";
import "./IngredientsReview.scss";
import { mantineTheme } from "../../utils/mantine-theme";

export default function IngredientsReview() {
  const PAGE_LIMIT = 50;
  const [searchValue, setSearchValue] = useState("");

  const tableContainerRef = useRef(null);
  const dispatch = useDispatch();

  const [dateModified, setDateModified] = useState(false);
  const popoverRef = useRef(null);

  const [bulkApproveIngredientModal, bulkApproveIngredientModalHandler] = useDisclosure(false);
  const [bulkDeleteIngredientModal, bulkDeleteIngredientModalHandler] = useDisclosure(false);

  const [range, setRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
    key: "selection",
  });

  const [ingredientStatus, setIngredientStatus] = useState(3);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_LIMIT,
  });
  const pageNumInputRef = useRef();
  const gotoPageInputRef = useRef();
  const [rowSelection, setRowSelection] = useState({});
  const [clickedIngredientId, setClickedIngredientId] = useState();

  const ingredients = useSelector((state) => state.ingredientReviews.ingredients);
  const ingredientDateRange = useSelector((state) => state.ingredientReviews.ingredientDateRange);
  const ingredientCount = useSelector((state) => state.ingredientReviews.ingredientCount);
  const fetchingIngredients = useSelector((state) => state.ingredientReviews.fetchingIngredients);
  const updatingIngredient = useSelector((state) => state.ingredientReviews.updatingIngredient);
  const pendingIngredientSuggestionsId = useSelector((state) => state.ingredientReviews.pendingIngredientSuggestionsId);

  const filterString = `Filtered for : ${dayjs(range?.startDate ?? undefined).format("MMM D, YY")} to ${dayjs(range?.endDate ?? undefined).format("MMM D, YY")}`;

  useEffect(() => {
    setRange({
      key: "selection",
      startDate: dayjs(ingredientDateRange?.from ?? undefined).toDate(),
      endDate: dayjs(ingredientDateRange?.to ?? undefined).toDate(),
    });
  }, [ingredientDateRange]);

  const columns = [
    {
      header: "Id",
      accessorKey: "id",
      size: 30,
      mantineTableHeadCellProps: {
        align: "center",
      },
      mantineTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        return (
          <Text
            fz={"md"}
            sx={{ cursor: "pointer", color: "#00c9b7", fontWeight: "bold" }}
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            {row.getValue("id")}
          </Text>
        );
      },
    },
    {
      header: "Ingredient Name",
      accessorKey: "name",
      size: 300,
      Cell: ({ row }) => {
        const [ingredientEditModal, { open: ingredientEditModalOpen, close: ingredientEditModalClose }] =
          useDisclosure(false);
        const selectedIngredientSuggestions = useSelector(
          (state) => state.ingredientReviews.selectedIngredientSuggestions,
        );
        const loadingSelectedIngredientSuggestions = useSelector(
          (state) => state.ingredientReviews.loadingSelectedIngredientSuggestions,
        );

        const initialState = {
          id: "1",
          value: row.getValue("name"),
          initialValue: row.getValue("name"),
          ingredientId: row.getValue("id"),
        };

        const [ingredientsNameToEdit, setIngredientsNameToEdit] = useState([{ ...initialState }]);

        const handleChange = (event) => {
          const updatedValues = ingredientsNameToEdit.map((value) => {
            if (value.id === event.target.id) {
              return { ...value, value: event.target.value };
            }
            return value;
          });
          setIngredientsNameToEdit([...updatedValues]);
        };
        const modifiedIngredientsValue = _.compact(_.map(ingredientsNameToEdit, "value"));
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
              gap: 10,
            }}
          >
            <Text>{row.getValue("name")}</Text>
            {ingredientStatus === 3 && (
              <div>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => {
                    ingredientEditModalOpen();
                  }}
                  size={"lg"}
                  color={"#00c9b7"}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <Modal
              opened={ingredientEditModal}
              onClose={() => {
                ingredientEditModalClose();
                setIngredientsNameToEdit([{ ...initialState }]);
              }}
              overlayProps={{
                color: "#E9ECEF",
                opacity: 0.55,
                blur: 3,
              }}
              centered
              closeOnEscape={true}
              closeOnClickOutside={false}
              closeButtonProps={{
                display: "none",
              }}
              styles={{
                header: {
                  padding: 0,
                },
                root: {},
                content: {
                  borderRadius: 25,
                  backgroundColor: "#fff",
                  padding: 24,
                },
              }}
              size={"lg"}
            >
              <div className="d-flex flex-row-reverse align-items-center my-4">
                <Button
                  variant="outline"
                  styles={{
                    root: {
                      ":hover": {
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                  radius={"md"}
                  leftIcon={<IconSquarePlus size={"1rem"} />}
                  onClick={() => {
                    const newIngredientId = uuidv4();
                    setIngredientsNameToEdit((prev) => [
                      ...prev,
                      { id: newIngredientId, value: "", initialValue: "", newValue: true },
                    ]);
                  }}
                >
                  Add Ingredient
                </Button>
              </div>
              <Text
                className="m-2"
                color="#000"
                style={{ fontFamily: "Open Sans", fontSize: 16, lineHeight: "1.5" }}
              >
                You can edit the text below and/or select from the suggested ingredients below
              </Text>
              <div>
                {ingredientsNameToEdit.map((item, index) => {
                  return (
                    <div key={item.id} className="my-2">
                      <div className="d-flex flex-row">
                        <div style={{ display: "flex", flex: 0.9 }}>
                          <Input
                            id={item.id}
                            value={item.value}
                            style={{ width: "100%" }}
                            onChange={handleChange}
                            placeholder={"Copy & Paste / Type new ingredient"}
                          />
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ flex: 0.1, display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            size="2xl"
                            style={{ cursor: ingredientsNameToEdit.length > 1 ? "pointer" : "not-allowed" }}
                            onClick={() => {
                              if (ingredientsNameToEdit.length === 1) return;
                              setIngredientsNameToEdit((prev) => [..._.reject(prev, ["id", item.id])]);
                            }}
                            color={ingredientsNameToEdit.length > 1 ? "red" : "grey"}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr color="#a9a9ab" />

              <Button
                onClick={() => {
                  dispatch(getSelectedIngredientsSuggestion(row.getValue("id")));
                  setClickedIngredientId(row.getValue("id"))
                }}
              >
                Show Suggestions
              </Button>

              {(() => {
                if (clickedIngredientId !== pendingIngredientSuggestionsId)
                  return <></>;
                return loadingSelectedIngredientSuggestions ? (
                  <div
                    style={{
                      width: "100%",
                      height: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {!selectedIngredientSuggestions ? <></> : selectedIngredientSuggestions?.length ? (
                      <div
                        style={{
                          marginTop: 10,
                          display:
                            _.compact(_.map(ingredientsNameToEdit, "suggestionId")).length ===
                            selectedIngredientSuggestions?.length
                              ? "none"
                              : "",
                        }}
                      >
                        <div>
                          <div
                            className="ingredient-review-ingredient-suggestion-container"
                            style={{
                              borderRadius: 5,
                              backgroundColor: "#eef8f8",
                              width: "100%",
                              border: "solid 1px #a9a9ab",
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            {selectedIngredientSuggestions?.map((item, index) => {
                              const selectedSuggestionId = _.map(ingredientsNameToEdit, "suggestionId");

                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: selectedSuggestionId.includes(item.id) ? "none" : "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: 10,
                                    borderBottom:
                                      index + 1 !== selectedIngredientSuggestions?.length ? "solid 1px #a9a9ab" : "",
                                  }}
                                  className=""
                                >
                                  <div>
                                    <Text c={"#000"} fs={14} lh={1.55}>
                                      {item?.name}
                                    </Text>
                                  </div>
                                  <div className="mx-2">
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      size="xl"
                                      color="#00c9b7"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setIngredientsNameToEdit((prev) => [
                                          ...prev,
                                          {
                                            suggestionId: item.id,
                                            id: uuidv4(),
                                            initialValue: item.name,
                                            value: item.name,
                                          },
                                        ]);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "row", padding: 10 }}>
                        <div className="mx-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <FontAwesomeIcon icon={faTriangleExclamation} color="red" size="xl" />
                        </div>
                        <div>
                          <Text c={"#eb5757"} align="justify">
                            No potential ingredient matches this text in CAMP.
                          </Text>
                        </div>
                      </div>
                    )}
                    {_.compact(_.map(ingredientsNameToEdit, "suggestionId")).length ===
                      selectedIngredientSuggestions?.length &&
                      selectedIngredientSuggestions?.length > 0 && (
                        <Text>No more ingredient is available for suggestion.</Text>
                      )}
                  </div>
              )})()}
              <div>
                {modifiedIngredientsValue.length === 0 && (
                  <Text c={"#eb5757"}>
                    In order to modify an ingredient, at least one of the input columns must be filled out. If you wish
                    to delete it, please use the action column in the ingredient review table to do so.
                  </Text>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
                className="mt-3"
              >
                <Button
                  variant="white"
                  c="#414b4f"
                  onClick={() => {
                    ingredientEditModalClose();
                    setIngredientsNameToEdit([{ ...initialState }]);
                    dispatch(clearSelectedIngredientsSuggestions());
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    dispatch(clearSelectedIngredientsSuggestions());
                    if (ingredientsNameToEdit.length === 1) {
                      if (_.has(ingredientsNameToEdit[0], "ingredientId")) {
                        if (ingredientsNameToEdit[0].value === ingredientsNameToEdit[0].initialValue) {
                          ingredientEditModalClose();
                          setIngredientsNameToEdit([{ ...initialState }]);
                          return;
                        }
                      }
                    }
                    const ingredientValue = _.map(ingredientsNameToEdit, (item) => {
                      if (_.isEmpty(item.value)) {
                        return "";
                      }

                      if (_.has(item, "initialValue")) {
                        if (item.value === item.initialValue) {
                          return {
                            id: _.get(item, "suggestionId", _.get(item, "ingredientId")),
                          };
                        }

                        if (_.isEmpty(item.value)) {
                          return "";
                        }

                        return {
                          name: item.value,
                        };
                      }

                      if (_.isEmpty(item.value)) {
                        return "";
                      }

                      return {
                        name: item.value,
                      };
                    });

                    const filteredIngredientValue = _.compact(ingredientValue);

                    dispatch(replaceSelectedIngredient(row.getValue("id"), _.compact(filteredIngredientValue)));
                  }}
                  disabled={modifiedIngredientsValue.length === 0}
                >
                  Save
                </Button>
              </div>
            </Modal>
          </div>
        );
      },
    },
    {
      header: "Cross-Reactor(s)",
      accessorKey: "cross_reactors",
      Cell: ({ row }) => {
        return (
          <CrossReactorList
            crossReactors={row.getValue("cross_reactors")}
            ingredientId={row.original.id}
            fragrance={row.original.fragrance}
            ingredientStatus={ingredientStatus}
          />
        );
      },
    },
    {
      header: "Last Updated",
      accessorKey: "updated_at",
      Cell: ({ cell }) => {
        const date = cell.getValue();
        return date ? dayjs(date).format("MMM DD, YYYY h:m A") : ""
      }
    },
    {
      header: "Action",
      Cell: ({ row }) => {
        return (
          <IngredientReviewAction
            ingredientId={row.getValue("id")}
            crossReactor={row.original.cross_reactors}
            ingredientName={row.original.name}
            ingredientStatus={ingredientStatus}
          />
        );
      },
      mantineTableHeadCellProps: {
        align: "center",
      },
      size: 50,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: ingredients,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: "80vh", minHeight: "530px" },
    },
    manualSorting: true,
    enableStickyHeader: true,
    selectAllMode: false,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableRowSelection: ingredientStatus === 3,
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: "#e5f1f0",
        zIndex: 30,
      },
    },
    state: {
      isLoading: fetchingIngredients || updatingIngredient,
      pagination,
      rowSelection,
    },
    enableHiding: true,
    enableFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    paginationDisplayMode: "pages",
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: ingredientCount,
    onRowSelectionChange: setRowSelection,
    getRowId: (originalRow) => originalRow.id,
    enableRowNumbers: false,
    renderDetailPanel: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
          }}
        >
          <ScrollArea.Autosize mah={250} w={"80%"}>
            <Table
              striped
              highlightOnHover
              withBorder
              verticalSpacing="xs"
              fontSize="md"
              style={{ width: "100%", position: "relative" }}
            >
              <thead
                style={{
                  backgroundColor: "#e5f1f0",
                  top: 0,
                  zIndex: 5,
                }}
              >
                <tr>
                  <th>SI.No</th>
                  <th>Id</th>
                  <th
                    style={{
                      width: "70%",
                    }}
                  >
                    Product Name
                  </th>
                  <th>UPC</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {row?.original?.products?.map((item, index) => {
                  return (
                    <tr key={item.id} className="ingredient-sub-panel" style={{ backgroundColor: "#fff" }}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>
                        <Text lineClamp={1}>{item.name}</Text>
                      </td>
                      <td>{item?.barcode}</td>
                      <td align="center">
                        <Tooltip transitionProps={{ transition: "slide-down", duration: 300 }} label="edit">
                          <ActionIcon
                            size="xl"
                            radius="xl"
                            variant="subtle"
                            component={Link}
                            target="_blank"
                            to={`/product-details/${item.id}`}
                            color="teal"
                            sx={{
                              ":hover": {
                                backgroundColor: "#fff",
                              },
                            }}
                          >
                            <IconEdit size={"2.125rem"} />
                          </ActionIcon>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
                {row?.original?.products?.length === 0 && (
                  <tr className="ingredient-sub-panel text-center p-3">
                    <td colSpan={"5"}>No Products recorded for the selected ingredients</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ScrollArea.Autosize>
        </Box>
      );
    },
  });

  useEffect(() => {
    dispatch(getIngredientsDateRange(ingredientStatus));
    setDateModified(false);
  }, [ingredientStatus]);

  useEffect(() => {
    gotoPageInputRef.current.value = ""
    setPagination({
      pageIndex: 0,
      pageSize: PAGE_LIMIT
    })
    const payload = {
      status: ingredientStatus,
      offset: 0,
      limit: PAGE_LIMIT,
      is_label_insight_import: true,
    };
    if (dateModified) {
      payload.updated_at__lte = dayjs(range.endDate).endOf("day").toISOString();
      payload.updated_at__gte = dayjs(range.startDate).startOf("day").toISOString();
    }
    dispatch(getIngredients({ ...payload }));
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
    table.resetRowSelection();
  }, [ingredientStatus]);

  useEffect(() => {
    gotoPageInputRef.current.value = ""
    const payload = {
      status: ingredientStatus,
      offset: PAGE_LIMIT * pagination.pageIndex,
      limit: PAGE_LIMIT,
      is_label_insight_import: true,
      name__icontains: searchValue,
    };
    if (dateModified) {
      payload.updated_at__lte = dayjs(range.endDate).endOf("day").toISOString();
      payload.updated_at__gte = dayjs(range.startDate).startOf("day").toISOString();
    }
    dispatch(getIngredients({ ...payload }));
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
    table.resetRowSelection();
  }, [pagination.pageIndex, searchValue]);

  useEffect(() => {
    const ingredientIds = _.map(ingredients, "id");
    const newSelection = _.pick(rowSelection, ingredientIds);
    setRowSelection(newSelection);
  }, [ingredients]);

  const handleDateFilterSelection = () => {
    setPagination({
      pageIndex: 0,
      pageSize: PAGE_LIMIT
    })
    const payload = {
      status: ingredientStatus,
      offset: 0,
      limit: PAGE_LIMIT,
      is_label_insight_import: true,
      updated_at__lte: dayjs(range.endDate).endOf("day").toISOString(),
      updated_at__gte: dayjs(range.startDate).startOf("day").toISOString(),
    };
    dispatch(getIngredients({ ...payload }));
  };

  const inputRef = useRef();
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setPagination({
      pageIndex: 0,
      pageSize: PAGE_LIMIT
    })
    dispatch(getIngredients({
      status: ingredientStatus,
      limit: PAGE_LIMIT,
      offset: 0,
      is_label_insight_import: true,
      name__icontains: e.target.value,
      updated_at__lte: dayjs(range.endDate).endOf("day").toISOString(),
      updated_at__gte: dayjs(range.startDate).startOf("day").toISOString()
    }));
  }

  const debouncedSearch = _.debounce(handleSearchChange, 500);

  const handleSearchClear = () => {
    setSearchValue("");
    setPagination({
      pageIndex: 0,
      pageSize: PAGE_LIMIT
    })
    inputRef.current.value = "";

    dispatch(getIngredients({
      status: ingredientStatus,
      limit: PAGE_LIMIT,
      offset: 0,
      is_label_insight_import: true,
      updated_at__lte: dayjs(range.endDate).endOf("day").toISOString(),
      updated_at__gte: dayjs(range.startDate).startOf("day").toISOString()
    }));
  }

  return (
    <div className="ingredient-reviews">
      <Header title="Ingredients Reviews" />
      <div className="review-products">
        <MantineProvider theme={mantineTheme} className="p-5 pt-1">
          <div className="my-2 d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row gap-2 ">
              <Input
                ref={inputRef}
                placeholder="Search" 
                icon={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
                onChange={debouncedSearch}
                style={{ width: 230 }}
                rightSection={
                  <CloseButton
                    onClick={handleSearchClear}
                    style={{ color: "black", opacity: 0.5, display: searchValue ? undefined : "none" }}
                  />
                }
              />
              <div>
                <form
                  className="d-flex gap-2"
                  onSubmit={(e) => {
                    e.preventDefault()
                    const integerPageNumInput = parseInt(pageNumInputRef.current)
                    if (integerPageNumInput && integerPageNumInput > 0) {
                      setPagination({
                        pageIndex: integerPageNumInput - 1,
                        pageSize: PAGE_LIMIT
                      })
                      return
                    }

                    setPagination({
                      pageIndex: 0,
                      pageSize: PAGE_LIMIT
                    })
                  }}
                >
                  <TextInput
                    ref={gotoPageInputRef}
                    type="number"
                    onChange={(e) => pageNumInputRef.current = e.target.value}
                    label="Go to page:"
                    style={{ 
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 16
                    }}
                    styles={{
                      "wrapper": { width: 65, },
                      "label": { marginRight: 8 }
                    }}
                  />
                  <Button
                    type="submit"
                    px={8}
                  >
                    Go
                  </Button>
                </form>
              </div>
              <div className="d-flex flex-row gap-3">
                {Object.keys(rowSelection).length > 0 &&
                  Object.keys(rowSelection).length < PAGE_LIMIT &&
                  Object.keys(rowSelection).length !== ingredients.length && (
                    <div className="d-flex flex-row gap-3">
                      {ingredientStatus !== 0 && (
                        <Button
                          leftIcon={
                            <div className={"selected-ingredients-count"}>{Object.keys(rowSelection).length}</div>
                          }
                          onClick={() => {
                            bulkApproveIngredientModalHandler.open();
                          }}
                        >
                          Publish Selected
                        </Button>
                      )}
                    </div>
                  )}

                {Object.keys(rowSelection).length > 0 &&
                  Object.keys(rowSelection).length < PAGE_LIMIT &&
                  Object.keys(rowSelection).length !== ingredients.length && (
                    <div className="d-flex flex-row gap-3">
                      {ingredientStatus !== 0 && (
                        <Button
                          style={{ backgroundColor: "#f50000" }}
                          leftIcon={
                            <div className={"selected-ingredients-count"} style={{ backgroundColor: "#f50000" }}>
                              {Object.keys(rowSelection).length}
                            </div>
                          }
                          onClick={() => {
                            bulkDeleteIngredientModalHandler.open();
                          }}
                        >
                          Delete Selected
                        </Button>
                      )}
                    </div>
                  )}

                {(Object.keys(rowSelection).length === PAGE_LIMIT ||
                  Object.keys(rowSelection).length === ingredients.length) &&
                  Object.keys(rowSelection).length > 0 && (
                    <div className="d-flex flex-row gap-3">
                      {ingredientStatus !== 0 && (
                        <Button
                          onClick={() => {
                            bulkApproveIngredientModalHandler.open();
                          }}
                        >
                          Publish All
                        </Button>
                      )}
                    </div>
                  )}
              </div>
            </div>
            <div className="d-flex flex-row gap-3">
              <div>
                <Popover position="bottom-end" trapFocus withArrow shadow="md" closeOnClickOutside={false}>
                  <Popover.Target ref={popoverRef}>
                    <Badge
                      size="xl"
                      variant="outline"
                      styles={{
                        inner: {
                          fontWeight: "normal",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "none",
                        },
                        root: {
                          backgroundColor: "#fff",
                        },
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {filterString}
                    </Badge>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <div>
                      <DateRangePicker
                        onChange={(item) => {
                          setRange(item.selection);
                        }}
                        editableDateInputs={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[range]}
                        direction="horizontal"
                        className="z-index"
                      />
                      <div className="p-3 d-flex align-items-center justify-content-center gap-4">
                        <Button
                          variant="white"
                          onClick={() => {
                            popoverRef?.current?.click();
                          }}
                          c={"#414b4f"}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setDateModified(true);
                            handleDateFilterSelection();
                            popoverRef?.current?.click();
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Popover.Dropdown>
                </Popover>
              </div>
              <Text size="md" className="my-1">
                Filter By:
              </Text>
              <Select
                style={{ width: 160 }}
                data={[
                  { value: 3, label: "Pending Review" },
                  { value: 0, label: "Published" },
                  { value: 2, label: "Declined" },
                ]}
                value={ingredientStatus}
                onChange={(e) => {
                  setIngredientStatus(e);
                  handleSearchClear();
                }}
              />
            </div>
          </div>
          <MantineReactTable table={table} />
          <Modal
            centered
            opened={bulkApproveIngredientModal}
            onClose={() => [bulkApproveIngredientModalHandler.close()]}
            title="Publish Selected Ingredient(s)"
            overlayProps={{
              opacity: 0.55,
              blur: 3,
            }}
            styles={{
              header: {
                color: "#00c9b7",
                fontSize: "20px",
                ".mantine-CloseButton-root": {
                  color: "gray",
                },
              },
            }}
          >
            <span>
              Are you sure that you want to publish the selected <br />
              <span style={{ color: "#00c9b7", fontWeight: "bold", fontSize: 16 }}>
                {Object.keys(rowSelection).length}
              </span>{" "}
              ingredient(s) ?
            </span>
            <div className="d-flex mt-3 mb-1 flex-row-reverse gap-3 ">
              <Button
                onClick={() => {
                  dispatch(bulkUpdateIngredient(0, rowSelection));
                  bulkApproveIngredientModalHandler.close();
                }}
              >
                Publish
              </Button>
              <Button
                variant="white"
                onClick={() => {
                  bulkApproveIngredientModalHandler.close();
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            size={"lg"}
            centered
            opened={bulkDeleteIngredientModal}
            onClose={() => [bulkDeleteIngredientModalHandler.close()]}
            title={
              <>
                <FontAwesomeIcon icon={faTriangleExclamation} color="red" size="xl" style={{ marginRight: 8 }} />
                Delete Selected Ingredient(s)
              </>
            }
            overlayProps={{
              opacity: 0.55,
              blur: 3,
            }}
            styles={{
              header: {
                fontSize: "20px",
                ".mantine-CloseButton-root": {
                  color: "gray",
                },
              },
            }}
          >
            <span>
              The selected ingredient(s) will no longer be available at CAMP and will
              also be removed from the product. Please confirm before deleting it.
            </span>
            <div className="d-flex mt-3 mb-1 flex-row-reverse gap-3 ">
              <Button
                style={{ backgroundColor: "#f50000" }}
                onClick={() => {
                  dispatch(bulkDeleteIngredient(2, rowSelection));
                  bulkDeleteIngredientModalHandler.close();
                }}
              >
                Delete
              </Button>
              <Button
                variant="white"
                onClick={() => {
                  bulkDeleteIngredientModalHandler.close();
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </MantineProvider>
      </div>
    </div>
  );
}
