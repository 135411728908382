/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FileButton, Text } from "@mantine/core";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import ImageComponent from "./ImageComponent";

function ProductReviewImage({ formValues, formFieldName, formSetValue, imageIdentifier, position }) {
  const DAYJS_FORMAT = "MMM DD, YYYY, h:mm a";
  const [newImage, setNewImage] = useState();
  const resetRef = useRef(null);

  const currentImage = formValues[formFieldName];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 20,
      }}
    >
      <ImageComponent
        currentImage={currentImage}
        newImage={newImage}
        formFieldName={formFieldName}
        formSetValue={formSetValue}
      />
      <div
        style={{ width: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        {newImage ? (
          <Text w={200} truncate>
            {newImage.name}
          </Text>
        ) : (
          <Text>No Image is selected</Text>
        )}
        <div style={{ textAlign: "center" }}>
          <Text>({imageIdentifier})</Text>
        </div>
      </div>
      <FileButton
        resetRef={resetRef}
        onChange={(file) => {
          const maxSize = 500 * 1024;

          if (file.size > maxSize) {
            alert("Please choose an image that is no larger than 500kb.");
            resetRef.current?.();

            return;
          }

          setNewImage(file);
          formSetValue({
            [formFieldName]: {
              position,
              newImage: file,
            },
          });
        }}
        accept="image/png,image/jpeg"
      >
        {(props) => (
          <Button
            {...props}
            sx={{
              backgroundColor: "#00c9b7",
              borderRadius: 25,
              color: "#333333",
              ":hover": {
                backgroundColor: "#00c9b7",
                color: "#fff",
              },
            }}
            size="lg"
          >
            Add Image
          </Button>
        )}
      </FileButton>
      <div
        style={{
          borderRadius: 30,
          backgroundColor: "#E0E0E0",
          rotate: newImage?.rotate,

          width: 40,
          height: 40,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        role="button"
        onClick={() => {
          setNewImage(null);
          resetRef.current?.();
          formSetValue({
            [formFieldName]: {
              position,
              newImage: {},
              image: {},
            },
          });
        }}
      >
        <FontAwesomeIcon className="mx-3" icon={faTrashCan} color="#eb5757" />
      </div>
      {currentImage?.image?.updated_at && (
        <div style={{ fontSize: 12 }}>
          Updated at <br /> {dayjs(currentImage?.image?.updated_at).format(DAYJS_FORMAT)}
        </div>
      )}
    </div>
  );
}

export default ProductReviewImage;
