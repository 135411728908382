import _ from "lodash";
import * as Actions from "../actions/ingredientReview";

export const initialState = {
  fetchingIngredients: false,
  fetchingIngredientsNext: false,
  ingredients: [],
  ingredientsNext: "",
  fetchingIngredientsError: {},
  ingredientDateRange: {},
  ingredientCount: 0,
  crossReactorsToExclude: {},
  fragranceToExclude: [],
  crossReactorsToInclude: {},
  fragranceToInclude: [],
  updatingIngredient: false,
  currentFilters: {},
  selectedIngredientSuggestions: null,
  loadingSelectedIngredientSuggestions: false,
  pendingIngredientSuggestionsId: null,
  fetchingCrossReactorsList: false,
  crossReactorsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_INGREDIENTS:
      return {
        ...state,
        fetchingIngredients: true,
        ingredients: [],
        crossReactorsToExclude: {},
        fragranceToExclude: [],
        currentFilters: action.filters,
      };
    case Actions.GET_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: _.get(action, "response.results", []),
        ingredientCount: _.get(action, "response.count", 0),
        fetchingIngredients: false,
        updatingIngredient: false,
      };
    case Actions.GET_INGREDIENTS_ERROR:
      return {
        ...state,
        fetchingIngredients: false,
        updatingIngredient: false,
      };
    case Actions.GET_INGREDIENTS_NEXT_SUCCESS:
      return {
        ...state,
        productsComments: [],
        fetchingProductsComments: true,
        fetchingProductsCommentsNext: false,
        productsCommentsNext: "",
      };
    case Actions.GET_INGREDIENTS_DATE_RANGE:
      return {
        ...state,
        ingredientCount: 0, // Will reset page count for pagination hence can go to 1st page
      };
    case Actions.GET_INGREDIENTS_DATE_RANGE_SUCCESS:
      return {
        ...state,
        ingredientDateRange: action.dateResponse,
      };
    case Actions.CROSS_REACTOR_TO_EXCLUDE: {
      const crossReactorId = action.crossReactorId;
      const ingredientId = action.ingredientId;

      const isIngredientAlreadySelected = _.has(state.crossReactorsToExclude, ingredientId);

      let overwriteIngredient;

      if (isIngredientAlreadySelected) {
        const ingredientCrossReactor = _.get(state.crossReactorsToExclude, ingredientId, []);
        if (ingredientCrossReactor.includes(crossReactorId)) {
          overwriteIngredient = {
            [ingredientId]: _.remove(ingredientCrossReactor, (id) => {
              return id !== crossReactorId;
            }),
          };
        } else {
          overwriteIngredient = {
            [ingredientId]: [...ingredientCrossReactor, crossReactorId],
          };
        }
      } else {
        overwriteIngredient = {
          [ingredientId]: [crossReactorId],
        };
      }

      return {
        ...state,
        crossReactorsToExclude: {
          ...state.crossReactorsToExclude,
          ...overwriteIngredient,
        },
      };
    }
    case Actions.UPDATE_INGREDIENT:
      return {
        ...state,
        updatingIngredient: true,
      };
    case Actions.UPDATE_INGREDIENT_SUCCESS: {
      return {
        ...state,
        ingredients: _.reject(state.ingredients, ["id", action.ingredientId]),
        crossReactorsToExclude: _.omit(state.crossReactorsToExclude, action.ingredientId),
        fragranceToExclude: [..._.pull(state.fragranceToExclude, action.ingredientId)],
        crossReactorsToInclude: _.omit(state.crossReactorsToInclude, action.ingredientId),
        updatingIngredient: false,
        fetchingIngredients: false,
      };
    }
    case Actions.UPDATE_INGREDIENT_ERROR:
      return {
        ...state,
        updatingIngredient: false,
      };
    case Actions.BULK_UPDATE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: _.differenceWith(
          state.ingredients,
          action.selectedIngredientsIds,
          (ingredient, selectedIngredientId) => ingredient.id === selectedIngredientId,
        ),
        crossReactorsToExclude: _.omitBy(state.crossReactorsToExclude, (value, key) =>
          action.selectedIngredientsIds.includes(key),
        ),
        fetchingIngredients: false,
        updatingIngredient: false,
      };
    case Actions.BULK_UPDATE_INGREDIENT:
      return {
        ...state,
        updatingIngredient: true,
        fetchingIngredients: false,
      };
    case Actions.BULK_UPDATE_INGREDIENT_ERROR:
      return {
        ...state,
        updatingIngredient: false,
        fetchingIngredients: false,
      };
    case Actions.BULK_DELETE_INGREDIENT:
      return {
        ...state,
        updatingIngredient: true,
        fetchingIngredients: false,
      };
    case Actions.BULK_DELETE_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: _.differenceWith(
          state.ingredients,
          action.selectedIngredientsIds,
          (ingredient, selectedIngredientId) => ingredient.id === selectedIngredientId,
        ),
        fetchingIngredients: false,
        updatingIngredient: false,
      };
    case Actions.BULK_DELETE_INGREDIENT_ERROR:
      return {
        ...state,
        updatingIngredient: false,
        fetchingIngredients: false,
      };
    case Actions.FRAGRANCE_TO_EXCLUDE: {
      const ingredientId = action.ingredientId;

      let fragranceToExcludeIds;

      if (state.fragranceToExclude.includes(ingredientId)) {
        fragranceToExcludeIds = _.pull(state.fragranceToExclude, ingredientId);
      } else {
        fragranceToExcludeIds = [...state.fragranceToExclude, ingredientId];
      }

      return {
        ...state,
        fragranceToExclude: [...fragranceToExcludeIds],
      };
    }
    case Actions.CLEAR_SELECTED_INGREDIENT:
      return {
        ...state,
        selectedIngredientSuggestions: null,
        pendingIngredientSuggestionsId: null
      }
    case Actions.GET_SELECTED_INGREDIENT:
      return {
        ...state,
        pendingIngredientSuggestionsId: action.ingredientId,
        loadingSelectedIngredientSuggestions: true,
        selectedIngredientSuggestions: null,
      };
    case Actions.GET_SELECTED_INGREDIENT_SUCCESS:
      return {
        ...state,
        loadingSelectedIngredientSuggestions: false,
        selectedIngredientSuggestions: _.get(action, "data", []),
      };
    case Actions.GET_SELECTED_INGREDIENT_ERROR:
      return {
        ...state,
        loadingSelectedIngredientSuggestions: false,
      };
    case Actions.GET_CROSS_REACTORS_LIST:
      return {
        ...state,
        fetchingCrossReactorsList: true
      }
    case Actions.GET_CROSS_REACTORS_LIST_SUCCESS:
      return {
        ...state,
        fetchingCrossReactorsList: false,
        crossReactorsList: action.data
      }
    case Actions.GET_CROSS_REACTORS_LIST_ERROR:
      return {
        ...state,
        fetchingCrossReactorsList: false
      }
    case Actions.UPDATE_INGREDIENT_CROSS_REACTOR_LIST:
      return {
        ...state,
        crossReactorsToInclude: {
          ...state.crossReactorsToInclude,
          [action.ingredientId]: action.crossReactor
        }
      }
    default:
      return state;
  }
};
