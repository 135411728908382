import { LOGOUT, apiDelete, apiGet, apiPatch, apiPost } from "acds-react-core";
import _ from "lodash";
import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import toastr from "toastr";
import * as commentReviewActions from "../actions/commentReview";
import * as productReviewActions from "../actions/productReview";
import * as profileActions from "../actions/profile";

function* doPasswordChange(action) {
  const { params } = action;
  try {
    let payLoad;
    if (Object.keys(params).length === 3) {
      payLoad = {
        old_password: params.currentPassword,
        new_password1: params.newPassword,
        new_password2: params.confirmNewPassword,
      };
    } else {
      payLoad = {
        new_password1: params.newPassword,
        new_password2: params.confirmNewPassword,
      };
    }

    const response = yield apiPatch("api/user/", payLoad);
    toastr.options.positionClass = "toast-bottom-right";

    if (response.status === 400) {
      const message = Object.values(response.data);

      toast.error(message[0][0], {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        closeButton: true,
      });

      throw new Error(message[0][0]);
    } else {
      toast.success("Password Updated Successfully", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#00c9b7",
        },
        closeButton: true,
      });
    }
    yield put({
      type: profileActions.PATCH_CHANGE_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: profileActions.PATCH_CHANGE_PASSWORD_ERRORS,
      error: error.message,
    });
  }
}

function* doPasswordReset(action) {
  const { params } = action;

  try {
    const response = yield apiPost(`api/password/reset/confirm/`, {
      new_password1: params.newPassword,
      new_password2: params.confirmNewPassword,
      uid: params.uid,
      token: params.token,
    });
    toastr.options.positionClass = "toast-bottom-right";

    if (response.status === 400) {
      let message = Object.values(response.data)[0][0];
      const key = Object.keys(response.data);

      if (key[0] === "token") {
        message = "Password link is either invalid, expired, or used. Please request a new one.";
      }

      toast.error(message, {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        closeButton: true,
      });

      throw new Error(message);
    }

    toast.success("Password Updated Successfully", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
    });
    params.history.push("/login");

    yield put({
      type: profileActions.PATCH_CHANGE_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: profileActions.PATCH_CHANGE_PASSWORD_ERRORS,
      error: error.message,
    });
  }
}

function* doGetReviewPendingList(action) {
  const status = action.params.status;
  const apiString = `api/products/reviews/?status=${status}`;
  if (!_.isEmpty(status.toString())) {
    try {
      const response = yield apiGet(apiString);
      yield put({
        type: profileActions.GET_REVIEW_PENDING_LIST_SUCCESS,
        response,
      });
    } catch (error) {
      console.error(error);
    }
  }
}

function* doSubmitReview(action) {
  const params = action;
  const id = params.params.id;
  const payload = { status: params.params.status };
  const apiString = `api/products/reviews/${id}/`;

  try {
    yield apiPatch(apiString, payload);
    const params = {
      status: "1",
    };
    yield put({ type: profileActions.GET_REVIEW_PENDING_LIST, params });

    toast.success("Comment Published", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
    });
  } catch (error) {
    console.error(error);
  }
}

function* doDeclineReview(action) {
  const params = action;
  const id = params.params.id;
  const payload = { status: params.params.status };

  try {
    yield apiPatch(`api/products/reviews/${id}/`, payload);
    const params = {
      status: "1",
    };
    yield put({
      type: profileActions.GET_REVIEW_PENDING_LIST,
      params,
    });

    toast["error"]("Comment Rejected", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#E74C3C",
      },
      closeButton: true,
      position: "top-right",
      toastId: "comment-rejected",
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetCommentsReviewFilterByDate(action) {
  const startDate = action.params.startDate;
  const endDate = action.params.endDate;
  const status = action.params.status;

  try {
    const response = yield apiGet(
      `api/products/reviews/?status=${status}&created_at__lte=${endDate}&created_at__gte=${startDate}`,
    );

    yield put({
      type: profileActions.GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS,
      response,
    });

    yield put({
      type: commentReviewActions.SET_COMMENT_REVIEW_LAST_UPDATED_DATE,
      response: {
        from: startDate,
        to: endDate,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function* getNextProductsSaga(action) {
  const apiString = action.params.apiPath + action.params.status;
  try {
    const response = yield apiGet(apiString);
    yield put({
      type: profileActions.GET_NEXT_COMMENT_REVIEWS_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductsReviewPendingList(action) {
  const status = action.params.status;
  const response = yield apiGet(`api/products/?status=${status}`);

  try {
    yield put({
      type: profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetNextProductsReviews(action) {
  const apiString = action.params.apiPath + action.params.status;
  try {
    const response = yield apiGet(apiString);
    yield put({
      type: profileActions.GET_NEXT_PRODUCT_REVIEWS_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doSubmitProductReview(action) {
  const params = action;
  const id = params.params.id;
  const payload = {
    status: params.params.status,
  };
  const apiString = `api/products/${id}/`;

  try {
    yield apiPatch(apiString, payload);
    const params = {
      status: 1,
    };
    yield put({ type: profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST, params });
    toast.success("Product Published", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
    });
  } catch (error) {
    console.error(error);
  }
}

function* doDeclineProductReview(action) {
  const params = action;
  const id = params.params.id;
  const payload = { status: params.params.status };

  try {
    yield apiPatch(`api/products/${id}/`, payload);
    const params = {
      status: 1,
    };
    yield put({
      type: profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST,
      params,
    });
    toast.error("Product Rejected", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#E74C3C",
      },
      closeButton: true,
      position: "top-right",
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductsReviewFilterByDate(action) {
  const startDate = action.params.startDate;
  const endDate = action.params.endDate;
  const status = action.params.status;
  try {
    const response = yield apiGet(
      `api/products/?status=${status}&updated_at__lte=${endDate}&updated_at__gte=${startDate}`,
    );

    yield put({
      type: profileActions.GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS,
      response,
    });

    yield put({
      type: productReviewActions.SET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
      response: {
        from: startDate,
        to: endDate,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductsReviewSortBy(action) {
  const name = action.params.name;
  const status = action.params.status;
  try {
    const response = yield apiGet(`api/products/?status=${status}&ordering=${name}`);

    yield put({
      type: profileActions.GET_PRODUCT_REVIEW_PENDING_SORTBY_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doSubmitProductDetails(action) {
  const params = action;
  const id = params.params.id;
  const payload = {
    name: params.params.payload.name,
    description: params.params.payload.description,
    ingredient: params.params.payload.ingredient,
    brand: params.params.payload.brand,
    barcode: params.params.payload.barcode,
    category: params.params.payload.category,
    manufacturer: params.params.payload.manufacturer,
    status: params.params.status,
  };

  const apiString = `api/products/${id}/`;

  try {
    yield apiPatch(apiString, payload);
    const params = {
      status: 1,
    };
    toast.success("Product Published", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
    });
    yield put({ type: profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST, params });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductInfo(action) {
  const productId = action.params.productId;
  try {
    const response = yield apiGet(`api/products/${productId}/`);

    yield put({
      type: profileActions.GET_CURRENT_PRODUCT_INFO_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doDeleteAccount({ params }) {
  const userId = params.userId;
  try {
    yield apiDelete(`api/users/${userId}/`);

    yield put({
      type: LOGOUT,
    });

    params.history.push("/login");

    toast.success("Account has been deleted successfully", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* profileSagas() {
  yield takeLatest(profileActions.PATCH_CHANGE_PASSWORD, doPasswordChange);
  yield takeLatest(profileActions.RESET_PASSWORD, doPasswordReset);
  yield takeLatest(profileActions.GET_REVIEW_PENDING_LIST, doGetReviewPendingList);
  yield takeLatest(profileActions.SUBMIT_REVIEW, doSubmitReview);
  yield takeLatest(profileActions.DECLINE_REVIEW, doDeclineReview);
  yield takeLatest(profileActions.GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE, doGetCommentsReviewFilterByDate);
  yield takeLatest(profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST, doGetProductsReviewPendingList);
  yield takeLatest(profileActions.GET_NEXT_PRODUCT_REVIEWS, doGetNextProductsReviews);
  yield takeLatest(profileActions.GET_PRODUCTS_REVIEW_PENDING_LIST, doGetProductsReviewPendingList);
  yield takeLatest(profileActions.GET_NEXT_PRODUCT_REVIEWS, doGetNextProductsReviews);
  yield takeLatest(profileActions.GET_NEXT_COMMENT_REVIEWS, getNextProductsSaga);
  yield takeLatest(profileActions.DECLINE_PRODUCT_REVIEW, doDeclineProductReview);
  yield takeLatest(profileActions.SUBMIT_PRODUCT_REVIEW, doSubmitProductReview);
  yield takeLatest(profileActions.GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE, doGetProductsReviewFilterByDate);
  yield takeLatest(profileActions.GET_PRODUCT_REVIEW_PENDING_SORTBY, doGetProductsReviewSortBy);
  yield takeLatest(profileActions.SUBMIT_PRODUCT_DETAILS, doSubmitProductDetails);
  yield takeLatest(profileActions.GET_CURRENT_PRODUCT_INFO, doGetProductInfo);
  yield takeLatest(profileActions.DELETE_ACCOUNT, doDeleteAccount);
}
