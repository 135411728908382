import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { logout } from "acds-react-core";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadingScreen from "../../components/common/LoadingScreen";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SSOLoginPage() {
  const userToken = useSelector((state) => state.account.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get("token") || "";
  const user = useSelector((s) => s.account.user);
  const groups = _.get(user, "groups");
  const userGroups = _.map(groups, "name");
  const cookieName = useSelector((state) => state.env.token_name);
  const userIsLoading = useSelector((state) => state.account.userLoading);

  const handleErrorResponse = () => {
    if (token === "None") {
      new Cookies().remove(cookieName, { path: "/" });
      const error = query.get("error_message");
      dispatch(
        logout({
          type: "token-invalidation",
        }),
      );
      if (error) {
        history.push(`/login?error_message=${error}`);
      } else {
        history.push("/login");
      }
    }

    return <></>;
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push(`/login?token=${token}`);
  };

  if (token === "None") {
    return handleErrorResponse();
  }

  if (userIsLoading) {
    return <LoadingScreen />;
  }

  if (userGroups.includes("physician")) {
    new Cookies().remove(cookieName, { path: "/" });

    dispatch(logout());
    history.push("/");
    if (token !== "None") {
      history.push(`/login?token=${token}`);
    } else {
      return handleErrorResponse();
    }
  }

  if (_.isEmpty(userToken)) {
    if (token === "None") {
      return handleErrorResponse();
    }
    if (!_.isEmpty(token)) {
      return <Redirect to={`/login?token=${token}`} />;
    }
  }

  if (_.isEmpty(token)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <CModal
        backdrop="static"
        visible={!_.isEmpty(userToken) && !userGroups.includes("physician")}
        aria-labelledby="StaticBackdropExampleLabel"
        alignment="center"
        className="nav-logout-modal"
      >
        <CModalHeader className="logout-header" style={{ borderBottom: "none" }}>
          <CModalTitle id="logoutHeader">
            <span className="logout-header-txt">Logout confirmation</span>
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="pt-0" style={{ borderBottom: "none" }}>
          You&apos;re logged in as a {userGroups[0]} user. Are you sure you want to switch to a physician account ?
        </CModalBody>
        <CModalFooter style={{ borderTop: "none" }}>
          <CButton
            color="secondary"
            onClick={() => {
              history.push("/");
            }}
          >
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleLogout}>
            Confirm
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default SSOLoginPage;
