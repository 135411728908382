import * as Actions from '../actions/profile';

export const initialState = {
  changePasswordPending: false,
  changePasswordError: null,
  productsPending:[],
  productReviews: [],
  selectedProduct: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.PATCH_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordPending: true,
        changePasswordError: null,
      };
    case Actions.PATCH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordPending: false,
        changePasswordError: null,
      };
    case Actions.PATCH_CHANGE_PASSWORD_ERRORS:
      return {
        ...state,
        changePasswordPending: false,
        changePasswordError: action.error,
      };

    case Actions.GET_REVIEW_PENDING_LIST_SUCCESS:
      return  {
        ...state,
        productsPending: action.response,
      };

    case Actions.GET_COMMENT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS:
      return  {
        ...state,
        productsPending: action.response,
      };

    case Actions.GET_NEXT_COMMENT_REVIEWS_SUCCESS:
      return  {
        ...state,
        productsPending: {
          ...state.productsPending,
          ...action.response,
          results: [...state.productsPending.results, ...action.response.results],
        },
      };

    case Actions.GET_PRODUCTS_REVIEW_PENDING_LIST_SUCCESS:

      return  {
        ...state,
        productReviews: action.response,
      };

    case Actions.GET_NEXT_PRODUCT_REVIEWS_SUCCESS:

      return  {
        ...state,
        productReviews: {
          ...state.productReviews,
          ...action.response,
          results: [...state.productReviews.results, ...action.response.results],
        },
      };

    case Actions.GET_PRODUCT_REVIEW_PENDING_LIST_FILTERBY_DATE_SUCCESS:
      return  {
        ...state,
        productReviews: action.response,
      };

    case Actions.GET_PRODUCT_REVIEW_PENDING_SORTBY_SUCCESS:
      return  {
        ...state,
        productReviews: action.response,
      };

    case Actions.GET_CURRENT_PRODUCT_INFO_SUCCESS:
      return  {
        ...state,
        selectedProduct: action.response,
      };

    default:
      return state;
  }
};
