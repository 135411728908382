// List of notifications
export const GET_NOTIFICATIONS = 'NOTIFICATION/GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_ERROR = 'NOTIFICATION/GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_SUCCESS = 'NOTIFICATION/GET_NOTIFICATIONS_SUCCESS';
export const MARK_NOTIFICATION_AS_READ = 'NOTIFICATION/MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_AS_READ_ERROR = 'NOTIFICATION/MARK_NOTIFICATION_AS_READ_ERROR';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'NOTIFICATION/MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'NOTIFICATION/MARK_ALL_NOTIFICATIONS_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ_ERROR =
  'NOTIFICATION/MARK_ALL_NOTIFICATIONS_AS_READ_ERROR';
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS =
  'NOTIFICATION/MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS';
  
export const notificationGetNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

export const notificationMarkAsRead = (params) => {
  return {
    type: MARK_NOTIFICATION_AS_READ,
    params,
  };
};

export const notificationMarkAllAsRead = () => {
  return {
    type: MARK_ALL_NOTIFICATIONS_AS_READ,
  };
};
