import {
  POST_USER_ANSWERS_SUCCESS,
} from 'acds-redux-core';
import {
  select,
  takeEvery,
} from 'redux-saga/effects';
// import toastr from 'toastr';
import * as _ from 'lodash';

function* doUserAnswerSuccess (action) {
  const { history, shouldRoute } = action;
  
  const showTutorial = yield select((s) => _.get(s.account, 'user.show_tutorial', false));
  if (shouldRoute) history.push(showTutorial ? '/onboarding' : '/safe-list');
}

export default function* routingAndToastSagas () {
  yield takeEvery(POST_USER_ANSWERS_SUCCESS, doUserAnswerSuccess);
}
