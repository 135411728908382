import { apiGet, apiPatch, apiPost } from "acds-react-core";
import { SELECT_INGREDIENT } from "acds-redux-core";
import * as _ from "lodash";
import { all, put, select, takeLatest, delay } from "redux-saga/effects";

import * as Actions from "../actions/physician";
import { toast } from "react-toastify";

function* doLookUpCodes(action) {
  const { params } = action;
  try {
    const response = yield apiGet(`api/codeset/?code1=${params.code1}&code2=${params.code2}`);

    if (response.results.length > 0) {
      yield put({
        type: Actions.LOOK_UP_CODES_SUCCESS,
        response: response.results[0],
      });

      const ingredients = _.get(response, "results.0.cross_reactor_ingredient");
      const excludedAllergenNarrativesArray = _.chain(ingredients)
        .filter({
          allergen_narrative: false,
        })
        .map("ingredient_id")
        .value();

      yield all(
        _.map(ingredients, (ingredient) =>
          put({
            type: SELECT_INGREDIENT,
            ingredient,
            isLookUp: true,
          }),
        ),
      );
      yield put({
        type: "INGREDIENT/SET_ALLERGEN_RESTRICTIVE_ARRAY",
        excludedAllergenNarrativesArray,
      });
      params.history.push(params.route);
    } else {
      yield put({
        type: Actions.LOOK_UP_CODES_ERROR,
        error: "Invalid Codes",
      });
    }
  } catch (error) {
    yield put({
      type: Actions.LOOK_UP_CODES_ERROR,
      error: error.response,
    });
  }
}

function* doGenerateCodesets() {
  try {
    const allergens = yield select((state) => state.ingredientReducer.selectedIngredients);
    const crossReactorGroupsToExclude = yield select((state) => state.ingredientReducer.excludedCrossReactorGroups);
    const fragrances = yield select((state) => state.ingredientReducer.fragrance);

    const allergenCrossReactorChoices = _.map(allergens, (allergen) => ({
      id: allergen.id,
      restrictive: _.get(fragrances, `${allergen.id}.restrictive`, false),
      exclude_crossreactor: _.get(crossReactorGroupsToExclude, allergen.id, []),
    }));

    const codesetGenerateResponse = yield apiPost("api/codeset/", {
      ingredients: allergenCrossReactorChoices,
    });

    const apiString = `api/codeset?code1=${codesetGenerateResponse.code1}&code2=${codesetGenerateResponse.code2}`;
    const codeIdResponse = yield apiGet(apiString);

    const codesetResponse = {
      ..._.cloneDeep(codesetGenerateResponse),
      id: codeIdResponse.results[0].id,
    };

    yield put({
      type: Actions.GENERATE_CODESETS_SUCCESS,
      codesetResponse,
    });
    sessionStorage.setItem("code1", codesetResponse.code1);
    sessionStorage.setItem("code2", codesetResponse.code2);
  } catch (error) {
    yield put({
      type: Actions.GENERATE_CODESETS_ERROR,
      error: error.response.data,
    });
  }
}

function* doUpdateCodesets() {
  try {
    const codesets = yield select((s) => s.physician.codesets);
    const allergens = yield select((s) => s.ingredientReducer.selectedIngredients);
    const crossReactorGroupsToExclude = yield select((state) => state.ingredientReducer.excludedCrossReactorGroups);
    const fragrances = yield select((state) => state.ingredientReducer.fragrance);
    const excludedAllergenNarratives = yield select((state) => state.ingredientReducer.excludedAllergenNarratives);

    const physicianQuestionAnswers = yield select((state) => state.physician.physicianQuestionAnswers);
    const allergenCrossReactorChoices = _.map(allergens, (allergen) => ({
      id: allergen.id,
      restrictive: _.get(fragrances, `${allergen.id}.restrictive`, false),
      allergen_narrative: !excludedAllergenNarratives.includes(allergen.id),
      dietary_sheet: _.get(allergen, "dietary_sheet", false),
      exclude_crossreactor: _.get(crossReactorGroupsToExclude, allergen.id, []),
    }));
    const apiString = `api/codeset?code1=${codesets.code1}&code2=${codesets.code2}`;
    const response = yield apiGet(apiString);
    const { results } = response;
    const patchApiString = `api/codeset/${_.get(results[0], "id")}/`;
    const codesetResponse = yield apiPatch(patchApiString, {
      ingredients: allergenCrossReactorChoices,
    });

    yield put({
      type: Actions.GENERATE_CODESETS_SUCCESS,
      codesetResponse,
    });

    if (physicianQuestionAnswers.length > 0) {
      for (let i = 0; i < physicianQuestionAnswers.length; i++) {
        const value = physicianQuestionAnswers[i];
        yield put({
          type: Actions.POST_PHYSICIAN_QUESTION_ANSWER,
          value,
        });
      }
    }

    yield put({
      type: Actions.GET_PHYSICIAN_ANSWERS,
    });
  } catch (error) {
    yield put({
      type: Actions.GENERATE_CODESETS_ERROR,
      error: error.response.data,
    });
  }
}

function* doEmailSafeList(action) {
  const { params, closeEmail, setUserEmail, productCategories } = action;
  try {
    const codesets = yield select((s) => s.physician.codesets);
    const products = yield select((s) => s.physicianReducer.products);

    let groupedProducts = [];
    if (!_.isEmpty(products)) {
      groupedProducts = products.map(category => {
        const productGroupedByBrand = category.docList.docs.reduce((acc, curr) => {
          if (acc[curr.brand])
            acc[curr.brand].push({ name: curr.name, size: curr.size });
          else 
            acc[curr.brand] = [{name: curr.name, size: curr.size}]
          return acc
        }, {});
  
        const products = Object.entries(productGroupedByBrand).map(([brand, items]) => ({
          brand, items
        }));
  
        products.sort((a, b) => {
          return a.brand.toLowerCase().localeCompare(b.brand.toLowerCase())
        })
      
        return {
          category: category.groupValue,
          count: category.docList.numFound,
          products
        }
      })
    }

    const response = yield apiPost("api/users/email-safe-list/", {
      email: params.email,
      code_1: codesets.code1,
      code_2: codesets.code2,
      product_categories: productCategories
    });

    let responseMsg = response?.success ?? ""

    toast.success(responseMsg, {
      position: "top-right",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
    });

    closeEmail();
    setUserEmail("");

    yield put({
      type: Actions.EMAIL_SAFE_LIST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.EMAIL_SAFE_LIST_ERROR,
      error: error.response.data,
    });
    closeEmail();
    setUserEmail("");
  }
}

function* doDownloadSafeList(action) {
  const { productCategories } = action;

  try {
    const codesets = yield select((s) => s.physician.codesets);
    const response = yield apiPost("api/users/download-safe-list/", {
      code_1: codesets.code1,
      code_2: codesets.code2,
      product_categories: productCategories
    });

    yield put({
      type: Actions.DOWNLOAD_SAFE_LIST_SAVE_TASKID,
      taskId: response.task_id
    })

    yield put({
      type: Actions.DOWNLOAD_SAFE_LIST_POLLING_START
    })
  } catch(error) {
    console.log(error);
    yield put({
      type: Actions.DOWNLOAD_SAFE_LIST_ERROR,
      error: error.response,
    })
  }
}

function* doDownloadSafeListPolling() {
  const taskId = yield select((s) => s.physician.downloadSafeListTaskId);
  try {
    while (true) {
      const response = yield apiPost(`api/users/safe-list-download-status/${taskId}/`)
      yield delay(5000)
      if (response.status === "Success") {
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = `data:application/pdf;base64,${response.pdf_file}`
        a.download = response.pdf_filename
        document.body.appendChild(a)
        a.click()

        toast.success("PDF file downloaded successfully", {
          position: "top-right",
          style: {
            backgroundColor: "#414B4F",
            color: "#ffffff",
          },
          progressStyle: {
            background: "#00c9b7",
          },
          closeButton: true,
        });

        yield put({
          type: Actions.DOWNLOAD_SAFE_LIST_SUCCESS,
        })
        break;
      }
      else if (response.status === "Failed") {
        toast.error(`PDF download failed: ${response.message}`, {
          position: "top-right",
          style: {
            backgroundColor: "#414B4F",
            color: "#ffffff",
          },
          progressStyle: {
            background: "#00c9b7",
          },
          closeButton: true,
        });

        yield put({
          type: Actions.DOWNLOAD_SAFE_LIST_ERROR,
          error: response.message,
        })
        break;
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: Actions.DOWNLOAD_SAFE_LIST_ERROR,
      error: error.response,
    })
  }
}

function* doEmailCodeSets(action) {
  const { params, closeEmail, setUserEmail } = action;
  try {
    const codesets = yield select((s) => s.physician.codesets);

    const response = yield apiPost("api/users/email-safe-list/", {
      email: params.email,
      code_1: codesets.code1,
      code_2: codesets.code2,
    });

    let responseMsg = response?.success ?? ""

    toast.success(responseMsg, {
      position: "top-right",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
    });

    closeEmail();
    setUserEmail("");

    yield put({
      type: Actions.EMAIL_CODESETS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.EMAIL_CODESETS_ERROR,
      error: error.response.data,
    });
  }
}

function* getCommonAllergens() {
  try {
    const apiString = "api/ingredients/quick-list/?is_common=true";
    const response = yield apiGet(apiString);
    yield put({
      type: Actions.SET_COMMON_ALLERGENS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* getIngredientsCrossReactorBasedOnDescription(actions) {
  try {
    const { ingredientId } = actions.payload && actions.payload;
    const apiString = `api/ingredients/?id=${ingredientId}`;
    const response = yield apiGet(apiString);
    if (response.results.length > 0) {
      yield put({
        type: Actions.GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION_SUCCESS,
        response: response.results[0],
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllergensInfoSheets(actions) {
  try {
    const { code1, code2 } = actions.payload;
    const apiString = `api/codeset?code1=${code1}&code2=${code2}`;
    const response = yield apiGet(apiString);
    if (response !== undefined) {
      const result = response.results[0];
      yield put({
        type: Actions.GET_ALLERGEN_INFO_SHEETS_SUCCESS,
        response: result,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAllergenNarratives() {
  try {
    const apiString = "api/ingredients/allergen-narratives?isPatient=False";
    const response = yield apiGet(apiString);
    if (response !== undefined) {
      yield put({
        type: Actions.GET_ALLERGEN_NARATIVES_SUCCESS,
        response,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getDietarySheets() {
  try {
    const apiString = "api/ingredients/dietary-sheets?isPatient=False";
    const response = yield apiGet(apiString);
    if (response !== undefined) {
      yield put({
        type: Actions.GET_DIETARY_SHEETS_SUCCESS,
        response,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* sendIngredientListInfo(actions) {
  try {
    const { codeSetsId, selectedIngredient } = actions.payload;
    const apiString = `api/codeset/${codeSetsId}/`;
    const response = yield apiPatch(apiString, {
      ingredients: selectedIngredient,
    });
    yield put({
      type: Actions.SEND_SELECTED_INGREDIENTS_LIST_INFO_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* postPhysicianQuestionAnswer(actions) {
  const codeset = yield select((state) => state.physician.codesets);
  const physicianQuestionAnswersSync = yield select((state) => state.physician.physicianQuestionAnswersSync);

  try {
    const { value } = actions;
    let isAnswered = false;
    const isQuestionPresent = _.some(physicianQuestionAnswersSync, ["question", value.question]);
    if (isQuestionPresent) {
      isAnswered = true;
    }
    const question = _.find(physicianQuestionAnswersSync, ["question", value.question]);
    if (isAnswered) {
      const patchApiString = `api/users/user-answer/${question.id}/`;
      yield apiPatch(patchApiString, {
        answer_choices: parseInt(value?.answer_choices),
      });
    } else {
      const apiString = "api/users/user-answer/";

      const response = yield apiPost(apiString, {
        codeset_id: parseInt(codeset.id),
        question: parseInt(value?.question),
        answer_choices: parseInt(value?.answer_choices),
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPhysicianAnswers() {
  const codeset = yield select((state) => state.physician.codesets);
  const apiString = `api/users/user-answer/?codeset__id=${codeset.id}`;

  yield put({
    type: Actions.PHYSICIAN_ANSWERS_SYNC_STATUS,
    payload: true,
  });

  const response = yield apiGet(apiString);
  yield put({
    type: Actions.PHYSICIAN_QUESTION_ANSWER_SYNC,
    response,
  });

  yield put({
    type: Actions.PHYSICIAN_ANSWERS_SYNC_STATUS,
    payload: false,
  });
}

export default function* physicianSagas() {
  yield takeLatest(Actions.LOOK_UP_CODES, doLookUpCodes);
  yield takeLatest(Actions.GENERATE_CODESETS, doGenerateCodesets);
  yield takeLatest(Actions.UPDATE_CODESETS, doUpdateCodesets);
  yield takeLatest(Actions.EMAIL_SAFE_LIST, doEmailSafeList);
  yield takeLatest(Actions.EMAIL_CODESETS, doEmailCodeSets);
  yield takeLatest(Actions.DOWNLOAD_SAFE_LIST, doDownloadSafeList);
  yield takeLatest(Actions.DOWNLOAD_SAFE_LIST_POLLING_START, doDownloadSafeListPolling);
  yield takeLatest(Actions.GET_COMMON_ALLERGENS, getCommonAllergens);
  yield takeLatest(Actions.GET_INGREDIENT_CROSSREACTORS_BY_DESCRIPTION, getIngredientsCrossReactorBasedOnDescription);
  yield takeLatest(Actions.GET_ALLERGEN_INFO_SHEETS, getAllergensInfoSheets);
  yield takeLatest(Actions.GET_ALLERGEN_NARRATIVES, getAllergenNarratives);
  yield takeLatest(Actions.GET_DIETARY_SHEETS, getDietarySheets);
  yield takeLatest(Actions.SEND_SELECTED_INGREDIENTS_LIST_INFO, sendIngredientListInfo);
  yield takeLatest(Actions.POST_PHYSICIAN_QUESTION_ANSWER, postPhysicianQuestionAnswer);
  yield takeLatest(Actions.GET_PHYSICIAN_ANSWERS, getPhysicianAnswers);
}
