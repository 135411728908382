export const GET_NOTIFICATION_SETTINGS = 'NOTIFICATION/GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_ERROR = 'NOTIFICATION/GET_NOTIFICATION_SETTINGS_ERROR';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'NOTIFICATION/GET_NOTIFICATION_SETTINGS_SUCCESS';
export const SET_NOTIFICATION_SETTINGS = 'NOTIFICATION/SET_NOTIFICATION_SETTINGS';
export const SET_NOTIFICATION_SETTINGS_ERROR = 'NOTIFICATION/SET_NOTIFICATION_SETTINGS_ERROR';
export const SET_NOTIFICATION_SETTINGS_SUCCESS = 'NOTIFICATION/SET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTING_TYPES = 'NOTIFICATION/GET_NOTIFICATION_SETTING_TYPES';
export const GET_NOTIFICATION_SETTING_TYPES_ERROR =
  'NOTIFICATION/GET_NOTIFICATION_SETTING_TYPES_ERROR';
export const GET_NOTIFICATION_SETTING_TYPES_SUCCESS =
  'NOTIFICATION/GET_NOTIFICATION_SETTING_TYPES_SUCCESS';

export const getNotificationSettingTypes = () => {
  return {
    type: GET_NOTIFICATION_SETTING_TYPES,
  };
};

export const getNotificationSettings = () => {
  return {
    type: GET_NOTIFICATION_SETTINGS,
  };
};

export const setNotificationSettings = (params) => {
  return {
    type: SET_NOTIFICATION_SETTINGS,
    params,
  };
};
