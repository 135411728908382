export const SET_PLOT_DATA = 'PLOT/SET_PLOT_DATA';
export const GET_PLOT_DATA = 'PLOT/GET_PLOT_DATA';
export const SET_PLOT_STATUS = 'PLOT/SET_PLOT_STATUS';
export const SET_PLOT_DATA_ERROR = 'PLOT/SET_PLOT_DATA_ERROR';

export const setSideBarState = (payload) => {
  return {
    type: SET_PLOT_DATA,
    payload,
  };
};

export const getPlotData = (payload) => {
  return {
    type: GET_PLOT_DATA,
    payload,
  };
};
