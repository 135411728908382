// Search
export const INGREDIENT_SEARCH_SUCCESS = 'INGREDIENT/INGREDIENT_SEARCH_SUCCESS';
export const CROSS_REACTOR_SEARCH = 'INGREDIENT/CROSS_REACTOR_SEARCH';
export const CROSS_REACTOR_SEARCH_ERROR =
  'INGREDIENT/CROSS_REACTOR_SEARCH_ERROR';
export const CROSS_REACTOR_SEARCH_SUCCESS =
  'INGREDIENT/CROSS_REACTOR_SEARCH_SUCCESS';
export const GET_NEXT_CROSS_REACTOR_SEARCH =
  'INGREDIENT/GET_NEXT_CROSS_REACTOR_SEARCH';
export const SELECT_INGREDIENT = 'INGREDIENT/SELECT_INGREDIENT';
export const SELECT_INGREDIENT_SUCCESSES =
  'INGREDIENT/SELECT_INGREDIENT_SUCCESSES';
export const REMOVE_INGREDIENT = 'INGREDIENT/REMOVE_INGREDIENT';
export const CLEAR_SELECTED_INGREDIENTS =
  'INGREDIENT/CLEAR_SELECTED_INGREDIENTS';
export const INGREDIENT_FUZZY_SEARCH = 'INGREDIENT/INGREDIENTS_FUZZY_SEARCH';
export const SET_INGREDIENT_RESTRICTIVE = 'INGREDIENT/SET_INGREDIENT_RESTRICTIVE';
export const SET_INGREDIENT_ALLERGEN_DIETARY_SHEET = 'INGREDIENT/SET_INGREDIENT_DIETARY_SHEET';
export const SET_INGREDIENT_ALLERGEN_NARRATIVE = 'INGREDIENT/SET_INGREDIENT_NARRATIVE';
export const EXCLUDE_INGREDIENT_CROSS_REACTOR_GROUP = 'INGREDIENT/EXCLUDE_INGREDIENT_CROSS_REACTOR_GROUP';
export const INGREDIENT_CLEAR_CODES = 'CLEAR_CODES';
export const SET_ALLERGEN_RESTRICTIVE_ARRAY = 'INGREDIENT/SET_ALLERGEN_RESTRICTIVE_ARRAY';

export const ingredientSelectIngredient = (ingredient) => {  
  return {
    type: SELECT_INGREDIENT,
    ingredient,
  };
};

export const removeIngredient = (ingredient) => {
  return {
    type: REMOVE_INGREDIENT,
    ingredient,
  };
};

export const clearSelectedIngredients = () => {
  return {
    type: CLEAR_SELECTED_INGREDIENTS,
  };
};

export const getNextCrossReactorSearch = () => {
  return {
    type: GET_NEXT_CROSS_REACTOR_SEARCH,
  };
};

export const selectIngredientSuccess = (ingredient) => {
  return {
    type: SELECT_INGREDIENT_SUCCESSES,
    ingredient,
  };
};

export const setIngredientRestrictive = (ingredient) => {

  return {
    type: SET_INGREDIENT_RESTRICTIVE,
    ingredient,
  };
};

export const setIngredientAllergenNarrative = (ingredient) => {

  return {
    type: SET_INGREDIENT_ALLERGEN_NARRATIVE,
    ingredient,
  };
};

export const setIngredientDietary= (ingredient) => {

  return {
    type: SET_INGREDIENT_ALLERGEN_DIETARY_SHEET,
    ingredient,
  };
};

export const excludeIngredientCrossReactorGroup = (payload) => {
  return {
    type: EXCLUDE_INGREDIENT_CROSS_REACTOR_GROUP,
    payload,
  };
};
