/* eslint-disable indent */
import { Badge, Box, Progress, Select, Text } from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/common/Header";

import { DateRangePicker } from "react-date-range";

import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  getCommentReviewLastUpdatedDate,
  getProductCommentsNext,
  getProductsComments,
  updateCommentReview,
} from "../../redux/actions/commentReview";
import "./ReviewProduct.scss";

export default function CommentsReviewList() {
  const DAYJS_FORMAT = "MMM DD, YYYY, h:mm a";

  const tableContainerRef = useRef(null);
  const commentReviewLastUpdatedDate = useSelector((state) => state.commentReviews.commentReviewLastUpdatedDate);

  const productsComments = useSelector((state) => state.commentReviews.productsComments);
  const fetchingProductsComments = useSelector((state) => state.commentReviews.fetchingProductsComments);
  const fetchingProductsCommentsNext = useSelector((state) => state.commentReviews.fetchingProductsCommentsNext);
  const productsCommentsNext = useSelector((state) => state.commentReviews.productsCommentsNext);

  const [currentViewChoice, setCurrentViewChoice] = useState(1);

  const [range, setRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
    key: "selection",
  });

  useEffect(() => {
    setRange({
      startDate: dayjs(commentReviewLastUpdatedDate?.from_date ?? undefined).toDate(),
      endDate: dayjs(commentReviewLastUpdatedDate?.to_date ?? undefined).toDate(),
      key: "selection",
    });
  }, [commentReviewLastUpdatedDate]);

  const [openRangePicker, setOpen] = useState(false);

  const refOne = useRef(null);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommentReviewLastUpdatedDate(currentViewChoice));
    dispatch(getProductsComments({ status: currentViewChoice }));
  }, [currentViewChoice]);

  const handleFilterByDate = () => {
    dispatch(
      getProductsComments({
        startDate: dayjs(range.startDate).startOf("day").toISOString(),
        endDate: dayjs(range.endDate).endOf("day").toISOString(),
        status: currentViewChoice,
      }),
    );
    setOpen(false);
  };

  function ReviewAction({ resultId }) {
    return (
      <div className="d-flex" style={{ justifyContent: "left", alignItems: "left", gap: 20 }}>
        {currentViewChoice !== 0 && (
          <div>
            <button
              className="font-button"
              onClick={() => {
                dispatch(
                  updateCommentReview({
                    id: resultId,
                    status: 0,
                  }),
                );
              }}
            >
              <FontAwesomeIcon icon={faCheck} color="#13aa48" size="xl" />
            </button>
          </div>
        )}
        {currentViewChoice !== 2 && (
          <div>
            <button
              className="font-button"
              onClick={() => {
                dispatch(
                  updateCommentReview({
                    id: resultId,
                    status: 2,
                  }),
                );
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#f50000" size="xl" />
            </button>
          </div>
        )}
      </div>
    );
  }

  const loadNextValue = () => {
    if (productsCommentsNext && productsCommentsNext.length > 0) {
      dispatch(getProductCommentsNext());
    }
  };

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 350 &&
          productsComments?.length > 0 &&
          !fetchingProductsComments &&
          !fetchingProductsCommentsNext
        ) {
          loadNextValue();
        }
      }
    },
    [loadNextValue],
  );

  const filterString = `Filtered for : ${dayjs(commentReviewLastUpdatedDate?.from_date ?? undefined).format("MMM D, YY")} to ${dayjs(
    commentReviewLastUpdatedDate?.to_date ?? undefined,
  ).format("MMM D, YY")} `;

  const columns = [
    {
      header: "Id",
      accessorKey: "id",
      size: 50,
      mantineTableHeadCellProps: {
        align: "center",
      },
      mantineTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Rating",
      accessorKey: "rating",
      size: 50,
      mantineTableHeadCellProps: {
        align: "left",
      },
      mantineTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: "Product",
      accessorKey: "product",
      Cell: ({ row }) => {
        return (
          <Text
            c={"#00c9b7"}
            fw={700}
            fz={"md"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            {row.getValue("product")}
          </Text>
        );
      },
      mantineTableHeadCellProps: {
        textAlign: "center",
      },
    },
    {
      header: "Submitting User",
      accessorKey: "submitting_user",
    },
    {
      header: "Action",
      Cell: ({ row }) => {
        return <ReviewAction resultId={row.getValue("id")} />;
      },
      mantineTableHeadCellProps: {
        align: "left",
      },
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: productsComments,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: "530px", minHeight: "530px" },
      onScroll: (event) => fetchMoreOnBottomReached(event.target),
    },
    enablePagination: false,
    enableRowNumbers: true,
    manualSorting: true,
    enableStickyHeader: true,
    selectAllMode: false,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    renderBottomToolbar: () =>
      fetchingProductsCommentsNext && (
        <div>
          <Progress value={100} striped animate color="rgba(0, 201, 183, 1)" />
        </div>
      ),
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: "#e5f1f0",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-numbers": {
        Header: "Sl No",
      },
    },
    state: { isLoading: fetchingProductsComments },
    enableHiding: false,
    enableFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    enableExpanding: true,
    renderDetailPanel: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eaf1f1",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              backgroundColor: "#eaf1f1",
              width: "70%",
              height: "100%",
              padding: 30,
            }}
          >
            <Text fw={"bold"}>
              Content:{" "}
              <Text fw={200} span>
                {row.original?.content}
              </Text>
            </Text>
            <Text fw={"bold"}>
              Username:{" "}
              <Text fw={200} span>
                {row.original?.username}
              </Text>
            </Text>
            <Text fw={"bold"} sx={{ textAlign: "left" }}>
              Rating:{" "}
              <Text fw={200} span sx={{ textAlign: "left", display: "inline-block" }}>
                {row.original?.rating}
              </Text>
            </Text>
            <Text fw={"bold"}>
              Product:{" "}
              <Text fw={200} span>
                {row.original?.product}
              </Text>
            </Text>
            <Text fw={"bold"}>
              Created at:{" "}
              <Text fw={200} span>
                {dayjs(row.original?.created_at).format(DAYJS_FORMAT)}
              </Text>
            </Text>
            <Text fw={"bold"}>
              Submitting user:{" "}
              <Text fw={200} span>
                {row.original?.submitting_user}
              </Text>
            </Text>
            <Text fw={"bold"}>
              Last Updated:{" "}
              <Text fw={200} span>
                {dayjs(row.original?.updated_at).format(DAYJS_FORMAT)}
              </Text>
            </Text>
          </Box>
        </Box>
      );
    },
  });

  return (
    <div>
      <Header title="Comment Reviews" />
      <div className="review-products">
        <div className="w-100">
          <div
            className="d-flex mx-0 my-2"
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              gap: "100",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="mx-0"
            >
              <Text size="md" className="mx-2">
                Filter By:
              </Text>
              <Select
                data={[
                  { value: 1, label: "Pending Review" },
                  { value: 0, label: "Published" },
                  { value: 2, label: "Rejected" },
                ]}
                value={currentViewChoice}
                onChange={(value) => {
                  if (tableContainerRef.current) {
                    tableContainerRef.current.scrollTop = 0;
                  }
                  setCurrentViewChoice(value);
                }}
                styles={{
                  item: {
                    "&[data-selected]": {
                      "&, &:hover": {
                        backgroundColor: "#00c9b7",
                        color: "#fff",
                      },
                    },
                  },
                  input: {
                    ":focus": {
                      borderColor: "#00c9b7",
                    },
                  },
                }}
              />
            </div>
            <div>
              <div className="sort-dropdown-span">
                <div style={{ position: "relative" }}>
                  <div>
                    <Badge
                      color="dark"
                      size="xl"
                      variant="outline"
                      onClick={() => setOpen((open) => !open)}
                      styles={{
                        inner: {
                          fontWeight: "normal",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "none",
                        },
                        root: {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      {filterString}
                    </Badge>
                  </div>
                  {openRangePicker && (
                    <div className="product-review-date-picker-container">
                      <DateRangePicker
                        onChange={(item) => setRange(item.selection)}
                        editableDateInputs={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[range]}
                        direction="horizontal"
                        className="z-index"
                      />
                      <div className="con-button">
                        <button className="btn-submit" onClick={handleFilterByDate}>
                          Apply
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() => {
                            setOpen(!openRangePicker);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <MantineReactTable table={table} />
      </div>
    </div>
  );
}
