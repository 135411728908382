// Sync to turn saga on or off
export const START_QUESTIONS_SYNC = 'QUESTIONS/START_QUESTIONS_SYNC';
export const STOP_QUESTIONS_SYNC = 'QUESTIONS/STOP_QUESTIONS_SYNC';
// Get answers
export const GET_USER_ANSWERS = 'QUESTIONS/GET_USER_ANSWERS';
export const GET_USER_ANSWERS_SUCCESS = 'QUESTIONS/GET_USER_ANSWERS_SUCCESS';
export const GET_USER_ANSWERS_ERRORS = 'QUESTIONS/GET_USER_ANSWERS_ERRORS';
export const GET_USER_ANSWERS_STATUS = 'QUESTIONS/GET_USER_ANSWERS_STATUS';
// Send answers to questions
export const POST_USER_ANSWERS = 'QUESTIONS/POST_USER_ANSWERS';
export const POST_USER_ANSWERS_SUCCESS = 'QUESTIONS/POST_USER_ANSWERS_SUCCESS';
export const POST_USER_ANSWERS_ERRORS = 'QUESTIONS/POST_USER_ANSWERS_ERRORS';
export const PATCH_USER_ANSWERS = 'QUESTIONS/PATCH_USER_ANSWERS';
export const PATCH_USER_ANSWERS_SUCCESS = 'QUESTIONS/PATCH_USER_ANSWERS_SUCCESS';
export const PATCH_USER_ANSWERS_ERRORS = 'QUESTIONS/PATCH_USER_ANSWERS_ERRORS';
export const SUBMIT_USER_ANSWERS = 'QUESTIONS/SUBMIT_USER_ANSWERS';
// Questions
export const GET_QUESTIONS = 'QUESTIONS/GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'QUESTIONS/GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERRORS = 'QUESTIONS/GET_QUESTIONS_ERRORS';
export const GET_QUESTION_CATEGORIES = 'QUESTIONS/GET_QUESTION_CATEGORIES';
export const GET_QUESTION_CATEGORIES_SUCCESS = 'QUESTIONS/GET_QUESTION_CATEGORIES_SUCCESS';
export const GET_QUESTION_CATEGORIES_ERRORS = 'QUESTIONS/GET_QUESTION_CATEGORIES_ERRORS';

export const getQuestions = (questionType) => {
  return {
    type: GET_QUESTIONS,
    questionType,
  };
};

export const getQuestionCategories = () => {
  return {
    type: GET_QUESTION_CATEGORIES,
  };
};

export const startQuestionsSync = () => {
  return {
    type: START_QUESTIONS_SYNC,
  };
};

export const stopQuestionsSync = () => {
  return {
    type: STOP_QUESTIONS_SYNC,
  };
};

export const getUserAnswers = (params) => {
  return {
    type: GET_USER_ANSWERS,
    params,
  };
};

export const postUserAnswers = (
  params,
  history,
  shouldRoute=false,
) => {
  return {
    type: POST_USER_ANSWERS,
    params,
    history,
    shouldRoute,
  };
};

export const postUserAnswersSuccess = (
  response,
  history,
  shouldRoute,
) => {
  return {
    type: POST_USER_ANSWERS_SUCCESS,
    response,
    history,
    shouldRoute,
  };
};

export const putUserAnswers = (params) => {
  return {
    type: PATCH_USER_ANSWERS,
    params,
  };
};

export const submitUserAnswers = (
  params,
  history,
  shouldRoute=false,
) => {
  return {
    type: SUBMIT_USER_ANSWERS,
    params,
    history,
    shouldRoute,
  };
};
