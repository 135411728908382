export const GET_CODE_SET_DETAILS = "RESET_CODE_SET/GET_CODE_SET_DETAILS";
export const GET_CODE_SET_DETAILS_SUCCESS = "RESET_CODE_SET/GET_CODE_SET_DETAILS_SUCCESS";
export const GET_CODE_SET_DETAILS_ERROR = "RESET_CODE_SET/GET_CODE_SET_DETAILS_ERROR";

export const RESET_CODE_SETS = "RESET_CODE_SET/RESET_CODE_SETS";
export const RESET_CODE_SETS_SUCCESS = "RESET_CODE_SET/RESET_CODE_SETS_SUCCESS";
export const RESET_CODE_SETS_ERROR = "RESET_CODE_SET/RESET_CODE_SETS_ERROR";

export const RESET_CODE_RESET_STATE = "RESET_CODE_SET/RESET_CODE_RESET_STATE";

export const getCodeSetDetails = (code1, code2) => {
  return {
    type: GET_CODE_SET_DETAILS,
    code1,
    code2,
  };
};

export const resetResetCodeSetState = () => {
  return {
    type: RESET_CODE_RESET_STATE,
  };
};

export const resetCodeSets = (codeSetId) => {
  return {
    type: RESET_CODE_SETS,
    codeSetId,
  };
};
