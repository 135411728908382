/* eslint-disable no-case-declarations */
import * as _ from "lodash";
import * as Actions from "../actions/safeList";

const initialState = {
  activeTab: {},
  getCategoriesLoading: false,
  productCategories: null,
  productCategoriesError: null,
  submitProductError: null,
  uploadProductPhotoError: null,
  viewBy: "Category",
  unsafeIngredients: [],
  productBrandsLoading: false,
  productBrands: [],
  productBrandNext: "",
  productManufacturesLoading: false,
  productManufactures: [],
  productManufacturesNext: "",
  addProductSubmitting: false,
  categoriesFlattened: [],
  isFetchingManufacturer: false,
  isFetchingManufacturerNext: false,
  isFetchingBrand: false,
  isFetchingBrandNext: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Product Categories
    case Actions.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        getCategoriesLoading: true,
      };
    case Actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: _.get(action, "category"),
      };
    case Actions.SET_VIEW_BY_TYPE:
      return {
        ...state,
        viewBy: _.get(action, "viewBy"),
      };
    case Actions.GET_PRODUCT_CATEGORIES_SUCCESS: {
      const flattenList = (list) => {
        const flattened = _.flattenDeep(
          list.map((item) => {
            if (item.children.length === 0) {
              return item;
            }

            return [item, ...flattenList(item.children)];
          }),
        );

        return flattened;
      };

      const flattenedList = flattenList(_.get(action, "response", []));

      return {
        ...state,
        getCategoriesLoading: false,
        productCategories: _.get(action, "response"),
        categoriesFlattened: flattenedList,
      };
    }
    case Actions.GET_PRODUCT_CATEGORIES_ERRORS:
      return {
        ...state,
        getCategoriesLoading: false,
        productCategoriesError: action.error,
      };
    case Actions.POST_SUBMIT_PRODUCT_ERRORS:
      return {
        ...state,
        submitProductError: action.error,
      };
    case Actions.UPLOAD_PRODUCT_PHOTO_ERRORS:
      return {
        ...state,
        uploadProductPhotoError: action.error,
      };
    case Actions.GET_UNSAFE_INGREDIENTS:
      return {
        ...state,
      };
    case Actions.SET_UNSAFE_INGREDIENTS:
      return {
        ...state,
        unsafeIngredients: [...action.payload],
      };
    case Actions.EMPTY_UNSAFE_INGREDIENTS:
      return {
        ...state,
        unsafeIngredients: [],
      };
    case Actions.GET_PRODUCT_BRANDS:
      return {
        ...state,
        productBrandsLoading: true,
        isFetchingBrand: true,
        isFetchingManufacturerNext: false,
      };
    case Actions.GET_PRODUCT_BRANDS_SUCCESS:
      return {
        ...state,
        productBrands: action.response.data,
        productBrandNext: action.response.next,
        productBrandsLoading: false,
        isFetchingBrand: false,
      };
    case Actions.GET_PRODUCT_MANUFACTURERS:
      return {
        ...state,
        productManufacturesLoading: true,
        isFetchingManufacturer: true,
        isFetchingManufacturerNext: false,
      };
    case Actions.GET_PRODUCT_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        productManufactures: action.response.data,
        productManufacturesNext: _.get(action.response, "next", ""),
        productManufacturesLoading: false,
        isFetchingManufacturer: false,
      };
    case Actions.SET_ADD_PRODUCT_SUBMITTING:
      return {
        ...state,
        addProductSubmitting: action.value,
      };
    case Actions.GET_PRODUCT_BRANDS_NEXT: {
      return {
        ...state,
        isFetchingBrandNext: true,
      };
    }
    case Actions.GET_PRODUCT_BRANDS_NEXT_SUCCESS: {
      const { data } = action.response;

      return {
        ...state,
        productBrands: [...state.productBrands, ...data],
        isFetchingBrandNext: false,
      };
    }
    case Actions.GET_PRODUCT_BRANDS_NEXT_ERROR: {
      return {
        ...state,
        isFetchingBrandNext: false,
      };
    }
    case Actions.GET_PRODUCT_MANUFACTURERS_NEXT: {
      return {
        ...state,
        isFetchingManufacturerNext: true,
      };
    }
    case Actions.GET_PRODUCT_MANUFACTURERS_NEXT_SUCCESS: {
      const { data } = action.response;

      return {
        ...state,
        productManufactures: [...state.productManufactures, ...data],
        isFetchingManufacturerNext: false,
      };
    }
    case Actions.GET_PRODUCT_MANUFACTURERS_NEXT_ERROR: {
      return {
        ...state,
        isFetchingBrandNext: false,
      };
    }
    default:
      return state;
  }
};
