import { apiGet, apiPost } from 'acds-react-core';
import { put, takeLatest } from 'redux-saga/effects';
import * as Actions from '../actions/notificationSettings';

function* doGetNotificationSettingTypes () {
  try {
    const response = yield apiGet('api/notifications/types/');
    yield put({
      type: Actions.GET_NOTIFICATION_SETTING_TYPES_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_NOTIFICATION_SETTING_TYPES_ERROR,
      error,
    });
  }
}

function* doGetNotificationSettings () {
  try {
    const response = yield apiGet('api/notifications/my_settings/');
    yield put({
      type: Actions.GET_NOTIFICATION_SETTINGS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_NOTIFICATION_SETTINGS_ERROR,
      error,
    });
  }
}

function* doUpdateNotificationSettings (action) {
  const {params} = action;
  try {
    const response = yield apiPost(`api/notifications/my_settings/update_settings/`, params);
    yield put({
      type: Actions.SET_NOTIFICATION_SETTINGS_SUCCESS,
      response,
    });
    if(params.type === 'web') {
      params.toast.success('Notifications settings has been updated successfully', {
        bodyClassName: 'toast-body',
        style: {
          backgroundColor: '#414B4F',
          color: '#ffffff',
        },
        progressStyle: {
          background: '#00c9b7',
        },
        position: 'bottom-right',
      });
    }
  } catch (error) {
    yield put({
      type: Actions.SET_NOTIFICATION_SETTINGS_ERROR,
      error,
    });
  }
}

export default function* notificationSettings () {
  yield takeLatest(Actions.GET_NOTIFICATION_SETTING_TYPES, doGetNotificationSettingTypes);
  yield takeLatest(Actions.GET_NOTIFICATION_SETTINGS, doGetNotificationSettings);
  yield takeLatest(Actions.SET_NOTIFICATION_SETTINGS, doUpdateNotificationSettings);
}
