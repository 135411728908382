export const POST_CONTACT_US = 'COMMON_UTILS/POST_FORGOT_PASSWORD';

export const GET_TERMS = 'COMMON_UTILS/GET_TERMS';
export const GET_TERMS_SUCCESS = 'COMMON_UTILS/GET_TERMS_SUCCESS';

export const GET_PRIVACY_POLICY = 'COMMON_UTILS/GET_PRIVACY_POLICY';
export const GET_PRIVACY_POLICY_SUCCESS = 'COMMON_UTILS/GET_PRIVACY_POLICY_SUCCESS';
export const GET_FAQ = 'COMMON_UTILS/GET_FAQ';
export const GET_FAQ_SUCCESS = 'COMMON_UTILS/ GET_FAQ_SUCCESS';
export const GET_GENERAL_EDUCATION = 'COMMON_UTILS/GET_GENERAL_EDUCATION';
export const GET_GENERAL_EDUCATION_SUCCESS = 'COMMON_UTILS/GET_GENERAL_EDUCATION_SUCCESS';

export const postContactUs = (payload, toggle) => {

  return {
    type: POST_CONTACT_US,
    payload,
    toggle,
  };
};

export const getTerms = () => {
  return {
    type: GET_TERMS,
  };
};

export const getPrivacyPolicy = () => {
  return {
    type: GET_PRIVACY_POLICY,
  };
};

export const getFaq = () => {
  return {
    type: GET_FAQ,
  };
};

export const getGeneralEducation = () => {
  return {
    type: GET_GENERAL_EDUCATION,
  };
};
