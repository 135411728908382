import * as _ from 'lodash';
import * as Actions from '../actions/notificationSettings';

const initialState = {
  notificationSettingTypes: [],
  notificationSettings: [],
  submitPending: false,
  error: null,
  success: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_NOTIFICATION_SETTINGS:
    case Actions.SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        submitPending: true,
      };
    case Actions.GET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        error: null,
        submitPending: false,
        notificationSettings: _.get(action, 'response.results'),
      };
    case Actions.SET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        submitPending: false,
        error: null,
        success: 'Your notification settings have been updated.',
        notificationSettings: _.get(action, 'response.results'),
      };
    case Actions.GET_NOTIFICATION_SETTING_TYPES_SUCCESS:
      return {
        ...state,
        notificationSettingTypes: _.get(action, 'response.results'),
      };
    case Actions.GET_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        error: 'There was an error retrieving your settings, please try again later.',
        submitPending: false,
      };
    case Actions.SET_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        error: 'There was an error updating your settings, please try again later.',
        success: null,
        submitPending: false,
      };
    default:
      return state;
  }
};
