import * as _ from 'lodash';

import * as Actions from '../actions/ingredient';
import { ELASTIC_SEARCH } from '../actions/search';

const initialState = {
  crossReactors: [],
  crossReactorsNext: null,
  crossReactorsPending: false,
  crossReactorsError: null,
  ingredientCount: 0,
  ingredientsError: false,
  ingredientsPending: false,
  ingredients: [],
  ingredientsNext: null,
  selectedIngredients: [],
  totalIngredientCount: 0,
  offset: 250,
  crossReactorsCount: 0,
  excludedCrossReactorGroups: {},
  fragrance: {},
  excludedAllergenNarratives: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ELASTIC_SEARCH:
      return {
        ...state,
        ingredients: []
      }
    // Ingredient Search
    case Actions.INGREDIENT_SEARCH_SUCCESS:
      return {
        ...state,
        ingredientsPending: false,
        ingredients: action.paginated ? [
          ...state.ingredients,
          ..._.get(action, 'response.results'),
        ] : _.get(action, 'response.results'),
        ingredientsNext: _.get(action, 'response.next'),
        searchText: action.searchValue,
      };
    // Cross-reactor Search
    case Actions.CROSS_REACTOR_SEARCH:
    case Actions.CROSS_REACTOR_SEARCH_ERROR:
      return {
        ...state,
        crossReactorsPending: false,
        crossReactorsError: _.get(action, 'error'),
      };
    case Actions.CROSS_REACTOR_SEARCH_SUCCESS:
      var successCrossReactors = _.get(action, 'formattedResult', []);
      var crossReactorCount =  _.get(action, 'count', 0);

      return {
        ...state,
        crossReactorsPending: false,
        crossReactors: action.paginated ? [
          ...state.crossReactors,
          ...successCrossReactors,
        ] : successCrossReactors,
        crossReactorsNext: _.get(action, 'response.next'),
        searchText: action.searchValue,
        crossReactorsCount: crossReactorCount,
      };
    case Actions.SELECT_INGREDIENT_SUCCESSES:{
      const ingredient = action.ingredient;
      const isLookUp = action.isLookUp;
      
      ingredient['id'] = ingredient['ingredient_id'] || ingredient['id'];
      
      if(_.isUndefined(ingredient.ingredient_id) || _.isEmpty(ingredient.ingredient_id)) {
        ingredient['ingredient_id'] = ingredient['id'];
      } 

      const crossReactorAlreadySelected = _.has(state.excludedCrossReactorGroups, ingredient.ingredient_id);
      const fragranceAlreadySelected = _.has(state.fragrance, ingredient.ingredient_id);
      
      let ingredientExcludeCrossReactors;
      
      if (crossReactorAlreadySelected) {
        ingredientExcludeCrossReactors = _.get(state.excludedCrossReactorGroups, ingredient.ingredient_id, []);
      } else {
        ingredientExcludeCrossReactors = _.get(ingredient, 'exclude_crossreactor', []);
      }

      let ingredientRestrictive = {};

      if(!fragranceAlreadySelected) {
        if(isLookUp) {
          if(_.has(ingredient, 'fragrance.restrictive')) {
            ingredientRestrictive = {
              [ingredient.id] : {
                'restrictive': _.get(ingredient, 'restrictive', false),
              },
            };
          }
        } else {
          if(_.has(ingredient, 'fragrance.restrictive')) {
            ingredientRestrictive = {
              [ingredient.id] : {
                'restrictive': false,
              },
            };
          } 
        }
      }
      
      return {
        ...state,
        selectedIngredients: _.uniqBy([
          ...state.selectedIngredients,
          ingredient,
        ], 'id'),
        excludedCrossReactorGroups: {
          ...state.excludedCrossReactorGroups,
          [ingredient.ingredient_id]: ingredientExcludeCrossReactors,
        },
        fragrance: {
          ...state.fragrance,
          ...ingredientRestrictive,
        },
      };
    }
    case Actions.REMOVE_INGREDIENT:
      return {
        ...state,
        selectedIngredients: _.filter(
          state.selectedIngredients,
          (ingredient) => (ingredient.id !== _.get(action, 'ingredient.ingredient_id')),
        ),
        excludedCrossReactorGroups: _.omit(state.excludedCrossReactorGroups, _.get(action, 'ingredient.ingredient_id')),
        fragrance: _.omit(state.fragrance, _.get(action, 'ingredient.ingredient_id')),
      };
    case Actions.CLEAR_SELECTED_INGREDIENTS:
      return {
        ...state,
        selectedIngredients: [],
        excludedCrossReactorGroups: {},
        fragrance: {},
        excludedAllergenNarratives: [],
      };
    case Actions.INGREDIENT_FUZZY_SEARCH:
      var ingredient;  
      var hits = _.get(action, 'response.hits.hits'); 
      var total = _.get(action, 'response.hits.total');
      
      if(state.ingredients.length > 0 && action.paginated) {
        ingredient = [...state.ingredients, ...hits];
      } else {
        ingredient = hits;
      }
      
      return {
        ...state,
        ingredientsPending: false,
        ingredients: ingredient,
        totalIngredientCount: total,
      };
    case Actions.SET_INGREDIENT_RESTRICTIVE:{
      const payload = _.get(action, 'ingredient', {});
      const { id, restrictive } = payload;

      return {
        ...state,
        fragrance: {
          ...state.fragrance,
          [id]: {
            restrictive,
          },
        },
      };
    }
    case Actions.SET_INGREDIENT_ALLERGEN_NARRATIVE:{
      const ingredientId = _.get(action, 'ingredient.id')
      const isAllergenNarrativeExcluded = state.excludedAllergenNarratives.includes(ingredientId);

      return {
        ...state,
        excludedAllergenNarratives: isAllergenNarrativeExcluded ? [..._.pull(state.excludedAllergenNarratives, ingredientId)] : _.union(state.excludedAllergenNarratives, [ingredientId])
      };
    }
    case Actions.SET_INGREDIENT_ALLERGEN_DIETARY_SHEET:
      var ingredientDietarySheetClone = _.cloneDeep(state.selectedIngredients);

      return {
        ...state,
        selectedIngredients: _.map(ingredientDietarySheetClone, (allergen) => {
          if (allergen.id === _.get(action, 'ingredient.id')) {
            return {
              ...allergen,
              dietary_sheet: _.get(action, 'ingredient.dietary_sheet'),
            };
          } else {
            return allergen;
          }
        }),
      };
    case Actions.EXCLUDE_INGREDIENT_CROSS_REACTOR_GROUP:
    {
      
      const payload = _.get(action, 'payload', {});
      const { ingredientId, id: crossReactorGroupToExclude } = payload;

      const ingredientExistInCrossReactor = _.has(state.excludedCrossReactorGroups, ingredientId);
      
      let crossReactorToExclude;
      
      if (ingredientExistInCrossReactor) {
        const excludedCrossReactorsGroups = _.get(state.excludedCrossReactorGroups, ingredientId, []);
      
        crossReactorToExclude = {
          [ingredientId]: excludedCrossReactorsGroups.includes(crossReactorGroupToExclude)
            ? _.pull(excludedCrossReactorsGroups, crossReactorGroupToExclude)
            : _.union(excludedCrossReactorsGroups, [crossReactorGroupToExclude]),
        };
      } else {
        crossReactorToExclude = {
          [ingredientId]: [crossReactorGroupToExclude],
        };
      }
      
      return {
        ...state,
        excludedCrossReactorGroups: {
          ...state.excludedCrossReactorGroups,
          [ingredientId]: crossReactorToExclude[ingredientId],
        },
      };      
    }
    case Actions.INGREDIENT_CLEAR_CODES:
      return {
        ...state,
        excludedCrossReactorGroups: {},
        fragrance: {},
      };
    case Actions.SET_ALLERGEN_RESTRICTIVE_ARRAY:{
      return {
        ...state,
        excludedAllergenNarratives: [...action.excludedAllergenNarrativesArray]
      }
    }
    default:
      return state;
  }
};
