import {
  apiGet,
  apiPatch,
  apiPost,
} from 'acds-react-core';
import * as _ from 'lodash';
import {
  actionChannel,
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import * as Actions from '../actions/questions';

function* doGetQuestionCategories () {
  try {
    const response = yield apiGet('api/users/question-categories/', {authenticationRequired: false});

    yield put({
      type: Actions.GET_QUESTION_CATEGORIES_SUCCESS,
      response,
    });

    return response.results;
  } catch (error) {
    yield put({
      type: Actions.GET_QUESTION_CATEGORIES_ERRORS,
      error: error.response.data,
    });
  }
}

function* doGetQuestions ({questionType}) {
  try {
    const categories = yield call(doGetQuestionCategories);
    const currCategory = _.find(
      categories,
      (category) => category.category_type_label === questionType,
    );

    const response = yield apiGet(
      `api/users/questions/?category__category_type=${
        _.get(currCategory, 'category_type')
      }`,
      {authenticationRequired: false}
    );

    yield put({
      type: Actions.GET_QUESTIONS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_QUESTIONS_ERRORS,
      error: error.response.data,
    });
  }
}

function* doGetUserAnswers ({ params }) {
  try {
    const categories = yield select((s) => s.questionsReducer.categories);
    const currCategory = _.find(
      categories,
      (category) => category.category_type_label === params.questionType,
    );

    const response = yield apiGet(
      `api/users/user-answer/?user__email=${
        _.get(params, 'user.email')
      }&question__category__category_type=${
        _.get(currCategory, 'category_type')
      }`
    );

    yield put({
      type: Actions.GET_USER_ANSWERS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_USER_ANSWERS_ERRORS,
      error: error.data,
    });
  }
}

function* doPostAnswers ({
  params,
  history,
  shouldRoute=false,
}) {
  try {
    const choices = _.isArray(params.answer_choices) ? _.map(params.answer_choices, 'id') : params.id;
    
    const response = yield apiPost('api/users/user-answer/', {
      question: params.question,
      answer_choices: choices,
    });
    
    yield put({
      type: Actions.POST_USER_ANSWERS_SUCCESS,
      response,
      history,
      shouldRoute,
    });
  } catch (error) {
    yield put({
      type: Actions.POST_USER_ANSWERS_ERRORS,
      error: _.get(error, 'response.data'),
    });
  }
}

function* doPatchAnswers ({ params }) {
  try {
    const choices = _.isArray(params.answer_choices) ? _.map(params.answer_choices, 'id') : params.id;

    const response = yield apiPatch(`api/users/user-answer/${params.existingId}/`, {
      question: params.question,
      answer_choices: choices,
    });

    yield put({
      type: Actions.PATCH_USER_ANSWERS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.PATCH_USER_ANSWERS_ERRORS,
      error: error.response.data,
    });
  }
}

function* doSubmitUserAnswers ({
  params,
  history,
  shouldRoute=false,
}) {
  // Find new form answers to questions not previously answered
  const type = _.get(params, 'type', false);

  const newAnswers = _.pickBy(
    _.differenceBy(
      _.toArray(params.newAnswers),
      params.currentAnswers,
      'question',
    ),
    _.identity,
  );

  const omitEmptyAnswers = _.omitBy(
    newAnswers,
    (ans) => (
      _.isUndefined(ans) || (
        _.get(ans, 'questionType') === 2 &&
        _.isEmpty(ans.answer_choices)
      )
    ),
  );

  // Map through new answer saga calls in a sagas friendly manner.
  yield all(
    _.map(
      omitEmptyAnswers,
      (answer) => 
        put({
          type: Actions.POST_USER_ANSWERS,
          params: answer,
          history,
          shouldRoute,
        }),
    ),
  );
 
  // For questions with an existing answer
  // Find questions updated by form changes.
  const updatingAnswers = _.pickBy(
    _.intersectionBy(
      _.toArray(params.newAnswers),
      params.currentAnswers,
      'question',
    ),
    _.identity,
  );

  // Add existingId field to each updated answer
  const expandedUpdatingAnswers = _.filter(
    _.map(
      updatingAnswers,
      (ans) => {
        // This is if it is a multi-select question
        if (_.get(ans, 'questionType') === 2) {
          ans['existingId'] = _.get(ans, 'id');
          // Set the ids for multi-select choices
          ans['id'] = _.map(
            _.get(ans, 'answer_choices'),
            'id',
          );
        } else {
          ans['existingId'] = _.get(ans, 'existing.id');
        }

        return ans;
      },
    ),
    (ans) => ans.id !== ans.existingId ,
  );

  // Remove multi-select entries if the answer_choices have not changed
  _.remove(
    expandedUpdatingAnswers,
    (ans) => {
      let shouldRemove = false;
      if (_.get(ans, 'questionType') === 2) {
        const addedAns = _.difference(
          _.map(_.get(ans, 'answer_choices'), 'id'),
          _.map(_.get(ans, 'existing'), 'id'),
        ).length === 0;

        const removedAns = _.difference(
          _.map(_.get(ans, 'existing'), 'id'),
          _.map(_.get(ans, 'answer_choices'), 'id'),
        ).length === 0;

        shouldRemove = addedAns && removedAns;
      }

      return shouldRemove;
    },
  );

  // Map through updating answer saga calls in a sagas friendly manner.
  try {
    yield all(
      _.map(
        expandedUpdatingAnswers,
        (answer) => 
          put({type: Actions.PATCH_USER_ANSWERS, params: answer}),
      ),
    );
  } catch (error) {
    console.log(error); 
  }

  if(type === 'web') {
    params.toast.success('User Answers updated', {
      bodyClassName: 'toast-body',
      style: {
        backgroundColor: '#414B4F',
        color: '#ffffff',
      },
      progressStyle: {
        background: '#00c9b7',
      },
      position: 'bottom-right',
    });
  }

  if(shouldRoute) {
    history.push('/onboarding');
  }

}

// This method of calling and cancelling questionSync prevents this saga from running on non-question pages
function* doQuestionsSync () {
  const channel = yield actionChannel([
    Actions.GET_USER_ANSWERS,
    Actions.GET_QUESTIONS,
    Actions.GET_QUESTION_CATEGORIES,
    Actions.SUBMIT_USER_ANSWERS,
  ]);
  try {
    while(true) {
      const action = yield take(channel);

      switch (action.type) {
        case Actions.GET_USER_ANSWERS:
          yield put({
            type: Actions.GET_USER_ANSWERS_STATUS,
            isLoading: true,
          });
          yield call(doGetUserAnswers, action);
          break;
        case Actions.SUBMIT_USER_ANSWERS:
          yield call(doSubmitUserAnswers, action);
          break;
        case Actions.GET_QUESTIONS:
          yield call(doGetQuestions, action);
          break;
        case Actions.GET_QUESTION_CATEGORIES:
          yield call(doGetQuestionCategories, action);
          break;
        default:
          break;
      }
    }
  } finally {
    if (yield cancelled())
      yield put(Actions.stopQuestionsSync('Sync cancelled!'));
  }
}

export function* questionsFlow () {
  while ( yield take(Actions.START_QUESTIONS_SYNC)) {
    // Starts the task in the background
    const questionsSyncTask = yield fork(doQuestionsSync);

    // Wait for questions to complete
    yield take(Actions.STOP_QUESTIONS_SYNC);

    // Leave questions pages. Cancel the background task
    // this will cause the forked doQuetionsSync task to jump into its finally block
    yield cancel(questionsSyncTask);
  }
}

export default function* questionsSagas () {
  yield takeEvery(Actions.POST_USER_ANSWERS, doPostAnswers);
  yield takeEvery(Actions.PATCH_USER_ANSWERS, doPatchAnswers);
  yield questionsFlow();
}
