export const PHYSICIAN_PRODUCT_SEARCH_SUCCESS = 'PHYSICIAN/PHYSICIAN_PRODUCT_SEARCH_SUCCESS';
export const PHYSICIAN_PRODUCT_SEARCH_STATUS = 'PHYSICIAN_PRODUCT_SEARCH_STATUS';
export const CLEAR_PHYSICIAN_PRODUCT = 'PHYSICIAN/CLEAR_PRODUCTS';
export const SEARCH_NEXT_CATEGORIES_PENDING = 'PHYSICIAN/SEARCH_NEXT_CATEGORIES_PENDING'

export const setPhysicianProductSearchStatus = (status) => {
  return {
    type: PHYSICIAN_PRODUCT_SEARCH_STATUS,
    status,
  };
};

export const clearPhysicianProducts = () => {
  return {
    type: CLEAR_PHYSICIAN_PRODUCT,
  };
};

export const searchNextCategoriesPending = (status) => {
  return {
    type: SEARCH_NEXT_CATEGORIES_PENDING,
    status: true
  }
}
