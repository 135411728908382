import { apiGet } from 'acds-react-core';
import { put, select, takeEvery } from 'redux-saga/effects';

import * as Actions from '../actions/ingredient';
import { getNextApiString } from './helpers';

function* doGetNextCrossReactorSearch () {
  try {
    const next = yield select((s) => s.ingredientReducer.crossReactorsNext);
    const apiString = yield getNextApiString(next);
    const response = yield apiGet(apiString);

    yield put({
      type: Actions.CROSS_REACTOR_SEARCH_SUCCESS,
      response,
      paginated: true,
    });
  } catch (error) {
    yield put({
      type: Actions.CROSS_REACTOR_SEARCH_ERROR,
      error,
    });
  }
}

function* doGetSelectIngredientInfo ({ ingredient, isLookUp }) {
  let ingredientId;

  if(ingredient.id) {
    ingredientId = ingredient.id;
  } else {
    ingredientId = ingredient.ingredient_id;
  }

  try {
    const url = `api/ingredients/details/${ingredientId}`;
    const response = yield apiGet(url);
    yield put({
      type: Actions.SELECT_INGREDIENT_SUCCESSES,
      ingredient: _.merge(ingredient, response),
      isLookUp: !!isLookUp,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* ingredient () {
  yield takeEvery(Actions.SELECT_INGREDIENT, doGetSelectIngredientInfo);
}
