import * as _ from 'lodash';

import * as Actions from '../actions/account';

export const initialState = {
  codes: {},
  codesError: null,
  getCodesPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_REGISTRATION_CODES:
      return {
        ...state,
        getCodesPending: true,
        codesError: null,
        codes: {},
      };
    case Actions.GET_REGISTRATION_CODES_SUCCESS:
      return {
        ...state,
        getCodesPending: false,
        codesError: null,
        codes: {
          code1: _.get(action, 'codes.0.code1'),
          code2: _.get(action, 'codes.0.code2'),
        },
      };
    case Actions.GET_REGISTRATION_CODES_ERRORS:
      return {
        ...state,
        getCodesPending: false,
        codesError: action.error,
        codes: {},
      };
    case Actions.STOP_REGISTRATION_SYNC:
      return {
        ...state,
        getCodesPending: false,
      };
    case Actions.CLEAR_ERRORS:
      return {
        ...state,
        codesError: null,
      };
    default:
      return state;
  }
};
