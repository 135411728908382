import _ from "lodash";
import { toast } from "react-toastify";
import * as Actions from "../actions/code-reset";

export const initialState = {
  patientDetails: {},
  resetModalState: false,
  fetchingCodeSet: false,
  resettingCodeSet: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CODE_SET_DETAILS:
      return {
        ...state,
        patientDetails: {},
        fetchingCodeSet: true,
      };
    case Actions.GET_CODE_SET_DETAILS_SUCCESS: {
      const { user } = action;

      if (_.isEmpty(user)) {
        toast.error("No code sets has been found for the given codes", {
          bodyClassName: "toast-body",
          style: {
            backgroundColor: "#414B4F",
            color: "#ffffff",
          },
          closeButton: true,
          toastId: "code-set-not-found",
        });

        return {
          ...initialState,
        };
      }

      return {
        ...state,
        patientDetails: {
          ...user,
        },
        resetModalState: true,
        fetchingCodeSet: false,
      };
    }
    case Actions.RESET_CODE_SETS_SUCCESS: {
      toast.success("Code sets have been reset successfully", {
        position: "bottom-right",
        autoClose: 2000,
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#00c9b7",
        },
      });

      return {
        ...initialState,
      };
    }
    case Actions.RESET_CODE_RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
