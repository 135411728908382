import * as _ from 'lodash';
import { all, call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import toastr from 'toastr';

import { LOGIN_SUCCESS, SIGNUP_ERROR, SIGNUP_SUCCESS, apiGet, getUser } from 'acds-react-core';
import { AUTO_LOGGED_OUT } from 'acds-redux-core';
import {
  GET_REGISTRATION_CODES,
  GET_REGISTRATION_CODES_ERRORS,
  GET_REGISTRATION_CODES_SUCCESS,
  START_REGISTRATION_SYNC,
  STOP_REGISTRATION_SYNC,
} from '../actions/account';

function* doGetRegistrationCodes (action) {
  const { params, history } = action;

  try {
    const response = yield apiGet('api/codeset/', params);

    // If the response had an error status, but wasn't caught as an error
    if (!_.isNil(response.status) && response.status > 299) {
      yield put({
        type: GET_REGISTRATION_CODES_ERRORS,
        error: _.get(response, 'data'),
      });
    } else {
      // Confirm successful response, including codes
      if (_.has(response, 'results.0.code1') && _.has(response, 'results.0.code2')) {
        // Change route, while retaining history
        history.push('/registration');
      }

      yield put({
        type: GET_REGISTRATION_CODES_SUCCESS,
        codes: response.results,
      });
    }
  } catch (error) {
    yield put({
      type: GET_REGISTRATION_CODES_ERRORS,
      error: error.response.data,
    });
  }
}

function* doRegistrationSync () {
  while (true) {
    const action = yield take([LOGIN_SUCCESS, GET_REGISTRATION_CODES, SIGNUP_SUCCESS, SIGNUP_ERROR]);
    const user = yield select(getUser);

    // If there is a user, stop registration
    if (user || action.type === LOGIN_SUCCESS || action.type === SIGNUP_SUCCESS) {
      // Stop sync makes the registrationFlow (and this entire registration saga) stop
      yield take(STOP_REGISTRATION_SYNC);
    } else if (action.type === GET_REGISTRATION_CODES) {
      yield call(doGetRegistrationCodes, action);
    }
  }
}

const autoLogoutToast = () =>
  new Promise(() => {
    toastr.options.positionClass = 'toast-top-full-width';
    toastr.options.hideDuration = 5000;
    toastr.warning('Your session has expired due to inactivity.');
  });

export function* registrationFlow () {
  while (yield take(START_REGISTRATION_SYNC)) {
    // Starts the task in the background
    const registrationSyncTask = yield fork(doRegistrationSync);

    // Wait for registration to complete
    yield take(STOP_REGISTRATION_SYNC);

    // User logs in. Cancel the background task
    // this will cause the forked doRegistrationSync task to jump into its finally block
    yield cancel(registrationSyncTask);
  }
}

export default function* appAccountSagas () {
  yield all([registrationFlow(), takeLatest(AUTO_LOGGED_OUT, autoLogoutToast)]);
}
