import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getToken } from '../selectors/account';
import { getApiRoot, getTokenType } from '../selectors/env';

import * as _ from 'lodash';
import { LOGOUT } from '../actions/account';
import { UNAUTHORIZED_REQUEST } from '../constants';

const parseOptions = (options) => {
  let data = null;
  let customHeaders = undefined;
  let authenticationRequired = true;

  const hasData = _.has(options, 'data');
  const hasHeaders = _.has(options, 'headers');
  const hasAuthRequired = _.has(options, 'authenticationRequired');

  if (hasData || hasHeaders || hasAuthRequired) {
    if (hasData) {
      data = _.get(options, 'data');
    }

    if (hasHeaders) {
      customHeaders = _.get(options, 'headers');
    }

    if (hasAuthRequired) {
      authenticationRequired = _.get(options, 'authenticationRequired');
    }
  } else {
    data = options;
  }

  return { data, customHeaders, authenticationRequired };
};

export const validateStatus = () => true;

export function* getHeaders (options) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const authenticationRequired = _.get(options, 'authenticationRequired', true);
  const customHeaders = _.get(options, 'customHeaders', {});

  if (authenticationRequired) {
    const token = yield select(getToken);
    const token_type = yield select(getTokenType);

    if (token) {
      headers['Authorization'] = `${token_type} ${token}`;
    }
  }

  return _.merge({}, headers, customHeaders);
}

export function* apiPost (path, options) {
  const { data, customHeaders, authenticationRequired } = parseOptions(options);

  const headers = yield call(getHeaders, {
    authenticationRequired,
    customHeaders,
  });
  const apiRoot = yield select(getApiRoot);

  // Always return true so error status 4xx promises aren't rejected.
  const response = yield axios.post(`${apiRoot}/${path}`, data, {
    headers,
    validateStatus,
  });
  if (response.status === UNAUTHORIZED_REQUEST) {
    try {
      if (response.data.detail === 'Token has expired') {
        yield put({
          type: LOGOUT,
          message: 'session-timeout',
        });
        
        return;
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  if (response.status > 299) {
    return response;
  }

  return _.get(response, 'data', response);
}

export function* apiGet (path, options = {}) {
  const { data, customHeaders, authenticationRequired } = parseOptions(options);

  const headers = yield call(getHeaders, {
    authenticationRequired,
    customHeaders,
  });
  const apiRoot = yield select(getApiRoot);

  let url = `${apiRoot}/${path}`;

  if (!_.isEmpty(data)) {
    let queryString = Object.keys(data)
      .map((key) => key + '=' + data[key])
      .join('&');
    url = `${url}?${queryString}`;
  }

  // Always return true so error status 4xx promises aren't rejected.
  const response = yield axios.get(url, { headers, validateStatus });

  if (response.status === UNAUTHORIZED_REQUEST) {
    try {
      if (response.data.detail === 'Token has expired') {
        yield put({
          type: LOGOUT,
          message: 'session-timeout',
        });
      } else {
        yield put({
          type: LOGOUT,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  if (response.status > 299) {
    return response;
  }

  return _.get(response, 'data', response);
}

export function* apiPut (path, options) {
  const { data, customHeaders, authenticationRequired } = parseOptions(options);

  const headers = yield call(getHeaders, {
    authenticationRequired,
    customHeaders,
  });
  const apiRoot = yield select(getApiRoot);

  let url = `${apiRoot}/${path}`;

  // Always return true so error status 4xx promises aren't rejected.
  const response = yield axios.put(url, data, { headers, validateStatus });

  if (response.status === UNAUTHORIZED_REQUEST) {
    try {
      if (response.data.detail === 'Token has expired') {
        yield put({
          type: LOGOUT,
          message: 'session-timeout',
        });
      } else {
        yield put({
          type: LOGOUT,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  if (response.status > 299) {
    return response;
  }

  return _.get(response, 'data', response);
}

export function* apiPatch (path, options) {
  const { data, customHeaders, authenticationRequired } = parseOptions(options);

  const headers = yield call(getHeaders, {
    authenticationRequired,
    customHeaders,
  });
  const apiRoot = yield select(getApiRoot);

  let url = `${apiRoot}/${path}`;

  // Always return true so error status 4xx promises aren't rejected.
  const response = yield axios.patch(url, data, { headers, validateStatus });

  if (response.status === UNAUTHORIZED_REQUEST) {
    try {
      if (response.data.detail === 'Token has expired') {
        yield put({
          type: LOGOUT,
          message: 'session-timeout',
        });
      } else {
        yield put({
          type: LOGOUT,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  if (response.status > 299) {
    return response;
  }

  return _.get(response, 'data', response);
}

export function* apiDelete (path, authenticationRequired = true) {
  const headers = yield call(getHeaders, { authenticationRequired });
  const apiRoot = yield select(getApiRoot);

  let url = `${apiRoot}/${path}`;

  const response = yield axios.delete(url, { headers });

  if (response.status === UNAUTHORIZED_REQUEST) {
    try {
      if (response.data.detail === 'Token has expired') {
        yield put({
          type: LOGOUT,
          message: 'session-timeout',
        });
      } else {
        yield put({
          type: LOGOUT,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  if (response.status > 299) {
    return response;
  }

  return _.get(response, 'data', response);
}

const api = {
  login: (data) =>
    apiPost('api/login/', { data, authenticationRequired: false }),
  signup: (data) =>
    apiPost('api/signup/', { data, authenticationRequired: false }),
  patchUser: (data, headers, path = 'api/user/') => {
    return apiPatch(path, { data, headers });
  },
  loadUser: () => apiGet('api/user/'),
};

export default api;
