import { put, takeLatest } from 'redux-saga/effects';

import { apiGet } from 'acds-react-core';

import * as Actions from '../actions/learningCenter';

function* getIngredientsWithAllergenListAndDietarySheets (payload) {
  try {
    const { isPatient } = payload.payload;
    const apiString = `api/ingredients/allergen-information/?isPatient=${isPatient}`;
    const response = yield apiGet(apiString);
    yield put({
      type: Actions.GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS_ERROR,
      error: error.response,
    });
  }
}

export default function* learningCenterSagas () {
  yield takeLatest(
    Actions.GET_INGREDIENTS_WITH_ALLERGEN_NARATIVES_AND_DIETARYSHEETS,
    getIngredientsWithAllergenListAndDietarySheets,
  );
}
