import {createSelector} from 'reselect';
import * as _ from 'lodash';
import {format} from 'date-fns';

export const selectQualityOfLifeResults = (s) => s.qualityOfLife.qualityOfLifeResults;
export const selectQualityOfLifePending = (s) => s.qualityOfLife.qualityOfLifePending;
export const selectQualityOfLifeHighestScore = createSelector(
  selectQualityOfLifeResults,
  (results) => Math.max(...results.map((r) => _.get(r, 'total')), 0)
);
export const selectQualityOfLifeData = createSelector(
  selectQualityOfLifeResults,
  (results) => 
    _.map(results, (r) => {
      const resultDate = new Date(_.get(r, 'updated_at'));
      
      return {
        date: format(resultDate, 'MMM d, yy'),
        score: _.get(r, 'total'),
      };
    })
);
    
