// Get quality of life results
export const GET_QUALITY_OF_LIFE = 'QOL/GET_QUALITY_OF_LIFE';
export const GET_QUALITY_OF_LIFE_SUCCESS = 'QOL/GET_QUALITY_OF_LIFE_SUCCESS';
export const GET_QUALITY_OF_LIFE_ERRORS = 'QOL/GET_QUALITY_OF_LIFE_ERRORS';
export const GET_NEXT_QUALITY_OF_LIFE = 'QOL/GET_NEXT_QUALITY_OF_LIFE';
export const GET_NEXT_QUALITY_OF_LIFE_SUCCESS = 'QOL/GET_NEXT_QUALITY_OF_LIFE_SUCCESS';
// Post new quality of life entry
export const POST_ALL_QUALITY_OF_LIFE = 'QOL/POST_ALL_QUALITY_OF_LIFE';
export const POST_QUALITY_OF_LIFE = 'QOL/POST_QUALITY_OF_LIFE';
export const POST_QUALITY_OF_LIFE_SUCCESS = 'QOL/POST_QUALITY_OF_LIFE_SUCCESS';
export const POST_QUALITY_OF_LIFE_ERRORS = 'QOL/POST_QUALITY_OF_LIFE_ERRORS';

export const getQualityOfLife = () => {
  return {
    type: GET_QUALITY_OF_LIFE,
  };
};

export const getNextQualityOfLife = () => {
  return {
    type: GET_NEXT_QUALITY_OF_LIFE,
  };
};

export const postAllQualityOfLife = (params) => {
  return {
    type: POST_ALL_QUALITY_OF_LIFE,
    params,
  };
};

export const postQualityOfLife = (params) => {
  return {
    type: POST_QUALITY_OF_LIFE,
    params,
  };
};
