export const mantineTheme = {
  primaryColor: "teal",
  primaryShade: 0,
  colors: {
    teal: [
      "#00c9b7",
      "#1acebe",
      "#33d4c5",
      "#4dd9cd",
      "#66dfd4",
      "#80e4db",
      "#99e9e2",
      "#b3efe9",
      "#ccf4f1",
      "#e6faf8",
      "#ffffff",
    ],
    red: [
      "#eb5757",
      "#ed6868",
      "#ef7979",
      "##f18989",
      "##f39a9a",
      "#f5abab",
      "#f7bcbc",
      "#f9cdcd",
      "##fbdddd",
      "#fdeeee",
      "#ffffff",
    ],
    modifiedGray: [
      "#212529",
      "#343a40",
      "#495057",
      "#868e96",
      "#adb5bd",
      "#ced4da",
      "#dee2e6",
      "#e9ecef",
      "#f1f3f5",
      "#f8f9fa",
    ],
  },
  fontFamily: "Open Sans",
};
