import { apiGet } from 'acds-react-core';
import * as _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  CROSS_REACTOR_SEARCH_SUCCESS,
  INGREDIENT_FUZZY_SEARCH,
  INGREDIENT_SEARCH_SUCCESS,
} from '../actions/ingredient';
import {
  ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS,
  ALLERGEN_NARRATIVE_SEARCH_STATUS,
  ALLERGEN_NARRATIVE_SEARCH_SUCCESS,
} from '../actions/learning';
import { PHYSICIAN_PRODUCT_SEARCH_STATUS, PHYSICIAN_PRODUCT_SEARCH_SUCCESS, SEARCH_NEXT_CATEGORIES_PENDING } from '../actions/physician';
import {
  FAVORITES_SEARCH_SUCCESS,
  GET_CROSS_REACTORS,
  PRODUCT_ELASTIC_SEARCH_NEXT,
  PRODUCT_SEARCH_SUCCESS,
  SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS,
  UNSAFE_PRODUCT_SEARCH_SUCCESS,
  UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS,
} from '../actions/safeList';
import {
  CATEGORY_SEARCH_NEXT_PAGE,
  ELASTIC_SEARCH,
  GET_ALLERGEN_NARRATIVES_SEARCH,
  GET_PRODUCT_ELASTIC_SEARCH,
  NEXT_SEARCH,
  SEARCH,
  SEARCH_ERRORS,
  SEARCH_SUCCESS,
  SET_PRODUCT_INITIAL_LOAD,
  SET_SEARCH_STATUS,
} from '../actions/search';
import { getNextApiString } from './helpers';

function* doSearchResults ({
  response,
  searchRedux,
  paginated = false,
  searchValue = '',
}) {
  if (searchRedux == 'unsafe') {
    const productCount = yield select(
      (state) => state.safeListReducer.productCount
    );
    _.set(response, 'safeListCount', productCount);
  }

  yield put({
    type: SEARCH_SUCCESS,
    searchResults: response,
    paginated,
  });

  let actionType = PRODUCT_SEARCH_SUCCESS;
  switch (searchRedux) {
    case 'products':
      actionType = PRODUCT_SEARCH_SUCCESS;
      break;
    case 'unsafe':
      actionType = UNSAFE_PRODUCT_SEARCH_SUCCESS;
      break;
    case 'ingredients':
      actionType = INGREDIENT_SEARCH_SUCCESS;
      break;
    case 'crossReactor':
      actionType = CROSS_REACTOR_SEARCH_SUCCESS;
      break;
    case 'physician':
      actionType = PHYSICIAN_PRODUCT_SEARCH_SUCCESS;
      break;
    case 'narratives':
      actionType = ALLERGEN_NARRATIVE_SEARCH_SUCCESS;
      break;
    case 'favorites':
      actionType = FAVORITES_SEARCH_SUCCESS;
      break;
    case 'ingredientFuzzySearch':
      actionType = INGREDIENT_FUZZY_SEARCH;
      break;
    default:
      break;
  }

  yield put({
    type: actionType,
    response,
    searchValue,
    paginated,
  });
}

function createElasticSearchCategoryIDs (activeCategory) {
  const categoryIDs = [activeCategory.id];

  // recursively find the IDs of all the children
  const getChildrenIDs = (children = []) => {
    if (children.length === 0)
      return;

    children.forEach((child) => {
      categoryIDs.push(child.id);
      getChildrenIDs(child.children);
    });
  };

  getChildrenIDs(activeCategory.children);

  return categoryIDs;
}

function* doSearch (action) {
  const {
    params: { apiPath, filters, searchValue, searchRedux, sorting },
  } = action;

  try {
    const searchQuery = searchValue;
    let apiString = `${apiPath}/?`;
    let filterField = null;
    let filterString = null;
    let extraFilters = '';

    _.map(filters, (filterValue, filterKey) => {
      if (filterKey === 'filterField') {
        filterField = filterValue;
      } else if (filterKey === 'filterString') {
        filterString = filterValue;
      } else {
        extraFilters = `${extraFilters}&${filterKey}=${filterValue}`;
      }
    });

    // If there is no search string or filters, search api path only to return all results.
    // Used for browsing all products by name
    if (!searchQuery && !filterField) {
      // If there are filters (such as category)
    } else {
      if (!_.isNull(filterField) && !_.isNull(filterString)) {
        const filterQueryString = `filterField=${filterField}&filterString=${filterString}`;

        // If there is no search term
        apiString = _.isEmpty(searchValue)
          ? // Search filters only, not string
        // This is used to move through categories without search words
          `${apiPath}/?${filterQueryString}`
          : // If there is a search string, add filters to the original search string apiString
          `${apiString}&${filterQueryString}`;
      }

      // Add sorting to all search and filtering queries.
      apiString = `${apiString}&sortBy=${sorting || 'name'}`;
    }

    let response = yield apiGet(
      `${apiString}${extraFilters}${
        searchRedux === 'unsafe' ? '&unsafe=true' : ''
      }`
    );

    yield call(doSearchResults, {
      response,
      searchRedux,
      paginated: false,
      searchValue,
    });
  } catch (error) {
    yield put({
      type: SEARCH_ERRORS,
      error: _.get(error, 'response.data'),
    });

    if(searchRedux === 'physician') {
      yield put ({
        type: PHYSICIAN_PRODUCT_SEARCH_STATUS,
        status: false,
      });
    }
  }
}

function* doCategorySearchNextPage (action) {
  const {
    params: { apiPath, filters, searchRedux },
  } = action;
  let apiString = `${apiPath}/?`;
  let extraFilters = '';

  _.map(filters, (filterValue, filterKey) => {
    if (filterKey === 'filterField') {
      filterField = filterValue;
    } else if (filterKey === 'filterString') {
      filterString = filterValue;
    } else {
      extraFilters = `${extraFilters}&${filterKey}=${filterValue}`;
    }
  });

  try {
    yield put({
      type: SEARCH_NEXT_CATEGORIES_PENDING,
    });

    let response = yield apiGet(`${apiString}${extraFilters}`);

    yield put({
      type: PHYSICIAN_PRODUCT_SEARCH_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: SEARCH_ERRORS,
      error: _.get(error, 'response.data'),
    });

    if(searchRedux === 'physician') {
      yield put ({
        type: PHYSICIAN_PRODUCT_SEARCH_STATUS,
        status: false,
      });
    }
  }
}

function* doNextSearch (action) {
  const {
    params: { next, searchRedux },
  } = action;

  try {
    const apiString = yield getNextApiString(next);
    const response = yield apiGet(apiString);

    yield call(doSearchResults, {
      response,
      searchRedux,
      paginated: true,
    });
  } catch (error) {
    yield put({
      type: SEARCH_ERRORS,
      error: _.get(error, 'response.data'),
    });
  }
}

function* doElasticSearch (action) {
  const { params } = action;
  const { queryParams, searchRedux, paginated, searchIndex } = params;
  try {
    const apiRoot = 'api/search/' +searchIndex + '?';

    let params;
    let apiPath;

    if (queryParams) {
      params = new URLSearchParams(queryParams);
    }

    if (params !== undefined) {
      apiPath = apiRoot + params;
    } else {
      apiPath = apiRoot;
    }

    const response = yield apiGet(apiPath);
    console.log('apiPath', apiPath);
    // TODO: try to transform the data in the api
    const transformedData = {
      hits: {
        total: _.get(response, 'hits.total.value', 0),
        hits: _.map(response.hits.hits, (hit) => {
          return {
            ..._.pick(hit._source, ['id', 'name', 'is_common', 'is_allergen']),
            label: _.get(hit._source, 'name'),
            value: String(_.get(hit._source, 'id')),
          };
        }),
      },
    };
    yield call(
      doSearchResults,
      ({
        response: transformedData,
        searchRedux,
        paginated: paginated ? true : false,
      })
    );

  } catch (error) {
    yield put({
      type: SEARCH_ERRORS,
      error: _.get(error, 'response.data'),
    });
  }
}

function* doElasticProductSearch (action) {
  // applicable only for web

  yield put({type: SET_SEARCH_STATUS,  status: true });
  yield put({type: SET_PRODUCT_INITIAL_LOAD,  status: true });

  const  { params } = action;

  const apiDomain = yield select((state) => state.env.api_root);
  const activeCategory = yield select((state) => state.safeList.activeTab);
  const sortBy = yield select((state) => state.safeListReducer.sorting);

  const categoryIDs = createElasticSearchCategoryIDs(activeCategory);

  const unsafe = _.get(params, 'filters.unsafe', false);
  const favorites = _.get(params, 'filters.favorites', false);
  const searchValue = yield select((state) => state.search.productSearchTxt);

  const baseURL = new URL(`${apiDomain}/api/search/product`);

  if (searchValue.length > 0) {
    baseURL.searchParams.append('name', searchValue);
  }

  if (sortBy === 'brand') {
    baseURL.searchParams.append('sort_by', 'brand');
  } else if (sortBy === 'updated_at') {
    baseURL.searchParams.append('sort_by', 'updated_at');
  } else if (sortBy === 'name') {
    baseURL.searchParams.append('sort_by', 'name');
  } else {
    baseURL.searchParams.append('sort_by', 'name');
  }

  if (favorites) {
    baseURL.searchParams.append('favorites_only', favorites);
  }

  if (unsafe) {
    baseURL.searchParams.append('unsafe', unsafe);
  }

  baseURL.searchParams.append('limit', 20);
  baseURL.searchParams.append('fuzzy', true);

  let url = _.replace(baseURL.href, `${apiDomain}/`, '');

  if (activeCategory.name !== 'All Categories') {
    url = url + `&category_id=${categoryIDs.join(',')}`;
  }

  try {
    const response = yield apiGet(url);
    const formattedResults = _.map(
      _.get(response, 'results', []),
      function (item) {
        const images = _.get(item, '_source.image', []);

        return {
          id: _.get(item, '_source.id'),
          name: _.get(item, '_source.name'),
          brand: _.get(item, '_source.brand.name', ''),
          images: _.map(images, 'thumbnail'),
          is_favorite: _.get(item, '_source.is_favorite', false),
          size: _.get(item, '_source.size', ''),
          imageUrls: _.isEmpty(images) ? [] : images,
        };
      },
    );
    response.results = formattedResults;
    if(unsafe) {
      yield put({
        type: UNSAFE_PRODUCT_SEARCH_SUCCESS,
        response,
      });
    } else {
      yield put({
        type: PRODUCT_SEARCH_SUCCESS,
        response,
      });
    }
    yield put({type: SET_SEARCH_STATUS,  status: false });
    yield put({type: SET_PRODUCT_INITIAL_LOAD,  status: false });

  } catch (error) {
    console.log(error);
    yield put({type: SET_SEARCH_STATUS,  status: false });
    yield put({type: SET_PRODUCT_INITIAL_LOAD,  status: false });
  }
}

function* doNextElasticProductSearch (action) {
  // applicable only for web

  const isInitialLoad = yield select((state) => state.search.productInitialLoad);

  const  { params } = action;

  const apiDomain = yield select((state) => state.env.api_root);
  const activeCategory = yield select((state) => state.safeList.activeTab);
  const sortBy = yield select((state) => state.safeListReducer.sorting);

  const categoryIDs = createElasticSearchCategoryIDs(activeCategory);

  const unsafe = _.get(params, 'filters.unsafe', false);
  const favorites = _.get(params, 'filters.favorites', false);
  const searchValue = yield select((state) => state.search.productSearchTxt);

  const baseURL = new URL(`${apiDomain}/api/search/product`);

  if (searchValue.length > 0) {
    baseURL.searchParams.append('name', searchValue);
  }

  if (sortBy === 'brand') {
    baseURL.searchParams.append('sort_by', 'brand');
  } else if (sortBy === 'updated_at') {
    baseURL.searchParams.append('sort_by', 'updated_at');
  } else if (sortBy === 'name') {
    baseURL.searchParams.append('sort_by', 'name');
  } else {
    baseURL.searchParams.append('sort_by', 'name');
  }

  if (favorites) {
    baseURL.searchParams.append('favorites_only', favorites);
  }

  if (unsafe) {
    baseURL.searchParams.append('unsafe', unsafe);
  }

  baseURL.searchParams.append('limit', 20);
  baseURL.searchParams.append('fuzzy', true);

  if(unsafe) {
    const productCount = yield select((state) => state.safeListReducer.unsafeProducts);
    baseURL.searchParams.append('offset', productCount.length);
  } else {
    const productCount = yield select((state) => state.safeListReducer.products);
    baseURL.searchParams.append('offset', productCount.length);
  }

  let url = _.replace(baseURL.href, `${apiDomain}/`, '');

  if (activeCategory.name !== 'All Categories') {
    url = url + `&category_id=${categoryIDs.join(',')}`;
  }

  try {
    const response = yield apiGet(url);
    const formattedResults = _.map(
      _.get(response, 'results', []),
      function (item) {
        const images = _.get(item, '_source.image', []);

        return {
          id: _.get(item, '_source.id'),
          name: _.get(item, '_source.name'),
          brand: _.get(item, '_source.brand.name', ''),
          images: _.map(images, 'thumbnail'),
          is_favorite: _.get(item, '_source.is_favorite', false),
          size: _.get(item, '_source.size', ''),
          imageUrls: _.isEmpty(images) ? [] : images,
        };
      },
    );
    response.results = formattedResults;

    if(isInitialLoad) {
      return;
    }

    if(unsafe) {
      yield put({
        type: UN_SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS,
        response,
      });
    } else {
      yield put({
        type: SAFE_PRODUCT_ELASTIC_SEARCH_NEXT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* doGetCrossReactorSearch (action) {
  try {
    const apiRoot = yield select((state) => state.env.api_root);
    const crossReactor = yield select ((state) => state.ingredientReducer.crossReactors);
    const crossReactorCount = yield select ((state) => state.ingredientReducer.crossReactorsCount);

    const params = _.get(action, 'params', {});

    const api = 'api/search/cross-reactor';

    const url = new URL(api, apiRoot);

    const searchValue = _.get(params, 'searchValue', '');
    const paginated = _.get(params, 'paginated', false);

    if(searchValue.length > 0 ) {
      url.searchParams.append('name', params.searchValue);
    }

    if(paginated) {
      if(crossReactorCount === crossReactor.length) {
        return;
      }
      url.searchParams.append('offset', crossReactor.length);
    }

    url.searchParams.append('fuzzy', true);

    const response = yield apiGet(_.replace(url, apiRoot+'/',''));

    const formattedResult = _.map(_.get(response, 'results'), function (item) {
      return _.pick(item._source, ['name', 'ingredient']);
    });

    yield put({
      type: CROSS_REACTOR_SEARCH_SUCCESS,
      formattedResult,
      count: _.get(response, 'count', 0),
      paginated,
    });

  } catch (error) {
    console.log(error);
  }
}

function* doGetAllergenNarrativeSearch (action) {

  try {

    const apiRoot = yield select((state) => state.env.api_root);
    const allergenNarrative = yield select((state)=> state.learningReducer.allergenNarratives);

    const params = _.get(action, 'params', {});
    const searchValue = _.get(params, 'searchValue', '');
    const isPatient = _.get(params, 'isPatient', false);
    const paginated = _.get(params, 'paginated', false);

    if(!paginated) {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS,
        status: true,
      });
    } else {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_STATUS,
        status: true,
      });
    }

    const api = 'api/search/allergen-information';

    const url = new URL(api, apiRoot);

    if(searchValue.length > 0 ) {
      url.searchParams.append('name', params.searchValue);
    }

    if(isPatient) {
      // url.searchParams.append('isPatient', isPatient);
    }

    if(paginated) {
      url.searchParams.append('offset', allergenNarrative.length);
    }

    url.searchParams.append('fuzzy', true);
    url.searchParams.append('sort_by', 'name');

    const response = yield apiGet(_.replace(url, apiRoot+'/',''));

    const formattedResult = _.map(_.get(response, 'results'), function (item) {
      return _.pick(item._source, ['id', 'name', 'dietary_sheet', 'allergen_narrative']);
    });

    yield put({
      type: ALLERGEN_NARRATIVE_SEARCH_SUCCESS,
      formattedResult,
      count: _.get(response, 'count', 0),
      paginated,
    });

    if(!paginated) {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS,
        status: false,
      });
    } else {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_STATUS,
        status: false,
      });
    }

  } catch (error) {
    console.log(error);
    const paginated = _.get(params, 'paginated', false);

    if(paginated) {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_INITIAL_STATUS,
        status: false,
      });
    } else {
      yield put({
        type: ALLERGEN_NARRATIVE_SEARCH_STATUS,
        status: false,
      });
    }

  }
}

export default function* searchSagas () {
  yield takeLatest(SEARCH, doSearch);
  yield takeLatest(CATEGORY_SEARCH_NEXT_PAGE, doCategorySearchNextPage);
  yield takeLatest(NEXT_SEARCH, doNextSearch);
  yield takeLatest(ELASTIC_SEARCH, doElasticSearch);
  yield takeLatest(GET_PRODUCT_ELASTIC_SEARCH, doElasticProductSearch);
  yield takeLatest(PRODUCT_ELASTIC_SEARCH_NEXT, doNextElasticProductSearch);
  yield takeLatest(GET_CROSS_REACTORS, doGetCrossReactorSearch);
  yield takeLatest(GET_ALLERGEN_NARRATIVES_SEARCH, doGetAllergenNarrativeSearch);
}
