import { apiGet, apiPatch, apiPut } from "acds-react-core";
import _ from "lodash";
import { toast } from "react-toastify";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  BULK_UPDATE_INGREDIENT,
  BULK_UPDATE_INGREDIENT_ERROR,
  BULK_UPDATE_INGREDIENT_SUCCESS,
  BULK_DELETE_INGREDIENT,
  BULK_DELETE_INGREDIENT_SUCCESS,
  BULK_DELETE_INGREDIENT_ERROR,
  GET_INGREDIENTS,
  GET_INGREDIENTS_DATE_RANGE,
  GET_INGREDIENTS_DATE_RANGE_ERROR,
  GET_INGREDIENTS_DATE_RANGE_SUCCESS,
  GET_INGREDIENTS_ERROR,
  GET_INGREDIENTS_SUCCESS,
  GET_SELECTED_INGREDIENT,
  GET_SELECTED_INGREDIENT_SUCCESS,
  REPLACE_SELECTED_INGREDIENT_SUGGESTION,
  REPLACE_SELECTED_INGREDIENT_SUGGESTION_ERROR,
  REPLACE_SELECTED_INGREDIENT_SUGGESTION_SUCCESS,
  UPDATE_INGREDIENT,
  UPDATE_INGREDIENT_ERROR,
  UPDATE_INGREDIENT_SUCCESS,
  GET_CROSS_REACTORS_LIST,
  GET_CROSS_REACTORS_LIST_SUCCESS,
  GET_CROSS_REACTORS_LIST_ERROR
} from "../actions/ingredientReview";

function* doGetIngredientsLastUpdatedDate(payload) {
  const status = _.get(payload, "status", 1);
  const apiUrl = `api/ingredients/last_updated_date/?status=${status}&is_label_insight_import=true`;
  try {
    const response = yield apiGet(apiUrl);
    const dateResponse = {
      from: response.from_date,
      to: response.to_date,
    };
    yield put({
      type: GET_INGREDIENTS_DATE_RANGE_SUCCESS,
      dateResponse,
    });
  } catch (error) {
    yield put({
      type: GET_INGREDIENTS_DATE_RANGE_ERROR,
      error,
    });
    console.error(error);
  }
}

function* doGetIngredients({ filters }) {
  const queryParams = Object.keys(filters)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(filters[key]))
    .join("&");

  const apiString = `api/ingredients/?${queryParams}`;

  try {
    const response = yield apiGet(apiString);

    yield put({
      type: GET_INGREDIENTS_SUCCESS,
      response,
    });

    if (_.has(filters, "start_date")) {
      const dateResponse = {
        from: filters.start_date,
        to: filters.end_date,
      };
      yield put({
        type: GET_INGREDIENTS_DATE_RANGE_SUCCESS,
        dateResponse,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: GET_INGREDIENTS_ERROR,
      error,
    });
  }
}

function* doIngredientReviewAction({ reviewAction }) {
  const id = reviewAction.id;
  const status = reviewAction.status;
  const crossReactor = reviewAction.crossReactor;
  const payload = { status };
  const apiString = `api/ingredients/${id}/`;
  const crossReactorsToExclude = yield select((state) => state.ingredientReviews.crossReactorsToExclude);
  const fragranceToExclude = yield select((state) => state.ingredientReviews.fragranceToExclude);
  const currentFilters = yield select((state) => state.ingredientReviews.currentFilters);
  const crossReactorsToInclude = yield select((state) => state.ingredientReviews.crossReactorsToInclude);

  if (status === 0) {
    const ingredientCrossReactors = _.map(crossReactor, "id");

    if (ingredientCrossReactors.length) {
      if (!_.has(crossReactorsToExclude, id)) {
        payload.cross_reactors = ingredientCrossReactors;
      } else {
        payload.cross_reactors = _.difference(ingredientCrossReactors, _.get(crossReactorsToExclude, id));
      }
    }

    if (fragranceToExclude.includes(id)) {
      payload.fragrance = {};
    }

    if (crossReactorsToInclude && crossReactorsToInclude[id]) {
      const newCrossReactors = crossReactorsToInclude[id].map(item => item.id).filter(item => typeof item === "number")
      if (payload.cross_reactors?.length) {
        payload.cross_reactors = [...payload.cross_reactors, ...newCrossReactors]
      } else {
        payload.cross_reactors = newCrossReactors
      }

      const fragRestrictiveFound = crossReactorsToInclude[id].findIndex(item => item.id === "frag-restrictive") > -1
      const fragStandardFound = crossReactorsToInclude[id].findIndex(item => item.id === "frag-standard") > -1

      if (fragRestrictiveFound) {
        payload.fragrance = {
          restrictive: true
        }
      }

      else if (fragStandardFound) {
        payload.fragrance = {
          restrictive: false
        }
      }
    }
  }

  try {
    const response = yield apiPatch(apiString, payload);

    if (response.status >= 400) {
      throw response.data;
    }

    const statusMessages = {
      0: "Ingredient published",
      2: "Ingredient deleted",
      3: "Ingredient Restored",
    };

    const statusColor = {
      0: "#00c9b7",
      2: "#E74C3C",
      3: "#00c9b7",
    };

    const statusMessageType = {
      0: "success",
      2: "error",
      3: "success",
    };

    const toastMessage = statusMessages[status];
    const toastBackground = statusColor[status];
    const toastMessageType = statusMessageType[status];

    yield put({
      type: UPDATE_INGREDIENT_SUCCESS,
      ingredientId: id,
    });

    toast[toastMessageType](toastMessage, {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: toastBackground,
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });

    yield put({
      type: GET_INGREDIENTS,
      filters: currentFilters,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: UPDATE_INGREDIENT_ERROR,
    });
    toast.error("An error occurred while updating the data. Please try again later.", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#E74C3C",
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });
  }
}

function* doIngredientBulkUpdate({ status, selectedIngredients }) {
  const apiString = "api/ingredients/bulk_update/";

  const selectedIngredientsIds = Object.keys(selectedIngredients);

  const crossReactorsToExclude = yield select((state) => state.ingredientReviews.crossReactorsToExclude);
  const ingredients = yield select((state) => state.ingredientReviews.ingredients);
  const currentFilters = yield select((state) => state.ingredientReviews.currentFilters);
  const fragranceToExclude = yield select((state) => state.ingredientReviews.fragranceToExclude);

  const data = _.map(selectedIngredientsIds, (ingredientId) => {
    const ingredientData = {
      id: ingredientId,
      status,
    };

    if (_.has(crossReactorsToExclude, ingredientId)) {
      const excludedCrossReactorsId = _.get(crossReactorsToExclude, ingredientId, []);
      if (excludedCrossReactorsId.length > 0) {
        const ingredient = _.find(ingredients, ["id", parseInt(ingredientId)]);

        const ingredientCrossReactors = _.get(ingredient, "cross_reactors", []);
        const ingredientCrossReactorIds = _.map(ingredientCrossReactors, "id");

        ingredientData.cross_reactors = _.difference(ingredientCrossReactorIds, excludedCrossReactorsId);
      }
    }

    if (fragranceToExclude.includes(parseInt(ingredientId))) {
      ingredientData.fragrance = {};
    }

    return ingredientData;
  });

  try {
    const response = yield apiPatch(apiString, data);

    if (response.status >= 400) {
      throw response.data;
    }

    const toastMessage = status === 0 ? "Published selected ingredients" : "Rejected selected ingredients";
    const toastBackground = status === 0 ? "#00c9b7" : "#E74C3C";
    const toastMessageType = status === 0 ? "success" : "error";

    yield put({
      type: BULK_UPDATE_INGREDIENT_SUCCESS,
      selectedIngredientsIds: _.map(selectedIngredientsIds, (ingredientId) => parseInt(ingredientId)),
    });

    toast[toastMessageType](toastMessage, {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: toastBackground,
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });

    yield put({
      type: GET_INGREDIENTS,
      filters: currentFilters,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: BULK_UPDATE_INGREDIENT_ERROR,
    });

    toast.error("An error occurred while updating the data. Please try again later.", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#E74C3C",
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });
  }
}

function* doIngredientBulkDelete({ status, selectedIngredients }) {
  const apiString = "api/ingredients/bulk_update/";

  const selectedIngredientsIds = Object.keys(selectedIngredients);
  const currentFilters = yield select((state) => state.ingredientReviews.currentFilters);

  const data = _.map(selectedIngredientsIds, (ingredientId) => {
    return {
      id: ingredientId,
      status
    }
  })

  try {
    const response = yield apiPatch(apiString, data);

    if (response.status >= 400) {
      throw response.data;
    }

    yield put({
      type: BULK_DELETE_INGREDIENT_SUCCESS,
      selectedIngredientsIds: _.map(selectedIngredientsIds, (ingredientId) => parseInt(ingredientId)),
    });

    toast.success("Deleted selected ingredients", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });

    yield put({
      type: GET_INGREDIENTS,
      filters: currentFilters,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: BULK_DELETE_INGREDIENT_ERROR,
    });

    toast.error("An error occurred while updating the data. Please try again later.", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#E74C3C",
      },
      closeButton: true,
      position: "top-right",
      autoClose: 2000,
    });
  }
}

function* doGetSelectedIngredientSuggestion({ ingredientId }) {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("id", ingredientId);
  const apiString = "api/ingredients/possible_matches/?" + urlSearchParams.toString();
  try {
    const data = yield apiGet(apiString);
    yield put({
      type: GET_SELECTED_INGREDIENT_SUCCESS,
      data,
    });
  } catch (error) {}
}

function* doReplaceSelectedIngredientSuggestion({ ingredientId, suggestedIngredients }) {
  const apiString = `api/ingredients/${ingredientId}/replace/`;

  try {
    const data = yield apiPut(apiString, suggestedIngredients);
    yield put({
      type: REPLACE_SELECTED_INGREDIENT_SUGGESTION_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: REPLACE_SELECTED_INGREDIENT_SUGGESTION_ERROR,
      error,
    });
  }
}

function* doRefetchIngredients() {
  const currentFilters = yield select((state) => state.ingredientReviews.currentFilters);
  try {
    yield put({
      type: GET_INGREDIENTS,
      filters: currentFilters,
    });
  } catch (error) {}
}

function* dofetchCrossReactorsList() {
  const apiString = `api/ingredients/cross-reactor-list/`
  try {
    const data = yield apiGet(apiString);
    yield put({
      type: GET_CROSS_REACTORS_LIST_SUCCESS,
      data
    })
  } catch (error) {
    yield put({
      type: GET_CROSS_REACTORS_LIST_ERROR,
      error
    })
  }
}

export default function* ingredientReviewsSagas() {
  yield takeLatest(GET_INGREDIENTS_DATE_RANGE, doGetIngredientsLastUpdatedDate);
  yield takeLatest(GET_INGREDIENTS, doGetIngredients);
  yield takeEvery(UPDATE_INGREDIENT, doIngredientReviewAction);
  yield takeLatest(BULK_UPDATE_INGREDIENT, doIngredientBulkUpdate);
  yield takeLatest(BULK_DELETE_INGREDIENT, doIngredientBulkDelete);
  yield takeLatest(GET_SELECTED_INGREDIENT, doGetSelectedIngredientSuggestion);
  yield takeLatest(REPLACE_SELECTED_INGREDIENT_SUGGESTION, doReplaceSelectedIngredientSuggestion);
  yield takeLatest(REPLACE_SELECTED_INGREDIENT_SUGGESTION_SUCCESS, doRefetchIngredients);
  yield takeLatest(GET_CROSS_REACTORS_LIST, dofetchCrossReactorsList);
}
