import {
  all,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import * as _ from 'lodash';
import {apiGet, apiPost} from 'acds-react-core';

import { getNextApiString } from './helpers';
import * as Actions from '../actions/qualityOfLife';

function* doGetQualityOfLife () {
  try {
    const response = yield apiGet('api/users/qol-user-answer/');
  
    yield put({
      type: Actions.GET_QUALITY_OF_LIFE_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_QUALITY_OF_LIFE_ERRORS,
      error: error.response.data,
    });
  }
}

function* doGetNextQualityOfLife () {
  try {
    const next = yield select((s) => s.qualityOfLife.qualityOfLifeNext);
    const apiString = yield getNextApiString(next);
    const response = yield apiGet(apiString);
  
    yield put({
      type: Actions.GET_NEXT_QUALITY_OF_LIFE_SUCCESS,
      response,
      paginated: true,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_QUALITY_OF_LIFE_ERRORS,
      error: error.response.data,
    });
  }
}

function* doPostQualityOfLife (action) {
  const {params} = action;

  try {
    const response = yield apiPost('api/users/user-answer/', params);

    yield put({
      type: Actions.POST_QUALITY_OF_LIFE_SUCCESS,
      response,
    });

    // Call get action to update all associated quality of life selections
    yield put({
      type: Actions.GET_QUALITY_OF_LIFE,
    });
  } catch (error) {
    yield put({
      type: Actions.POST_QUALITY_OF_LIFE_ERRORS,
      error: error.response.data,
    });
  }
}

function* doPostAllQualityOfLife (action) {
  const {params} = action;
  
  yield all(
    _.map(
      params,
      (param, key) => put({
        type: Actions.POST_QUALITY_OF_LIFE,
        params: {
          question: key,
          rating: _.isUndefined(param) ? '3' : param,
        },
      })
    ),
  );
}

export default function* qualityOfLifeSagas () {
  yield takeLatest(Actions.GET_QUALITY_OF_LIFE, doGetQualityOfLife);
  yield takeLatest(Actions.GET_NEXT_QUALITY_OF_LIFE, doGetNextQualityOfLife);
  yield takeLatest(Actions.POST_ALL_QUALITY_OF_LIFE, doPostAllQualityOfLife);
  yield takeLatest(Actions.POST_QUALITY_OF_LIFE, doPostQualityOfLife);
}
