import { LOGOUT, accountReducer as account, envReducer as env } from "acds-react-core";
import {
  ingredientReducer,
  learningReducer,
  notificationSettingsReducer as notificationSettings,
  physicianReducer,
  qualityOfLifeReducer as qualityOfLife,
  questionsReducer,
  safeListReducer,
  searchReducer as search,
} from "acds-redux-core";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { default as appAccount } from "./reducers/account";
import { default as appUI } from "./reducers/app-ui";
import { default as resetCodeSet } from "./reducers/code-reset";
import { default as commentReviews } from "./reducers/commentReview";
import { default as ingredientReviews } from "./reducers/ingredientReview";
import { default as learningCenter } from "./reducers/learningCenter";
import { default as notifications } from "./reducers/notifications";
import { default as physician } from "./reducers/physician";
import { default as plotDetails } from "./reducers/plot";
import { default as productReviews } from "./reducers/productReviews";
import { default as profile } from "./reducers/profile";
import { default as safeList } from "./reducers/safeList";

import { toast } from "react-toastify";
import rootSaga from "./sagas";

export { default as icon } from "../assets/images/ACDS-Logo.png";

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  // APAX React Core
  account,
  env,
  // ACDS Redux Core
  ingredientReducer,
  learningReducer,
  physicianReducer,
  safeListReducer,
  search,
  qualityOfLife,
  questionsReducer,
  notificationSettings,
  // App Reducers
  appAccount,
  profile,
  safeList,
  physician,
  notifications,
  appUI,
  learningCenter,
  plotDetails,
  productReviews,
  commentReviews,
  ingredientReviews,
  resetCodeSet,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    if (action.message === "session-timeout") {
      toast.error("Your session has ended. Kindly log in again to continue.", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        closeButton: true,
        toastId: "session-timeout",
      });
    }

    const { env } = state;

    state = {
      env,
    };
  }

  return appReducer(state, action);
};

const composeEnhancers =
  (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

export const store = createStore(rootReducer, enhancers);

sagaMiddleware.run(rootSaga);

export default store;
