import * as _ from "lodash";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, userTypes, ...rest }) => {
  const user = useSelector((state) => state.account.user);
  const userLoading = useSelector((state) => state.account.userLoading);
  const history = useHistory();

  if (_.isEmpty(user) && !userLoading) {
    history.push("/login");
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        let userGroup = [];
        if (_.get(user, "groups")) {
          const userGroupList = _.get(user, "groups", [{ name: "anonymous" }]);
          _.map(userGroupList, (userList) => {
            userGroup.push(userList.name);
          });
        } else if (!!user) {
          userGroup = "patient";
        }

        const groupRedirects = {
          anonymous: <Redirect to="/login" />,
          acds: <Redirect to="/safe-list" />,
          patient: <Redirect to={_.get(user, "show_tutorial", false) ? "/onboarding" : "/welcome"} />,
          physician: <Redirect to="/allergen" />,
          industry: <Redirect to="/allergen" />,
          comment_review: <Redirect to="/comment-reviews" />,
          product_review: <Redirect to="/products-reviews" />,
          analytics: <Redirect to="/analytics" />,
          ingredient_review: <Redirect to="/ingredients-review" />,
        };

        const userRedirect = groupRedirects[userGroup[0]];
        const hasPermission =
          _.findIndex(userTypes, (userType) => userGroup.includes(userType) || userType === "user") !== -1 ||
          (userTypes === "user" && !userGroup.includes("anonymous")) ||
          userGroup.includes("acds");

        return !userLoading && (hasPermission ? <Suspense fallback={<div></div>}><Component {...props} /></Suspense> : userRedirect);
      }}
    />
  );
};
export default PrivateRoute;
