import * as _ from 'lodash';

import * as Actions from '../actions/questions';

const initialState = {
  categories: null,
  getQuestionCategoriesPending: false,
  getQuestionsPending: false,
  questionCategoriesErrors: null,
  questionErrors: null,
  questionList: null,
  patchAnswersPending: false,
  patchAnswersErrors: null,
  postAnswersPending: false,
  postAnswersErrors: null,
  userAnswers: null,
  userAnswersErrors: null,
  getAnswersPending: false,
};

const addAnswerName = (userAnswers) => {
  return _.map(
    userAnswers,
    (userAnswer) => {
      return {
        ...userAnswer,
        name: !_.isEmpty(userAnswer.answer) ? userAnswer.answer : userAnswer.answer_choices,
      };
    },
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_QUESTIONS:
      return {
        ...state,
        getQuestionsPending: true,
      };
    case Actions.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        getQuestionsPending: false,
        questionList: _.forEach(
          _.groupBy(_.get(action, 'response.results'), 'category.category'),
          (r,v,k) => ({
            category: k,
            questions: v,
          }),
        ),
      };
    case Actions.GET_QUESTIONS_ERRORS:
      return {
        ...state,
        getQuestionsPending: false,
        questionErrors: action.error,
      };
    case Actions.GET_QUESTION_CATEGORIES:
      return {
        ...state,
        getQuestionCategoriesPending: true,
      };
    case Actions.GET_QUESTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        getQuestionCategoriesPending: false,
        categories: _.get(action, 'response.results'),
      };
    case Actions.GET_QUESTION_CATEGORIES_ERRORS:
      return {
        ...state,
        getQuestionCategoriesPending: false,
        questionCategoriesErrors: action.error,
      };
    case Actions.STOP_QUESTIONS_SYNC:
      return {
        ...state,
        getQuestionsPending: false,
      };
    case Actions.GET_USER_ANSWERS_SUCCESS:
      return {
        ...state,
        userAnswers: addAnswerName(_.get(action, 'response.results')),
        getAnswersPending: false,
      };
    case Actions.GET_USER_ANSWERS_ERRORS:
      return {
        ...state,
        userAnswersErrors: action.error,
        getAnswersPending: false,
      };
    case Actions.POST_USER_ANSWERS:
      return {
        ...state,
        postAnswersPending: true,
      };
    case Actions.POST_USER_ANSWERS_SUCCESS:
      return {
        ...state,
        postAnswersPending: false,
        userAnswers: _.isNull(state.userAnswers) ?
          addAnswerName([action.response]) :
          addAnswerName([..._.clone(state.userAnswers), action.response]),
      };
    case Actions.POST_USER_ANSWERS_ERRORS:
      return {
        ...state,
        postAnswersPending: false,
        postAnswersErrors: action.error,
      };
    case Actions.PATCH_USER_ANSWERS:
      return {
        ...state,
        patchAnswersPending: true,
      };
    case Actions.PATCH_USER_ANSWERS_SUCCESS:
      return {
        ...state,
        patchAnswersPending: false,
        userAnswers: addAnswerName(
          // Map through existing user answers. 
          // If the response ID already exists, replace that object; otherwise return current state.
          _.map(
            _.clone(state.userAnswers),
            (ans) => ans.id === _.get(action, 'response.id') ? action.response : ans,
          ),
        ),
      };
    case Actions.PATCH_USER_ANSWERS_ERRORS:
      return {
        ...state,
        patchAnswersPending: false,
        patchAnswersErrors: action.error,
      };
    case Actions.GET_USER_ANSWERS_STATUS:
      return {
        ...state,
        getAnswersPending: action.isLoading,
      };
    default:
      return state;
  }
};
