import axios from 'axios';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  take,
} from 'redux-saga/effects';
import * as _ from 'lodash';

import { LOGOUT } from 'acds-react-core';

import {
  AUTO_LOGGED_OUT,
} from '../actions/account';

function axiosInterceptExpiredToken () {
  // Use an eventChannel to integrate functions with funciton generators
  return eventChannel((emitter) => {
    const iv = axios.interceptors.response.use(undefined, (erroneous) => {
      const message = _.get(erroneous, 'response.data.detail');
      if (['Token has expired', 'Invalid token.'].includes(message)) {
        emitter('shouldLogout');
      }
    });

    return () => {
      clearInterval(iv);
    };
  });
}

export function* doPollingAccount () {
  const axiosIntercept = yield call(axiosInterceptExpiredToken);

  while (true) {
    const axiosEventChannel = yield take(axiosIntercept);

    if (axiosEventChannel === 'shouldLogout') {
      yield put({type: LOGOUT});
      yield put({type: AUTO_LOGGED_OUT});
    }
  }
}

export default function* accountSagas () {
  yield all([
    doPollingAccount(),
  ]);
}
