import { apiDelete, apiGet } from "acds-react-core";
import _ from "lodash";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  GET_CODE_SET_DETAILS,
  GET_CODE_SET_DETAILS_SUCCESS,
  RESET_CODE_SETS,
  RESET_CODE_SETS_SUCCESS,
} from "../actions/code-reset";

function* doGetResetCodeSetUsers({ code1, code2 }) {
  const apiRoot = yield select((state) => state.env.api_root);
  const apiString = "api/codeset";

  const url = new URL(apiString, apiRoot);

  url.searchParams.append("code1", code1);
  url.searchParams.append("code2", code2);

  const data = yield apiGet(_.replace(url, apiRoot + "/", ""));

  yield put({
    type: GET_CODE_SET_DETAILS_SUCCESS,
    user: _.get(data, "results.0", {}),
  });
}

function* doResetCodeSet({ codeSetId }) {
  const apiString = `api/reset-codeset/${codeSetId}`;

  yield apiDelete(apiString);

  yield put({
    type: RESET_CODE_SETS_SUCCESS,
  });
}

export default function* codeResetSagas() {
  yield takeLatest(GET_CODE_SET_DETAILS, doGetResetCodeSetUsers);
  yield takeLatest(RESET_CODE_SETS, doResetCodeSet);
}
