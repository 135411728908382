import {
  all,
  call,
} from 'redux-saga/effects';
import * as _ from 'lodash';

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// Take at least 500ms.
export function* minDelayCall (...callArgs) {

  const [ result ] = yield all([
    call(...callArgs),
    call(delay, 500),
  ]);

  return result;
}

export const action = (type) => ({ type });

export const getNextApiString = (next) => {
  // Remove http and other starting parts of next call
  const trimmedNext = _.split(next, '/api/');
  // Use the second part of the next string (the part without http)
  const apiString = `api/${trimmedNext[1]}`;

  return apiString;
};
