import * as _ from 'lodash';

import * as Actions from '../actions/notifications';

const initialState = {
  notifications: [],
  notificationsError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: _.get(action, 'response.results'),
      };
    case Actions.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsError: action.error,
      };
    case Actions.MARK_NOTIFICATION_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: _.map(_.clone(state.notifications), (notification) =>
          notification.id === _.get(action, 'response.id')
            ? {...notification, is_seen: true}
            : notification,
        ),
      };
    case Actions.MARK_NOTIFICATION_AS_READ_ERROR:
      return {
        ...state,
        notificationsError: action.error,
      };
    case Actions.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: _.map(_.clone(state.notifications), (notification) =>
          !notification.is_seen ? {...notification, is_seen: true} : notification,
        ),
      };
    case Actions.MARK_ALL_NOTIFICATIONS_AS_READ_ERROR:
      return {
        ...state,
        notificationsError: action.error,
      };
    default:
      return state;
  }
};
