import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faClose, faFilePdf, faLongArrowAltLeft, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik, useFormik } from 'formik';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { postContactUs } from '../../redux/actions/commonUtils';
import './ContactUsPage.scss';

export default function ContactUsPage () {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const selectOptionRef = useRef(null);
  const dispatch = useDispatch();
  const [reCaptchaCompleted, setReCaptchaCompleted] = useState(false);
  const [modal, setModal] = useState(false);
  const user = useSelector((state) => state.account.user);
  const toggle = () => setModal(!modal);
  const userValues =
  user !== null ? { name: user.first_name + ' ' + user.last_name,
    email: user.email, message: '' } : { name: '', email: '', message: '' };
  const isEmptyEmail = _.isEmpty(_.get(user, 'email'));
  const isEmptyName = _.isEmpty(_.get(user, 'first_name') + _.get(user, 'last_name'));

  const formik = useFormik({
    initialValues: userValues,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        type: selectOptionRef.current.value,
        email_address: values.email,
        message: values.message,
        attachment: files,
      };
      dispatch(postContactUs(payload, toggle));
      window.grecaptcha.reset();
      setReCaptchaCompleted(false);
    },
  });

  const handleInputFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    let totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const newFiles = [];

    filesArray.forEach((file) => {
      if (totalSize + file.size <= 10 * 1024 * 1024 && newFiles.length < 1) {
        totalSize += file.size;
        newFiles.push(file);

        const reader = new FileReader();
        reader.onload = () => {
          const preview = reader.result;
          setPreviews((prevPreviews) => [...prevPreviews, preview]);
        };
        reader.readAsDataURL(file);
      }
    });

    setFiles([...files, ...newFiles]);
  };

  const handleFileRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    const newPreviews = [...previews];
    newPreviews.splice(index, 1);
    setPreviews(newPreviews);
  };

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const handleReCaptchaChange = (value) => {
    if (value) {
      setReCaptchaCompleted(true);
    } else {
      setReCaptchaCompleted(false);
    }
  };

  const selectOptions = [
    {
      displayValue: 'Ask a question',
      id: 1,
    },
    {
      displayValue: 'Provide a feedback',
      id: 2,
    },
    {
      displayValue: 'Report an error',
      id: 0,
    },
    {
      displayValue: 'Other',
      id: 3,
    },
  ];

  const RenderAddAttachment = () => {
    return (
      <Row className="align-items-center">
        <span
          className="text-center"
          style={{
            color: '#F5FBFF',
            fontSize: '16px',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          Add Attachments
        </span>
        <Row
          className="custom-add-button-container align-items-center"
          onClick={() => {
            document.getElementById('file-upload-input').click();
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="2xl" />
        </Row>
      </Row>
    );
  };

  const RenderPreviewFiles = () => {
    return (
      <Row className="justify-content-center align-items-center">
        {files.map((item, index) => (
          <Col  key={index} className='preview-image'>
            {item.type.startsWith('image') ? (
              <div
                style={{
                  display: 'inline',
                  position: 'relative',
                }}
              >
                <img
                  src={previews[index]}
                  alt={'Preview'}
                  style={{
                    width: '7vw',
                    height: '100%',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                />
                <div className="preview-close">
                  <FontAwesomeIcon
                    icon={faClose}
                    size="xl"
                    className='fa-close'
                    onClick={() => {
                      handleFileRemove(index);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'inline',
                  position: 'relative',
                }}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  style={{
                    width: '7vw',
                    height: '100%',
                    padding: '5px',
                    borderRadius: '5px',
                    color: 'white',
                  }}
                />
                <FontAwesomeIcon
                  icon={faClose}
                  size="xs"
                  style={{
                    position: 'absolute',
                    right: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleFileRemove(index);
                  }}
                />
              </div>
            )}
          </Col>
        ))}
        {previews.length !== 3 && (
          <Col
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              document.getElementById('file-upload-input').click();
            }}
          >
            <div
              style={{
                width: '30%',
                height: '100%',
                padding: '5px',
                borderRadius: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                color:'#595e60',
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="2xl" />
            </div>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <div className="contact-page-container">
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader className="custom-modal-header">
          <Row className='d-flex w-100 flex-row justify-content-between align-content-center'>
            <span>Success!</span>
          </Row>
          <FontAwesomeIcon
            icon={faXmark}
            color='#fff'
            style={{ cursor: 'pointer'}}
            onClick={() => {
              formik.resetForm();
              setFiles([]);
              setReCaptchaCompleted(null);
              setPreviews([]);
              toggle();
            }}
          />
        </ModalHeader>
        <ModalBody className="custom-modal-body">
          <Row>
            <Row className="p-4 justify-content-center align-items-center text-center">
              <Col>Thank you for your message! We will be in touch soon.</Col>
            </Row>
            <Row className="p-4 justify-content-center align-items-center text-center">
              <Col>
                <Button
                  style={{
                    width: '20%',
                  }}
                  className="contact-us"
                  onClick={() => {
                    formik.resetForm();
                    setFiles([]);
                    setReCaptchaCompleted(null);
                    setPreviews([]);
                    toggle();
                  }}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Row>
        </ModalBody>
      </Modal>
      <Row className="p-4">
        <div className="header-left">
          <Button className="btn-back" onClick={goBack}>
            <FontAwesomeIcon className="long-arrow" icon={faLongArrowAltLeft} size="lg" />
            &nbsp;&nbsp; Back
          </Button>
        </div>
      </Row>
      <Row className="page-title p-2">
        <span>Contact Us</span>
      </Row>
      <Row className="page-subtitle p-2">
        <span>Got a question? We’d love to hear from you. Send us a message.</span>
      </Row>
      <Row className="page-form justify-content-center">
        <Col className="col-5">
          <Formik {...formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Name"
                  required={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  disabled={(user === null || isEmptyName) ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="selectType">How can we help you?</Label>
                <Field
                  component={Input}
                  name="selectType"
                  id="selectType"
                  placeholder="What would you like?"
                  required={true}
                  type="select"
                  innerRef={selectOptionRef}
                  defaultValue={selectOptions[0].id}
                >
                  {selectOptions.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.displayValue}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email Address"
                  required={true}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled={(user === null || isEmptyEmail ) ? false : true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Type your message here..."
                  required={true}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  maxLength={250}
                />
                <Row className='m-2'>
                  <Col md={6} lg={6} className='float-start'>
                    {formik.values.message?.length === 250 && (
                      <div>
                        <span style={{ color: '#ef6360' }}>
                        max message length reached
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col
                    style={
                      { textAlign: 'right',
                        color: formik.values.message?.length === 250 ? '#ef6360' : '#f5fbff',
                      }
                    }
                    md={6}
                    lg={6}
                  >
                      ({formik.values.message?.length || 0} / 250)
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Input
                  id="file-upload-input"
                  type="file"
                  multiple
                  max={3}
                  className="d-none"
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={handleInputFileChange}
                />
              </FormGroup>
              <FormGroup>
                <Row className="custom-upload-container">
                  {previews?.length > 0 ? <RenderPreviewFiles /> : <RenderAddAttachment />}
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col></Col>
                  <Col>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN} onChange={handleReCaptchaChange} />
                  </Col>
                </Row>
              </FormGroup>
              <Row>
                <Button
                 className="footer-btn p-1 m-1"
                 type={'submit'}
                 disabled={!reCaptchaCompleted}
                 style={{
                  backgroundColor: "#00c9b7"
                 }}
                >
                  <div className='d-flex flex-row justify-content-center align-items-center'>
                    <div>
                      <FontAwesomeIcon icon={faTelegram} fill="#414b4f" size="xl" className='m-2'/>
                    </div>
                    <div>
                      <span>
                        Submit
                      </span>
                    </div>
                  </div>
                </Button>
              </Row>
              <Row className='contact-us-camp' />
            </Form>
          </Formik>
        </Col>
      </Row>
    </div>
  );
}
