import _ from 'lodash';
import * as Actions from '../actions/commentReview';

export const initialState = {
  commentReviewLastUpdatedDate: {},
  commentReviewLastUpdatedDateFetching: false,
  commentReviewLastUpdatedDateError: {},
  productsComments: [],
  productsCommentsNext: '',
  fetchingProductsComments: false,
  fetchingProductsCommentsNext: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_COMMENT_REVIEW_LAST_UPDATED_DATE:
      return {
        ...state,
        commentReviewLastUpdatedDateFetching: true,
      };
    case Actions.SET_COMMENT_REVIEW_LAST_UPDATED_DATE:
      
      return {
        ...state,
        commentReviewLastUpdatedDate: action.response,
        commentReviewLastUpdatedDateFetching: false,
      };
    case Actions.GET_PRODUCTS_COMMENTS:
      return {
        ...state,
        productsComments: [],
        fetchingProductsComments: true,
        fetchingProductsCommentsNext: false,
        productsCommentsNext: '',
      };
    case Actions.GET_PRODUCTS_COMMENTS_SUCCESS:
      return {
        ...state,
        productsComments: _.get(action, 'response.results', []),
        productsCommentsNext: _.get(action, 'response.next', ''),
        fetchingProductsComments: false,
        fetchingProductsCommentsNext: false,
      };
    case Actions.GET_PRODUCTS_COMMENTS_ERROR:
      return {
        ...state,
        productsComments: _.get(action, 'response.results', []),
        fetchingProductsComments: false,
        fetchingProductsCommentsNext: false,
      };
    case Actions.GET_PRODUCTS_COMMENTS_NEXT:
      return {
        ...state,
        fetchingProductsCommentsNext: true,
      };
    case Actions.GET_PRODUCTS_COMMENTS_NEXT_SUCCESS:
      
      return {
        ...state,
        productsComments: [ ...state.productsComments, ..._.get(action, 'result.results', [])],
        productsCommentsNext: _.get(action, 'result.next', ''),
        fetchingProductsComments: false,
        fetchingProductsCommentsNext: false,
      };
    case Actions.UPDATE_PRODUCT_COMMENT_STATUS:
      
      return {
        ...state,
        productsComments: _.reject(state.productsComments, ['id', action.reviewAction.id] ),
      };
    default:
      return state;
  }
};
