import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { notificationGetNotifications } from "../../redux/actions/notifications";
import "./Header.scss";
import NotificationPopover from "./NotificationPopover";

export default function Header({ title, showBack, backPath }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((s) => s.account.user);
  const apiRoot = useSelector((state) => state.env.api_root);
  const sidebarOpen = useSelector((state) => state.appUI.sideBarOpen);

  const groups = _.get(user, "groups");
  const userGroups = _.map(groups, "name");

  const goBack = () => {
    history.goBack();
  };
  const currentUser = useSelector((s) => s.account.user);

  useEffect(() => {
    if (!!currentUser) {
      dispatch(notificationGetNotifications());
    }
  }, [dispatch, currentUser]);

  return (
    <div className={`site-header ${sidebarOpen ? "sidebar-open" : ""}`}>
      {showBack && (
        <div className="header-left">
          <Button className="btn-back" onClick={goBack}>
            <FontAwesomeIcon className="long-arrow" icon={faArrowLeft} size="lg" />
            &nbsp;&nbsp; Back
          </Button>
        </div>
      )}
      <h1 className="camp-login-title">{title}</h1>
      <div className="header-right">
        {userGroups.includes("patient") && <NotificationPopover />}
        <div>
          {currentUser && userGroups.includes("patient") && (
            <>
              <div className="row d-flex mx-3 align-items-center">
                <div className="col mx-1" style={{ padding: 0 }}>
                  <Avatar
                    src={apiRoot + "/media/" + currentUser.image}
                    size={50}
                    round={true}
                    name={currentUser.first_name}
                    className="header-avatar"
                    onClick={() => {
                      if (userGroups.includes("patient")) {
                        history.push("/profile");
                      }
                    }}
                  />
                </div>
                <div className="col" style={{ padding: 0 }}>
                  <span className="header-user-profile-name">{currentUser.first_name}</span>
                  <div>
                    <span className="header-user-profile-email">{currentUser.email}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
