import { apiGet, apiPost } from "acds-react-core";
import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import {
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_GENERAL_EDUCATION,
  GET_GENERAL_EDUCATION_SUCCESS,
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_TERMS,
  GET_TERMS_SUCCESS,
  POST_CONTACT_US,
} from "../actions/commonUtils";

function* doContactUsApi(payload) {
  const apiString = "api/contact-us/";

  const values = _.get(payload, "payload");

  const formData = new FormData();
  formData.append("name", values.name);
  formData.append("type", values.type);
  formData.append("email_address", values.email_address);
  formData.append("message", values.message);
  values.attachment.map((item) => formData.append("attachment", item));

  try {
    yield apiPost(apiString, {
      data: formData,
      authenticationRequired: true,
      headers: { "Content-type": "multipart/form-data" },
    });
    payload.toggle();
  } catch (error) {
    console.error(error);
  }
}

function* doGetTerms() {
  const apiString = "api/terms/";
  const response = yield apiGet(apiString);
  try {
    yield put({
      type: GET_TERMS_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetPrivacyPolicy() {
  const apiString = "api/privacy/";
  const response = yield apiGet(apiString);
  try {
    yield put({
      type: GET_PRIVACY_POLICY_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetFaq() {
  const apiString = "api/faq/";
  const response = yield apiGet(apiString);
  try {
    yield put({
      type: GET_FAQ_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetGeneralEducation() {
  const apiString = "api/general-education/";
  const response = yield apiGet(apiString);
  try {
    yield put({
      type: GET_GENERAL_EDUCATION_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* commonUtilsSagas() {
  yield takeLatest(POST_CONTACT_US, doContactUsApi);
  yield takeLatest(GET_TERMS, doGetTerms);
  yield takeLatest(GET_PRIVACY_POLICY, doGetPrivacyPolicy);
  yield takeLatest(GET_FAQ, doGetFaq);
  yield takeLatest(GET_GENERAL_EDUCATION, doGetGeneralEducation);
}
